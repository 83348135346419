import React, { useEffect, useState } from 'react';
import { validateString } from "../../../utils/validateString";
import { takeColor } from './takeColor'

const SinglePesAnswerBox = ({answer, answerIndex, questionIndex, changeAnswerHandler}) => {
    const [elementColor, setElementColor] = useState('')

    useEffect(() => {
        if (answer.isCorrect !== undefined) {
            setElementColor(answer.isCorrect ? '#2FD77C' : answer.checked ? '#CD1B71' : '#1B2936')
        } else if (answer.checked) {
            setElementColor('rgba(0, 194, 223, 0.5)')
        } else {
            setElementColor('#1B2936')
        }
    }, [answer])

    return (
        <div
            className={'singlePesAnswerBox'}
            onClick={() => {
                if (answer.isCorrect === undefined) {
                    changeAnswerHandler(questionIndex, answerIndex)
                }
            }}
        >
			<span className={'singlePesAnswerBox__gamification'}
                  style={{
                      width: '100%',
                      background: elementColor
                  }}/>
            <div
                className={`singlePesAnswerBox__radio ${takeColor(answer.checked, answer.isCorrect)} `}/>
            <p className={`singlePesAnswerBox__title ${answer.isCorrect ? 'singlePesAnswerBox__title-green' : ''}`}
               dangerouslySetInnerHTML={{__html: validateString(answer.answer)}}></p>
        </div>
    )
}

export default SinglePesAnswerBox;