import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VariaContext } from '../../../context/varia/variaContext';
import NuvButton from '../../shared/ui/buttons/NuvButton';
import { Container, Grid, Stack } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import { validateString } from '../../../utils/validateString';

const VariaVideoPage = () => {

    const {
        variaState: {variaVideoInfo, video},
        getVariaVideoInfo,
        clearVariaVideoInfo,
        getVariaInfo,
        setIsViewed
    } = useContext(VariaContext)
    const params = useParams()

    useEffect(() => {
        getVariaInfo()
        return () => {
            clearVariaVideoInfo()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (params.id) {
            getVariaVideoInfo(params.id)
        }
        // eslint-disable-next-line
    }, [params.id, video])

    return (
        <Container maxWidth={'xl'}>
            <Grid container className={'variaVideoPage__header'}>
                <NuvButton flag={'return'} link={'/varia'} title={'POWRÓT'}/>
            </Grid>
            {
                variaVideoInfo &&
                <>
                    <Grid container className={'variaVideoPage__content'}>
                        <Grid item xs={12} lg={6} p={{xs: 1, sm: 2}} container alignItems={'center'}>
                            <p className={'variaVideoPage__content-title'}>{variaVideoInfo.title}</p>
                        </Grid>
                        <Grid item xs={12} lg={6} p={{xs: 1, sm: 2}}>
                            {
                                variaVideoInfo.description && variaVideoInfo.description.map((el, i) => <div key={i}
                                                                                                             className={'variaVideoPage__content-description'}
                                                                                                             dangerouslySetInnerHTML={{__html: validateString(el.content)}}/>)
                            }
                            <Stack direction={'row'}>
                                <p className={'variaVideoPage__content-authors'}>autor:</p>
                                <div className={'variaVideoPage__content-authorsContainer'}>
                                    {
                                        variaVideoInfo.authors && variaVideoInfo.authors.map((author, index) => <p
                                            className={'variaVideoPage__content-authors'}
                                            key={index}>{author.name}{index !== variaVideoInfo.authors.length - 1 ? ', ' : ''}</p>)
                                    }
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Vimeo
                        className={'variaVideoPage__content-video'}
                        video={variaVideoInfo.vimeoId}
                        autoplay
                        allowfullscreen
                        onEnd={() => setIsViewed(variaVideoInfo.id)}

                    />
                    {
                        variaVideoInfo.signature ?
                            <p className={'variaVideoPage__content-signature'}>{variaVideoInfo.signature}</p> : null
                    }
                </>
            }
        </Container>
    )
}

export default VariaVideoPage;