import React from 'react'
import {Container, Grid} from '@mui/material';
import PageTitles from '../../shared/ui/PageTitles';
import QuizzesBox from './QuizzesBox';

const QuizzesBoxes = () => {
    return (
        <>
            <Container maxWidth="xl">
                <Grid container>
                    <PageTitles titleOptions="quizzes" flag={false}/>
                    <h3 className='quizzes__subtitle'>
                        Połączenie nauki z elementami gry ułatwia zapamiętywanie.
                    </h3>
                    <Grid item xs={12} container alignItems={'center'} justifyContent={'center'}>
                        <Grid container justifyContent={{xs: 'center', md: 'space-around'}}
                              pt={{xs: '20px', md: '30px', lg: '92px'}} maxWidth={{xs: '690px', lg: '100%'}}
                              flex={'nowrap'}
                              alignItems={'center'} flexWrap={'wrap'} gap={{xs: 2, md: 4}} >
                            <QuizzesBox color={'#F4EB08'} text='POWTÓRKA PRZED PES' flag={'before-exam'}/>
                            <QuizzesBox color={'#2A81A1'} text='SZYBKA PIĄTKA' flag={'fast-fives'}/>
                            <QuizzesBox color={'#FF007A'} text='WYZWANIE' flag={'challenges'}/>
                            <QuizzesBox color={'#FA8072'} text='Co poszło nie tak?' flag={'what-went-wrong'}/>
                            <QuizzesBox color={'#1B96D5'} text='Ciekawostki obrazowe' flag={'pictorial-curiosities'}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default QuizzesBoxes