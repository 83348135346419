import {useReducer, useCallback} from 'react';
import {poiReducer} from './poiReducer';
import {PoiContext} from './poiContext';
import {SET_GLOBAL_LOADER, SET_MODAL_ERROR, UNSET_GLOBAL_LOADER, UNSET_MODAL_ERROR} from '../types';


export const PoiState = ({children}) => {
    const initialState = {
        isGlobalLoader: false,
        isGlobalError: false,
        errorMessage: null,
        errorCode: null,
    }

    const [state, dispatch] = useReducer(poiReducer, initialState);

    const setGlobalLoader = useCallback(() => dispatch({type: SET_GLOBAL_LOADER}), [])

    const unsetGlobalLoader = useCallback(() => dispatch({type: UNSET_GLOBAL_LOADER}), [])

    const getDataToList = (data) => {
        const dataArray = Object.entries(data);
        let dataMessages = '';
        for (let dataItem of dataArray) {
            for (let subData of dataItem[1]) {
                dataMessages += subData + "</br>";
            }
        }
        return dataMessages;
    }

    const setModalError = (res) => {
        const payload = {
            errorCode: res?.data?.code ? res.data.code : res.status ? res.status : 404,
            errorMessage: res?.data?.message ?
                res.data.message : res.data['hydra:description'] ?
                    res.data['hydra:description'] : res.data.data.error ?
                        res.data.data.error : res.data.data && typeof res.data.data === 'object' ?
                            getDataToList(res.data.data) : 'skontaktuj się z administratorem',
        }
        dispatch({type: SET_MODAL_ERROR, payload})
    }

    const unsetModalError = () => dispatch({type: UNSET_MODAL_ERROR})

    return (
        <PoiContext.Provider value={{
            isGlobalLoader: state.isGlobalLoader,
            isGlobalError: state.isGlobalError,
            errorMessage: state.errorMessage,
            errorCode: state.errorCode,
            setGlobalLoader, unsetGlobalLoader,
            setModalError, unsetModalError,
        }}>
            {children}
        </PoiContext.Provider>
    )

}