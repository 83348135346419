import React from 'react';
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {Stack} from '@mui/material';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	backgroundColor: "transparent",
	padding: 7,
	"& .MuiSwitch-switchBase": {
		backgroundColor: "transparent",
		margin: 1,
		padding: 0,
		transform: "translateX(6px)",
		"&.Mui-checked": {
			color: "#fff",
			transform: "translateX(22px)",
			"& .MuiSwitch-thumb": {
				backgroundColor: "#2FD77C",
			},
			"& .MuiSwitch-thumb:before": {
				backgroundImage: "url(../assets/img/switchButtons/check.svg)",
			},
			"& + .MuiSwitch-track": {
				backgroundColor: "transparent"
			}
		}
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: "#BDBDBD",
		width: 32,
		height: 32,
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: "url(../assets/img/ui/switchButtons/check.svg)",
		}
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: "transparent",
		border: "1px solid gray",
		borderRadius: 20 / 2
	}
}));

const CustomCheckSwitch = ({value, handler}) => {
	return <Stack direction={'row'} flexWrap={'nowrap'} alignItems={'center'}>
		<MaterialUISwitch
			sx={{ m: 1 }}
			checked={value}
			onChange={e => handler(e.target.checked)}
		/>
		<span style={{textTransform: 'uppercase', fontSize: '15px', lineHeight: '21px', color: '#fff'}}>UKOŃCZONE</span>
	</Stack>
}

export default CustomCheckSwitch;