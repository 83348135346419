import React, { useContext, useEffect, useState } from 'react'
import Vimeo from '@u-wave/react-vimeo';
import { EventContext } from '../../../../context/event/eventContext';
import { Grid } from '@mui/material';
import { validateString } from '../../../../utils/validateString';

const VideoVsButtonsSlide = ({showButtonHandler}) => {
    const {eventState: {caseStep}} = useContext(EventContext);
    const [videoToShow, setVideoToShow] = useState(null);
    const [isFirstVideoEnd, setIsFirstVideoEnd] = useState(false);
    const [isSecondVideoEnd, setIsSecondVideoEnd] = useState(false);

    useEffect(() => {
        showButtonHandler({type: null, show: false});
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setVideoToShow(caseStep.video)
    }, [caseStep])

    useEffect(() => {
        if (isFirstVideoEnd && isSecondVideoEnd) {
            showButtonHandler({type: 'slide', show: true});
        }
        // eslint-disable-next-line
    }, [isFirstVideoEnd, isSecondVideoEnd])

    return (
        <Grid
            container
            item
            className={'videoVsButtons'}
            height={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            {
                videoToShow
                    ?
                    <Vimeo
                        className={'videoVsButtons__videoWrapper'}
                        video={videoToShow}
                        autoplay
                        allowfullscreen
                        transparent={true}
                        onEnd={() => {
                            if (!isFirstVideoEnd) {
                                setIsFirstVideoEnd(true)
                                setVideoToShow(null)
                            } else {
                                setIsSecondVideoEnd(true)
                                setVideoToShow(null)
                            }
                        }}
                    />
                    :
                    !isFirstVideoEnd
                        ?
                        null
                        :
                        !isSecondVideoEnd
                            ?
                            <Grid
                                width={'100%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                flexDirection={'column'}
                                height={'auto'}
                            >
                                <h3>Wybierz kolejne pytanie do pacjenta:</h3>
                                <Grid container className={'videoVsButtons__buttonWrapper'} display={"flex"}>
                                    {
                                        caseStep.videoButtons.map((el, index) =>
                                            <Grid
                                                className={'videoVsButtons__buttonWrapper-textWrapper'}
                                                key={index}
                                                onClick={() => setVideoToShow(el.vimeoId)}
                                            >
                                                <p>{el.text}</p>
                                            </Grid>,
                                        )
                                    }
                                </Grid>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                }}>
                                    <div className={'refreshButton'}
                                         onClick={() => {
                                             setIsFirstVideoEnd(false)
                                             setIsSecondVideoEnd(false)
                                             setVideoToShow(caseStep.video)
                                         }}
                                    >
                                        <span className={'refreshButton__img'}/>
                                        <span className={'refreshButton__title'} style={{textAlign: 'center'}}>Odtwórz film ponownie</span>
                                    </div>
                                </div>
                            </Grid>
                            :
                            caseStep.comment.map((el, index) =>

                                <div className={'fusionText'}
                                     style={{alignSelf: 'flex-start'}}
                                     key={index}
                                     dangerouslySetInnerHTML={{__html: validateString(el.content)}}/>
                            )
            }
        </Grid>
    )
}

export default VideoVsButtonsSlide;