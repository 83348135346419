import React, { useContext } from 'react'
import { Container, Grid } from '@mui/material';
import { PodcastsContext } from '../../../context/podcasts/podcastsContext';
import PodcastDescriptionBox from './PodcastDescriptionBox';
import PodcastPart from './PodcastPart';

const PodcastParts = ({setCurrentEpisode, currentDescriptionIndex, clickHandler}) => {
  const {podcastsState} = useContext(PodcastsContext);

  return (
    <Grid className={'podcastParts'}>
      <Container maxWidth="xl">
        <h3 style={{marginBottom: '20px'}}>ODCINKI:</h3>
        <Grid container className={'podcastParts__content'} justifyContent={'space-between'}
              alignItems={{xs: 'center', lg: 'flex-start'}} flexDirection={{xs: 'row'}}>
          <Grid item xs={12} lg={7} className={'podcastParts__content-list'}>
            {
              podcastsState.episodes && podcastsState.episodes.length > 0 && podcastsState.episodes.map((episode, index) => (
                  <PodcastPart
                    key={index}
                    part={episode}
                    index={index}
                    currentDescriptionIndex={currentDescriptionIndex}
                    setCurrentEpisode={setCurrentEpisode}
                    clickHandler={() => clickHandler(index)}
                  />
                ),
              )
            }
          </Grid>
          <PodcastDescriptionBox currentIndex={currentDescriptionIndex}/>
        </Grid>
      </Container>
    </Grid>
  )
};

export default PodcastParts