import axios from 'axios';
import {HOST} from '../consts/projectUrl';

export default axios.create(
	{
		baseURL: `${HOST}`,
		headers: {
			'Content-Type': 'application/json',
		},
	},
)