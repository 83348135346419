import React from 'react'

const QuizNextButton = ({onClickHandler}) => {
	return (
		<button
			onClick={onClickHandler}
			className={'quizNextButton'}
		>
			DALEJ
		</button>
	)
}

export default QuizNextButton;