import React, {useContext} from 'react';
import {AuthContext} from '../context/auth/authContext';
import {Navigate, useLocation, useParams} from 'react-router-dom';

const ProtectedRoute = ({children, accessUserRoles, navigateLink}) => {
    const {role} = useContext(AuthContext);
    const location = useLocation();
    const params = useParams()
    const allowed = (!accessUserRoles || accessUserRoles.indexOf(role) >= 0);
    const navigationLink = navigateLink === '/quizzes/variant' && params.id ? `/quizzes/quiz/${params.id}` : navigateLink

    return allowed ? children :
        <Navigate to={location.state?.from.pathname ?? navigationLink} replace state={{from: location}}/>
}

export default ProtectedRoute;