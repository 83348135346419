import {CLEAR_VARIA_STATE, CLEAR_VARIA_VIDEO_INFO, GET_VARIA_INFO, GET_VARIA_VIDEO_INFO} from '../types';
import {useContext, useReducer} from 'react';
import {AuthContext} from '../auth/authContext';
import {variaReducer} from './variaReducer';
import {VariaContext} from './variaContext';
import {PoiContext} from '../poi/poiContext';
import axios from '../../axios/axios';

export const VariaState = ({children}) => {
	const {setGlobalLoader, unsetGlobalLoader, setModalError} = useContext(PoiContext);
	const {token, isLoggedIn} = useContext(AuthContext);

	const initialState = {
		video: null,
		image: null,
		publication: null,
		variaVideoInfo: null,
	}

	const [state, dispatch] = useReducer(variaReducer, initialState);

	const getVariaInfo = async () => {
		setGlobalLoader();
		try {
			axios.get(
				`wp-json/softwebo/v1/varias`,
				isLoggedIn ? {
					headers: {Authorization: `Bearer ${token}`},
				} : undefined,
			).then(res => {
				dispatch({type: GET_VARIA_INFO, payload: res.data})
				unsetGlobalLoader();
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const getVariaVideoInfo = (id) => {
		setGlobalLoader();
		if (state.video) {
			const payload = JSON.parse(JSON.stringify(state.video.find(el => +el.id === +id)));
			dispatch({type: GET_VARIA_VIDEO_INFO, payload})
		}
		unsetGlobalLoader();
	}

	const clearVariaState = () => dispatch({type: CLEAR_VARIA_STATE, payload: initialState})

	const clearVariaVideoInfo = () => dispatch({type: CLEAR_VARIA_VIDEO_INFO})

	const setIsViewed = async (id) => {
		try {
			await axios.post(
				`wp-json/softwebo/v1/varia/${id}`, null,
				{
					headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
				}
			)
		} catch (e) {
			setModalError(e.response);
		}
	}

	return (
		<VariaContext.Provider value={{
			variaState: state,
			getVariaInfo,
			getVariaVideoInfo,
			clearVariaVideoInfo,
			setIsViewed,
			clearVariaState,
		}}>
			{children}
		</VariaContext.Provider>
	)

}