import React, {useContext} from 'react';
import {Grid, Stack} from '@mui/material';
import StatusVideoButton from '../buttons/StatusVideoButton';
import {AuthContext} from '../../../../context/auth/authContext';
import {useNavigate} from 'react-router-dom';
import {timeConverter} from "../../../../utils/timeConverter";
import maskGroup from '../../../../assets/img/ui/maskGroup.png';

const PodcastBox = ({podcast, isOneLine = false}) => {
	const {isLoggedIn} = useContext(AuthContext);
	const navigate = useNavigate();
	
	return (
		<Grid
			item
			p={1}
			xl={3} lg={isOneLine? 3 : 4} sm={6} xs={12}
			width="100%"
		>
			<Grid
				container
				className="podcastBox"
				onClick={() => {
					navigate(`/podcasts/${podcast.id}`)
				}}
				>
			<Grid
				container
				height={'100%'}
				flexDirection={'column'}
			>
				<img
					className="podcastBox__photo"
					src={podcast.image}
					alt="patient"
				/>
				{
					!podcast.duration && <img src={maskGroup} className={'podcastBox__maskGroup'} alt="mask group"/>
				}
				<Grid item flexGrow={'1'}>
					<Grid
						container
						className="podcastBox__footer"
						flexDirection={'column'}
						justifyContent={'space-between'}
						flexWrap={'nowrap'}
						pl={2}
						pr={2}
						pb={2}
						height={'100%'}
					>
						<Grid item flexGrow={'1'}>
							<Grid container alignItems={'center'} height={'100%'} borderBottom={'1px solid white'}>
								<p className="podcastBox__title">{podcast.title}</p>
							</Grid>
						</Grid>
						<Grid container minHeight={70} alignItems={'center'} justifyContent={'space-between'} pt={1} flexWrap={'nowrap'}>
							<Grid
								item
							>
								<Stack direction={'row'}>
									<p className="podcastBox__authors podcastBox__authors-ls">Autorzy:</p>
									<Stack direction={'column'}>
										{
											podcast.authors && podcast.authors.map((author, index) => <p
												className="podcastBox__authors" key={index}>{author.name}</p>)
										}
									</Stack>
								</Stack>
								<Stack direction={'row'} mt={'4px'}>
									{
										podcast.duration
										?
											<>
												<p className="podcastBox__authors podcastBox__authors-ls">Czas trwania:</p>
												<p className="podcastBox__authors">{timeConverter(podcast.duration)}</p>
											</>
											:
											<p className="podcastBox__authors podcastBox__authors-ls">Już wkrótce</p>
									}
								</Stack>
							</Grid>
							<Grid item>
								{
									isLoggedIn && <StatusVideoButton status={podcast.status}/>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			</Grid>
		</Grid>
	)
}

export default PodcastBox