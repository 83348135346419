import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/auth/authContext';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pl } from 'date-fns/locale';
import validate from '../../../utils/validateInfo';
import useForm from '../../../hooks/useForm';
import { dateConverter } from "../../../utils/dateConverter";

const Registration = () => {
  const {getUserSpecializations, authState: {userSpecializations}} = useContext(AuthContext);
  const {handleChange, handleSubmit, userInfo, errors} = useForm(
    validate
  );
  const [specializationSet, setSpecializationSet] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const showPassHandler = () => setShowPassword(prev => !prev);
  const showConfirmPassHandler = () => setShowConfirmPassword(prev => !prev);

  const handleMouseDownPassword = (e) => e.preventDefault();

  useEffect(() => {
    getUserSpecializations();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userSpecializations) {
      setSpecializationSet([...userSpecializations])
    }
  }, [userSpecializations])
  return (<Grid
    container
    flex
    alignItems={'center'}
    justifyContent={'center'}
    className={'auth__container'}
    pt={4}
    pb={4}
    component={'form'}
    onSubmit={(e) => handleSubmit(e)}
  >
    <Grid
      width={{xs: '90%', md: '900px', lg: '900px'}}
      sx={{
        padding: '20px',
        borderRadius: '6px',
        boxShadow: '0 0 10px rgba(0, 0, 0, .5)',
        backgroundColor: '#fff',
      }}
      container
    >
      <Grid item xs={12} md={6} pr={{xs: 0, md: 3}}>
        <Grid container direction={'column'} height={'100%'}>
          <h3 className="auth__title">
            Zarejestruj się
          </h3>
          <p className="auth__text">
            Uzupełnij swoje dane, żeby założyć konto:
          </p>
          <Grid container justifyContent={'center'} alignItems={'center'} mt={3} spacing={1}>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="name"
                            color='primary'>Imię</InputLabel>
                <OutlinedInput
                  id="name"
                  name="name"
                  label="Imię"
                  error={errors.name}
                  placeholder="wpisz imię"
                  value={userInfo.name}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </FormControl>
              {errors.name && <Typography color={'error'} fontSize={12}>{errors.name}</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="surname"
                            color='primary'>Nazwisko</InputLabel>
                <OutlinedInput
                  id="surname"
                  label="Nazwisko"
                  error={errors.surname}
                  name="surname"
                  placeholder="wpisz nazwisko"
                  value={userInfo.surname}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </FormControl>
              {errors.surname && <Typography color={'error'} fontSize={12}>{errors.surname}</Typography>}
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} alignItems={'center'} mt={1} spacing={1}>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
                <DatePicker

                  disableFuture
                  label="Data urodzenia*"
                  openTo="year"
                  inputFormat="dd-mm-yyyy"
                  views={['year', 'month', 'day']}
                  value={userInfo.birthDate}
                  onChange={(newValue) => {
                    handleChange('birthDate', newValue);
                  }}
                  renderInput={(params) => {
                    params.inputProps.value = dateConverter(userInfo.birthDate);
                    return <TextField sx={{width: '100%'}} ref={params.inputRef} {...params} />
                  }
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="pwz"
                            color={!!errors.pwz ? "error" : 'primary'}>PWZ</InputLabel>
                <OutlinedInput
                  id="pwz"
                  label="PWZ"
                  name="pwz"
                  error={errors.pwz}
                  placeholder="wpisz PWZ"
                  value={userInfo.pwz}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </FormControl>
              {errors.pwz && <Typography color={'error'} fontSize={14}>{errors.pwz}</Typography>}
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} alignItems={'center'} mt={1} spacing={1}>
            <Grid item md={6} xs={12}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="email"
                            color={!!errors.email ? "error" : 'primary'}>Adres
                  email</InputLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  error={!!errors.email}
                  label="Adres email"
                  placeholder="wpisz email"
                  value={userInfo.email}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </FormControl>
              {errors.email && <Typography color={'error'} fontSize={14}> {errors.email}</Typography>}
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="tel"
                            color={!!errors.tel ? "error" : 'primary'}>Numer
                  telefonu</InputLabel>
                <OutlinedInput
                  id="tel"
                  name="tel"
                  label="Numer telefonu"
                  error={!!errors.tel}
                  placeholder="wpisz numer telefonu"
                  value={userInfo.tel}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </FormControl>
              {errors.tel && <Typography color={'error'} fontSize={14}> {errors.tel}</Typography>}
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <FormControl required fullWidth>
              <InputLabel id="specialization-label">Specjalizacja:</InputLabel>
              <Select
                labelId="specialization-label"
                id="specialization"
                name="specialization"
                value={userInfo.specialization}
                label="Specjalizacja:"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                {
                  specializationSet && specializationSet.map((item, i) => <MenuItem value={item}
                                                                                    key={i}>{item}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container justifyContent={'center'} alignItems={'center'} mt={1} spacing={1}>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="password"
                            color={!!errors.password ? "error" : 'primary'}>Hasło</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  label="Hasło"
                  autoComplete={"new-password"}
                  error={!!errors.password}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="wpisz hasło"
                  value={userInfo.password}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  endAdornment={<InputAdornment position="end">
                    <IconButton
                      onClick={showPassHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>}
                />
                {errors.password &&
                  <Typography color={'error'} fontSize={14}>{errors.password}</Typography>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="confirmPassword"
                            color={!!errors.confirmPassword ? "error" : 'primary'}>Powtórz
                  hasło</InputLabel>
                <OutlinedInput
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Powtórz hasło"
                  autoComplete={"new-password"}
                  error={!!errors.confirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="wpisz hasło"
                  value={userInfo.confirmPassword}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  endAdornment={<InputAdornment position="end">
                    <IconButton
                      onClick={showConfirmPassHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>}
                />
                {errors.confirmPassword &&
                  <Typography color={'error'} fontSize={14}>{errors.confirmPassword}</Typography>}

              </FormControl>
            </Grid>
          </Grid>
          <Grid mt={5}>
            <p className="auth_comment">* Pola obowiązkowe</p>
          </Grid>
          <Grid mt={'auto'} mb={1}>
            <p className="auth__help">Pomoc techniczna: <a href="mailto:drsim@softwebo.pl">drsim@softwebo.pl</a>
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} pl={3} sx={{padding: {xs: 0}}}>
        <Stack direction={'column'}>
          <Stack direction={'row'} flexWrap={'nowrap'} justifyContent={'flex-start'} alignItems={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  name="contactPolicy"
                  checked={userInfo.contactPolicy}
                  onChange={(e) => handleChange(e.target.name, e.target.checked)}
                />}
              label=""
            />
            <p className="auth__comment"> Wyrażam zgodę na otrzymywanie od <a className="auth__commentLink"
                                                                              href="https://www.sandoz.pl/"
                                                                              target="_blank"
                                                                              rel="noreferrer">Sandoz
              Polska
              Sp. z o.o.</a> informacji promocyjnych i edukacyjnych drogą elektroniczną na podany adres
              email lub w formie wiadomości tekstowych (np. SMS, MMS).</p>
          </Stack>

          <Stack direction={'row'} flexWrap={'nowrap'} justifyContent={'flex-start'} alignItems={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  name="privatePolicy"
                  checked={userInfo.privatePolicy}
                  onChange={(e) => handleChange(e.target.name, e.target.checked)}
                />}
              label=""
            />
            <p className="auth__comment">Akceptuję
              <a className="auth__commentLink"
                 href="https://drsim.pl/api/wp-content/uploads/2023/10/RegulaminDrSimOd10.2023.pdf"
                 target="_blank" rel="noreferrer"> regulamin </a>
              i
              <a className="auth__commentLink"
                 href="https://drsim.pl/api/wp-content/uploads/2023/11/Website-Privacy-Policy_2023_PL_final.pdf"
                 target="_blank" rel="noreferrer"> politykę prywatności DrSim.pl *</a>
            </p>
          </Stack>

          <Stack mt={2} direction={'column'} spacing={2}>
            <p className="auth__comment">Administratorem Pana/Pani danych osobowych jest Sandoz Polska Sp z o. o. z
              siedzibą w Warszawie, przy ul. Domaniewskiej 50C ("Sandoz"). Z inspektorem ochrony danych
              może się Pan/Pani skontaktować pisząc na adres siedziby administratora danych lub pocztą
              elektroniczną, na adres email: <a className="auth__commentLink"
                                                href="mailto:sandoz_global.dpo@sandoz.com"
                                                target="_blank"
                                                rel="noreferrer">sandoz_global.dpo@sandoz.com.</a>
            </p>
            <p className="auth__comment">Dane przetwarzane będą w celu realizacji aktywności edukacyjnej w
              ramach wykonania umowy zawartej poprzez akceptację regulaminu świadczenia usługi, w celu
              weryfikacji Pani/Pana uprawnień w zakresie dostępu do treści przeznaczonych wyłącznie dla
              lekarzy, co stanowi prawnie usprawiedliwiony interes Sandoz oraz w celu przesyłania
              Panu/Pani przez Sandoz informacji edukacyjnych i marketingowych drogą elektroniczną,
              na podstawie Pana/Pani zgody.</p>
            <p className="auth__comment">Przysługuje Panu/Pani prawo dostępu do Pana/Pani danych osobowych,
              żądania ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia
              danych, a także prawo do wniesienia skargi do Urzędu Ochrony Danych Osobowych. Może
              Pan/Pani także wycofać zgodę na otrzymywanie od Sandoz informacji edukacyjnych i
              marketingowych drogą elektroniczną klikając [unsubscribe], umieszczony w przesłanej
              informacji edukacyjnej lub marketingowej. Z pozostałych uprawnień przysługujących Pana/Pani
              w zakresie ochrony danych osobowych może Pan/Pani skorzystać składając wniosek tutaj:&nbsp;
              <a className="auth__commentLink"
                 href="https://privacyportal-ch.onetrust.com/webform/639a8c14-a915-4cdd-af16-67127df13247/79b82c24-0e30-4d20-bfe1-e83ea39f6a2d"
                 target="_blank" rel="noreferrer">Privacy Web Form (onetrust.com)</a> lub kontaktując się na adres
              <a className="auth__commentLink"
                 href="mailto:sandoz_global.dpo@sandoz.com"
                 target="_blank"
                 rel="noreferrer"> sandoz_global.dpo@sandoz.com.</a>&nbsp;
              Szczegółowe informacje dotyczące przetwarzania Pana/Pani danych dostępne są na stronie internetowej&nbsp;
              <a className="auth__commentLink"
                 href="https://drsim.pl/api/wp-content/uploads/2023/11/Website-Privacy-Policy_2023_PL_final.pdf"
                 target="_blank" rel="noreferrer">https://drsim.pl/api/wp-content/uploads/2023/11/Website-Privacy-Policy_2023_PL_final.pdf
                *</a>
            </p>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2} mb={2}>
            <p className="auth__text">
              Masz już konto? <NavLink to={'/login'} style={{color: 'black', fontWeight: '700'}}>Zaloguj
              się</NavLink>
            </p>
            <Button
              type="submit"
              variant="contained"
              disabled={!userInfo.privatePolicy}
            >
              ZATWIERDŻ
            </Button>
          </Stack>
          <Stack direction={'row'} justifyContent={'flex-end'}>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  </Grid>)
}

export default Registration