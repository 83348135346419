import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";

const ProgressBar = ({currentStep, stepCount}) => {
    const [drawArray, setDrawArray] = useState(null);
    useEffect(()=> {
        if (currentStep && stepCount) {
            let tempArray = []
            for (let i = 1; i <= stepCount; i++) {
               if (i < currentStep) {
                   tempArray.push(<span className={'progressBar__previous'} key={i}/>)
               } else if (i === currentStep) {
                   tempArray.push(<span className={'progressBar__current'} key={i}/>)
               } else {
                   tempArray.push(<span className={'progressBar__next'} key={i}/>)
               }
            }
            setDrawArray([...tempArray])
        }
    }, [currentStep, stepCount])

    return (
        <Grid
            container
            className={'progressBar'}
            flexDirection={'row'}
            flexWrap={'nowrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            {
                drawArray
            }
        </Grid>
    )
}

export default ProgressBar