import React, {useContext, useEffect, useState} from 'react';
import {Grid, Stack} from '@mui/material';
import {VariaContext} from '../../../context/varia/variaContext';
import {validateString} from '../../../utils/validateString';
import { useBodyOverflow } from "../../../hooks/useBodyOverflow";
import CloseIcon from '@mui/icons-material/Close';
const VariaImageModal = ({modalToggle, imageId}) => {
	useBodyOverflow()
	const {variaState: {image}} = useContext(VariaContext);
	const [currentImage, setCurrentImage] = useState(null);
	const [isImageScale, setIsImageScale] = useState(false);

	useEffect(() => {
		if (image) {
			setCurrentImage(JSON.parse(JSON.stringify(image.find(el => +el.id === +imageId))))
		}
	}, [imageId, image])

	return (
		<div className={`customModal variaImageModal`}>
			<div className={`customModal__content variaImageModal__content ${isImageScale ? 'variaImageModal__nonePadding' : null}`}>
				{ !isImageScale && <CloseIcon className={'variaImageModal__content-button'} onClick={() => modalToggle(null)}/>}
				{
					currentImage &&
					<>
						{ !isImageScale && <Grid container >
							<Grid item xs={12} lg={6} p={2} container alignItems={'center'}>
								<p className={'variaImageModal__content-title'}>{currentImage.title}</p>
							</Grid>
							<Grid item xs={12} lg={6} p={2}>
								{
									currentImage.description.map((el, i) => <div key={i}
																				 className={'variaImageModal__content-description'}
																				 dangerouslySetInnerHTML={{__html: validateString(el.content)}}/>)
								}
								<Stack direction={'column'}>
									{
										currentImage.authors.map((author, index) => <p
											className={'variaImageModal__content-authors'}
											key={index}>{index === 0 ? 'autor:' : ''} {author.name}</p>)
									}
								</Stack>
							</Grid>
						</Grid>}
						<Grid width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
							<img
								className={'variaImageModal__content-image'}
								src={currentImage.image}
								alt="content"
								onClick={() => setIsImageScale(!isImageScale)}
							/>
							{
								currentImage.signature && !isImageScale ? <p className={'variaImageModal__content-signature'}>{currentImage.signature}</p> : null
							}
						</Grid>
						<Grid container justifyContent={'center'} alignItems={'center'}>
							<p className={'variaImageModal__content-tip'}>
								W celu poprawy widoczności algorytmu ułóż telefon poziomo
							</p>
						</Grid>
					</>
				}
			</div>
		</div>
	)
}

export default VariaImageModal;