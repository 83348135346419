import { AuthContext } from '../auth/authContext';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { PoiContext } from '../poi/poiContext';
import { quizReducer } from './quizReducer';
import { QuizContext } from './quizContext';
import {
    ADD_QUIZ_ACCESS_TOKEN,
    CHECK_ANSWER,
    CLEAR_QUIZ_STATE,
    GET_QUIZ_STATE,
    SHORT_CASE_GAMIFICATION
} from '../types';
import axios from '../../axios/axios';
import { useNavigate } from 'react-router-dom';


export const QuizState = ({children}) => {

    const {setGlobalLoader, unsetGlobalLoader, setModalError} = useContext(PoiContext);
    const {token} = useContext(AuthContext)
    const [drawArray, setArray] = useState([]);
    const initialState = {
        accessToken: null
    }

    const [state, dispatch] = useReducer(quizReducer, initialState);
    const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
    const [startTimePes, setStartTime] = useState(new Date().getTime());

    useEffect(()=> {
        const urlArray = window.location.pathname.split('/')
        const quizId = urlArray[urlArray.length -1]
       const time =  window.localStorage.getItem(`${quizId}-startTime`);
       if (time) {
           setStartTime(Number(time))
       }

    }, [])

    const setStartTimePes = (time, quizId) => {
        setStartTime(time)
        window.localStorage.setItem(`${quizId}-startTime`, time);
    }
    const setDrawArray = (data) => {
        setArray(data)
    }
    const navigate = useNavigate();

    const getQuizInfo = useCallback(async (quizId, accessToken) => {
        setGlobalLoader()
        clearQuizState();
        if (accessToken) {
            dispatch({type: ADD_QUIZ_ACCESS_TOKEN, payload: accessToken})
        }
        try {
            await axios.get(
                `wp-json/softwebo/v1/quizzes/${quizId}`,
                {
                    headers: {Authorization: accessToken ? `AccessToken ${accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                if (res.data.caseStep.model === 'withQuizExamQuestions') {
                    const isEmptyAnswer = (el) => el.answerBackId[0] === '' || el.answerBackId.length === 0
                    if (res.data.caseStep.quiz.questions.findIndex(isEmptyAnswer) >= 0) {
                        setCurrentQuizIndex(res.data.caseStep.quiz.questions.findIndex(isEmptyAnswer))
                    } else {
                        let tempArray = []
                        res.data.caseStep.quiz.questions.forEach((question, index) => {
                            tempArray.push({
                                ...question,
                                answers: [...question.answers.map((el, idx) => ({
                                    ...el,
                                    checked: el.checked !== undefined ? el.checked : question.answerBackId?.[0] !== '' || question.answerBackId.length > 0 ? Number(question.answerBackId[0]) === idx : false,
                                    isCorrect: question.answerBackId?.[0] === question.answerBackIsCorrect[0] ? Number(question.answerBackId[0]) === idx : undefined,
                                }))],
                            })
                        })
                        unsetGlobalLoader()
                        quizComplete({startTimestamp: startTimePes, endTimestamp: Date.now(), questions: JSON.parse(JSON.stringify(tempArray))}, res.data.caseStep.id, res.data.caseStep.quiz.id, res.data.id)
                        return;
                    }
                }
                dispatch({type: GET_QUIZ_STATE, payload: res.data});
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }

    }, [token])

    const stepComplete = async () => {
        setGlobalLoader()
        try {
            await axios.post(
                `wp-json/softwebo/v1/quizzes/lesson/${state.caseStep.id}`,
                null,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                if (state.accessToken) {
                    dispatch({type: GET_QUIZ_STATE, payload: res.data});
                    unsetGlobalLoader();
                } else {
                    getQuizInfo(state.id)
                }
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const quizComplete = async (data, stepId = state.caseStep.id, quizId = state.caseStep.quiz.id, stateId = state.id) => {
        setGlobalLoader()
        try {
            await axios.post(
                `wp-json/softwebo/v1/quizzes/lesson/${stepId}/quiz/${quizId}`,
                data,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                if (state?.caseStep?.model === 'withQuiz' && state?.caseStep?.quiz?.questions[0].answers[0].isCorrect === undefined) {
                    dispatch({type: CHECK_ANSWER, payload: res.data});
                    unsetGlobalLoader();
                } else if (state.accessToken) {
                    stepComplete()
                } else {
                    getQuizInfo(stateId)
                }
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const resetQuiz = async () => {
        setGlobalLoader();
        setCurrentQuizIndex(0)
        try {
            await axios.put(
                `wp-json/softwebo/v1/cases/${state.id}/reset`,
                null,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                getQuizInfo(state.id, state.accessToken);
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const sendQuestionnaire = async (data) => {
        setGlobalLoader()
        try {
            await axios.post(
                `wp-json/softwebo/v1/forms/complete`,
                data,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                let defRes = {
                    data: {
                        code: '200',
                        message: 'Dziękujemy za wysłanie ankiety',
                    },
                }
                setModalError(defRes);
                navigate(`quizzes/variant`)
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const checkShortQuiz = async (data, questionIndex) => {
        setGlobalLoader()

        try {
            await axios.post(
                `wp-json/softwebo/v1/quizzes/lesson/${state.caseStep.id}/quiz/${state.caseStep.quiz.id}/question/${state.caseStep.quiz.questions[questionIndex].id}`,
                data,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                const tempCaseStep = JSON.parse(JSON.stringify(state.caseStep))
                tempCaseStep.quiz.questions[questionIndex] = {...res.data};
                dispatch({type: SHORT_CASE_GAMIFICATION, payload: tempCaseStep})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const clearQuizState = () => dispatch({type: CLEAR_QUIZ_STATE, payload: initialState})

    return (
        <QuizContext.Provider value={{
            quizState: state,
            resetQuiz,
            stepComplete,
            getQuizInfo,
            clearQuizState,
            quizComplete,
            checkShortQuiz,
            sendQuestionnaire,
            currentQuizIndex,
            setCurrentQuizIndex,
            startTimePes,
            setStartTimePes,
            drawArray,
            setDrawArray
        }}>
            {children}
        </QuizContext.Provider>
    )
}
