import React, { useContext, useEffect, useState } from 'react'
import { ShapeContext } from "../../../context/shape/shapeContext";
import { Container, Grid } from "@mui/material";
import PageTitles from "../../shared/ui/PageTitles";
import ShapeContent from "./ShapeContent";

const Shape = () => {

    const {getShapeInfo} = useContext(ShapeContext)
    const [isNotStartedSelected, setIsNotStartedSelected] = useState(true);
    const [isFinishedSelected, setIsFinishedSelected] = useState(true);

    const [finderValue, setFinderValue] = useState('')

    useEffect(() => {
        getShapeInfo()
    }, [])

    return (
        <Container maxWidth='xl'>
            <Grid container maxWidth={'100vw'}>
                <PageTitles
                    titleOptions='shape'
                    flag={false} filtersMenu={true}
                    isNotStartedSelected={isNotStartedSelected}
                    setIsNotStartedSelected={setIsNotStartedSelected}
                    isFinishedSelected={isFinishedSelected}
                    setIsFinishedSelected={setIsFinishedSelected}
                    finderValue={finderValue} setFinderValue={setFinderValue}
                />
                <ShapeContent
                    finderValue={finderValue}
                    isNotStartedSelected={isNotStartedSelected}
                    isFinishedSelected={isFinishedSelected}
                />
            </Grid>
        </Container>
    )
}

export default Shape;