import React, {useContext, useEffect, useState} from 'react';
import {EventContext} from '../../../../context/event/eventContext';
import {Grid} from '@mui/material';
import QuestionnaireRangeItem from '../../../shared/customElements/QuestionnaireRangeItem';
import QuizNextButton from '../../../shared/ui/buttons/QuizNextButton';
import NuvButton from '../../../shared/ui/buttons/NuvButton';
import {useParams} from 'react-router-dom';
import VideoModal from '../../../shared/modals/VideoModal';

const EventQuestionnaire = () => {
	const {eventState, sendQuestionnaire} = useContext(EventContext);
	const [specialization, setSpecialization] = useState('');
	const [specializationSet, setSpecializationSet] = useState(['']);
	const [isValid, setIsValid] = useState(false);
	const [fieldsState, setFieldsState] = useState([]);
	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

	const params = useParams();

	useEffect(() => {
		fieldsState.filter((el) => el.value !== null).length === fieldsState.length && specialization !== '' ?
			setIsValid(true) : setIsValid(false)
	}, [fieldsState, specialization])

	useEffect(() => {
		if (eventState.questionnaire?.fields[1]?.options?.values) {
			const tempArray = [];
			Object.entries(eventState.questionnaire.fields[1].options.values).forEach(([key, value]) => tempArray.push(value))
			setSpecializationSet(tempArray)
		}

		setFieldsState([])

		eventState.questionnaire?.fields.forEach(field => {
			if (field.type === 'scale') {
				const tempArray = []
				Object.entries(field.options.values).forEach(([key, value]) => tempArray.push({label: key, value}))
				setFieldsState(prev => [...prev, {
					title: field.label,
					id: +field.id,
					value: null,
					options: [...tempArray],
				}])
			}
		})

	}, [eventState])

	const radioHandler = (id, value) => {
		const tempObj = JSON.parse(JSON.stringify(fieldsState.filter(el => el.id === id)[0]))
		tempObj.value = value
		const tempArray = fieldsState.map(el => el.id === id ? tempObj : el)
		setFieldsState(JSON.parse(JSON.stringify(tempArray)));
	}

	const closeModalHandler = () => setIsVideoModalOpen(prev => !prev);

	const QuoteHandler = () => {
		const data = {
			postId: eventState.id,
			formId: eventState.questionnaire.formId,
			formKey: eventState.questionnaire.formKey,
			nonce: eventState.questionnaire.nonce,
			frmSubmitEntry: eventState.questionnaire.frmSubmitEntry,
			fields: eventState.questionnaire.fields.map((el, index) => {
				switch (el.type) {
					case 'user_id':
						return {
							id: el.id,
							value: el.options.defaultValue,
						}
					case 'select':
						return {
							id: el.id,
							value: specialization,
						}
					case 'scale':
						return {
							id: el.id,
							value: fieldsState.filter(f => +el.id === +f.id)[0].value,
						}
					case 'hidden':
						return {
							id: el.id,
							value: el.options.defaultValue,
						}
					default:
						return {}
				}
			}),
		}
		sendQuestionnaire(data)
	}

	return eventState.questionnaire &&
		<Grid className={'eventQuestionnaire'} >
			<h2 className={'eventQuestionnaire__title'}>{eventState.title}</h2>
			<Grid
				container
				flexDirection={'row'}
				flexWrap={{sx: 'wrap', sm:'nowrap'}}
				justifyContent={'center'}
				alignItems={'center'}
				gap={2}
				m={{sx: 0, sm: 1}}
			>
				<NuvButton flag={'return'} link={'/events'} title={'POWRÓT DO PRZYPADKÓW'}/>
				<NuvButton flag={'refresh'} title={'zacznij od nowa'} caseId={params.id}/>
				{
					eventState.pdf && <NuvButton flag={'pdf'} title={'pobierz podsumowanie w formacie pdf'} pdfLink={eventState.pdf}/>
				}
				<NuvButton flag={'lastSLide'} title={'odtwórz komentarz ponownie'} closeModalHandler={closeModalHandler}/>
			</Grid>
			<Grid container className={'eventQuestionnaire__columns'} display={"flex"} flexDirection={{xs: 'column', lg: 'row'}}>
				<Grid item xs={12} lg={6} height={'100%'} className={'eventQuestionnaire__columns-ls'}>
					<Grid container>
						<p className={'eventQuestionnaire__text'}>SPECJALIZACJA:</p>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6} className={'eventQuestionnaire__columns-rs'} >
					<Grid container>
						<select name="specialization" className={'eventQuestionnaire__select'} value={specialization}
								onChange={(e) => setSpecialization(e.target.value)}>
							{
								specializationSet.length > 0 && specializationSet.map((spec, index) => <option key={index}
																										   value={spec}>{spec}</option>)
							}
						</select>
					</Grid>
				</Grid>
			</Grid>

			{
				fieldsState.length > 0 && fieldsState.map((field) => (
					<QuestionnaireRangeItem
						key={field.id}
						field={field}
						radioHandler={radioHandler}
					/>
				))
			}
			{
				isValid &&
				<Grid container justifyContent={'flex-end'}>
					<QuizNextButton onClickHandler={QuoteHandler}/>
				</Grid>
			}
			{
				isVideoModalOpen && <VideoModal closeModalHandler={closeModalHandler} vimeoId={eventState.vimeoId}/>
			}
		</Grid>
}

export default EventQuestionnaire;