import React, {useContext, useEffect, useState} from 'react'
import {Container, Grid} from '@mui/material';
import PageTitles from '../../shared/ui/PageTitles';
import {MainPageContext} from '../../../context/mainPage/mainPageContext';
import {MaterialUIPagination} from '../../shared/ui/MaterialUIPagination';
import PodcastBox from '../../shared/ui/boxes/PodcastBox';
import NoContent from '../../shared/ui/NoContent';

const Podcasts = () => {

    const {
        getSpecializationsSet,
        mainPageState: {specializations, podcasts},
        getPageInfo,
        clearPodcasts,
    } = useContext(MainPageContext);

    const [isNotStartedSelected, setIsNotStartedSelected] = useState(true);
    const [isInProgressSelected, setIsInProgressSelected] = useState(true);
    const [isFinishedSelected, setIsFinishedSelected] = useState(true);
    const [specialization, setSpecialization] = useState('Obszar terapeutyczny');
    const [specializationsSelectSet, setSpecializationsSelectSet] = useState([]);
    const [finderValue, setFinderValue] = useState('')
    const [drawArray, setDrawArray] = useState([]);
    const [pageDrawArray, setPageDrawArray] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1)
    const cardsPerPage = 20;

    useEffect(() => {
        getSpecializationsSet('podcast')
        getPageInfo('podcasts')
        return () => {
            clearPodcasts();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (podcasts) {
            setCurrentPage(1);
            let tempDrawArray = [...podcasts];
            if (!isNotStartedSelected) {
                tempDrawArray = [...tempDrawArray.filter(podcast => podcast.status !== 'notListened')]
            }
            if (!isInProgressSelected) {
                tempDrawArray = [...tempDrawArray.filter(podcast => podcast.status !== 'inProgress')]
            }
            if (!isFinishedSelected) {
                tempDrawArray = [...tempDrawArray.filter(podcast => podcast.status !== 'listened')]
            }
            if (specialization !== 'Obszar terapeutyczny') {
                tempDrawArray = [...tempDrawArray.filter(podcast => !!podcast.medicalAreas.find(el => el === specialization))]
            }
            if (finderValue.trim().length) {
                tempDrawArray = [...tempDrawArray.filter(
                    podcast => podcast.title.toLowerCase().includes(finderValue.toLowerCase())
                        || (podcast.authors.length && podcast.authors.filter(author => author.name.toLowerCase().includes(finderValue.toLowerCase())).length)
                )]
            }

            setDrawArray([...tempDrawArray]);
            setPageCount(Math.ceil(tempDrawArray.length / cardsPerPage))
        }
    }, [podcasts, isNotStartedSelected, isInProgressSelected, isFinishedSelected, specialization, finderValue])

    useEffect(() => {
        if (specializations) {
            setSpecializationsSelectSet([...specializations])
        }
    }, [specializations])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <Container maxWidth="xl">
            <Grid container>
                <PageTitles
                    titleOptions="podcasts"
                    flag={false} filtersMenu={true}
                    isNotStartedSelected={isNotStartedSelected}
                    setIsNotStartedSelected={setIsNotStartedSelected}
                    isInProgressSelected={isInProgressSelected}
                    setIsInProgressSelected={setIsInProgressSelected}
                    isFinishedSelected={isFinishedSelected}
                    setIsFinishedSelected={setIsFinishedSelected}
                    specialization={specialization}
                    setSpecialization={setSpecialization}
                    specializationsSelectSet={specializationsSelectSet}
                    finderValue={finderValue} setFinderValue={setFinderValue}
                />
            </Grid>
            <Grid item xs={12}>
                {
                    drawArray.length ?
                        <>
                            <Grid container
                                  alignItems={'center'}
                                  justifyContent={{xs: 'center', lg: 'flex-start'}}
                                  m={{md: '0 auto'}}
                                  maxWidth={{md: '1000px', lg: '1900px'}}
                            >
                                {
                                    pageDrawArray.length > 0 && pageDrawArray.map(podcast => <PodcastBox podcast={podcast}
                                                                                                     key={podcast.id}/>)
                                }
                            </Grid>
                            <Grid container justifyContent={'flex-end'} pt={2} pb={2}>
                                <MaterialUIPagination
                                    count={pageCount}
                                    page={currentPage}
                                    onChange={(e, value) => setCurrentPage(value)}
                                    size={'large'}
                                />
                            </Grid>
                        </>
                        :
                        <NoContent/>
                }
            </Grid>
        </Container>
    )
}

export default Podcasts;