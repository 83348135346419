import React, {useEffect, useState} from 'react'
import wrongAnswer from '../../../../assets/img/ui/buttons/wrongAnswer.png';
import rightAnswer from '../../../../assets/img/ui/buttons/rightAnswer.png';

const AnswerBox = ({answer, answerIndex, questionIndex, changeAnswerHandler, fullWidth}) => {
	
	const [style, setStyle] = useState({
		backgroundColor: 'transparent',
		borderColor: '#00C2DF',
	})
	
	useEffect(() => {
		if (answer.isCorrect === undefined) {
			if (answer.checked) {
				setStyle(prev => ({...prev, backgroundColor: '#00C2DF'}))
			} else {
				setStyle(prev => ({backgroundColor: 'transparent', borderColor: '#00C2DF', opacity: '1'}))
			}
		} else {
			if (answer.isCorrect && answer.checked) {
				setStyle(prev => ({borderColor: '#2FD77C', backgroundColor: '#2FD77C'}))
			} else if (!answer.isCorrect && answer.checked) {
				setStyle(prev => ({borderColor: '#FF007A', backgroundColor: '#FF007A'}))
			} else {
				setStyle(prev => ({...prev, opacity: '0.5'}))
			}
		}
	}, [answer])
	
	return (
		<div
			className={fullWidth ? 'longAnswerBox' : 'answerBox'}
			style={style}
			onClick={() => {
				if (answer.isCorrect === undefined) {
					changeAnswerHandler(questionIndex, answerIndex);
				}
			}}
		>
			{
				!fullWidth && <img
					src={answer.answer.toLowerCase() === 'tak' ? rightAnswer : wrongAnswer}
					alt="answer ico"
				/>
			}
			<p>{answer.answer.toUpperCase().replace('MM HG', 'mm Hg')}</p>
		</div>
	)
}

export default AnswerBox;