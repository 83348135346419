import React, { useContext } from "react";
import { AuthContext } from "../../context/auth/authContext";
import { Box, Button, Divider, Fade, Menu, MenuItem } from "@mui/material";
import hamburgerOpen from "../../assets/img/ui/hamburgerOpen.svg";
import hamburgerClosed from "../../assets/img/ui/hamburgerClosed.svg";
import HeaderLink from "./HeaderLink";
import quizzesIco from "../../assets/img/ui/menuIcons/quizes.svg";
import shape from "../../assets/img/ui/menuIcons/shape.svg";
import podcastsIco from "../../assets/img/ui/menuIcons/podcasts.svg";
import variasIco from "../../assets/img/ui/menuIcons/varias.svg";
import profileIco from "../../assets/img/ui/menuIcons/profile.svg";
import logoutButton from "../../assets/img/ui/menuIcons/logoutButton.svg";
import home from "../../assets/img/ui/menuIcons/home.svg";

export default function MobileNavMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {isLoggedIn} = useContext(AuthContext)

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{justifyContent: 'flex-end', padding: 0, minWidth: 'unset'}}
      >
        <img src={open ? hamburgerOpen : hamburgerClosed} alt={'menu'}/>
      </Button>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
          'className': 'headerMenu--mobile',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        variant={'menu'}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <img src={open ? hamburgerOpen : hamburgerClosed} alt={'menu'} onClick={handleClose}/>
        <MenuItem onClick={handleClose}>
          <HeaderLink link="/" title="home" img={home} alt='home menu button'/> </MenuItem>
        <MenuItem onClick={handleClose}>
          <HeaderLink link="/quizzes/variant" title="QUIZY" img={quizzesIco} alt='quiz menu button'/>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HeaderLink link="/events" title="PRZYPADKI" img={shape} alt='events menu button'/>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HeaderLink link="/podcasts" title="PODCASTY" img={podcastsIco} alt='podcasts menu button'/>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HeaderLink link="/shape" title="SHAPE" img={shape} alt='shape menu button'/>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HeaderLink link="/varia" title="VARIA" img={variasIco} alt='varia menu button'/> </MenuItem>
        {
          isLoggedIn
            ?
            <MenuItem onClick={handleClose}>
              <HeaderLink link="/profile" title="PROFIL" img={profileIco} alt='profile menu button'/>
            </MenuItem>
            :
            <MenuItem onClick={handleClose}>
              <HeaderLink link="/login" title="LOGIN" img={profileIco} alt='login menu button'/>
            </MenuItem>
        }
        {
          isLoggedIn && <MenuItem> <HeaderLink link="/logout" title={'WYLOGUJ'} img={logoutButton}
                                               alt='logout menu button'/> </MenuItem>
        }
        <Divider color={'#F2F2F2'} sx={{margin: ' 0 40px'}}/>
        <Box className={'headerMenu__link'}>
          <MenuItem onClick={handleClose}>
            <a href="https://drsim.pl/api/wp-content/uploads/2023/10/RegulaminDrSimOd10.2023.pdf">
              WARUNKI KORZYSTANIA
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="https://drsim.pl/api/wp-content/uploads/2023/11/Website-Privacy-Policy_2023_PL_final.pdf">POLITYKA
              PRYWATNOŚCI </a>
          </MenuItem>
        </Box>
      </Menu>
    </>
  )
    ;
}