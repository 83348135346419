export const takeColor = (checked, isCorrect) => {
    if (checked) {
        if (isCorrect) {
            return 'singlePesAnswerBox__userAnswer-green'
        } else if (isCorrect === undefined) {
            return 'singlePesAnswerBox__userAnswer'
        } else {
            return 'singlePesAnswerBox__userAnswer-red'
        }
    } else {
        return ''
    }
}