import oncology from './../../../../assets/img/ui/pesIcons/oncology.svg'
import haematology from './../../../../assets/img/ui/pesIcons/haematology.svg'

export const data = {
    12339: {
        title: 'onkologia',
        background: '#303A60',
        image: oncology
    },
    12412: {
        title: 'hematologia',
        background: '#77315B',
        image: haematology
    }
}