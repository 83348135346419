import React from 'react';
import {Grid} from '@mui/material';

const EventVideoBox = ({onClick, isChecked, buttonInfo}) => {
	return (
		<Grid
			onClick={() => onClick(buttonInfo)}
			className={'eventVideoBox'}
		>
			<div className={`eventVideoBox__nonCheckedBox ${isChecked ? 'eventVideoBox__checkedBox' : ''}`}/>
			<p className={'eventVideoBox__text'}>{buttonInfo.text}</p>
		</Grid>
	)
}

export default EventVideoBox;