import React, {useContext} from 'react';
import {PoiContext} from '../../../context/poi/poiContext';
import {Button, Typography} from "@mui/material";
import { useBodyOverflow } from '../../../hooks/useBodyOverflow';
import { useLocation } from "react-router-dom";
import { EventContext } from "../../../context/event/eventContext";

const ErrorModal = () => {
	const {errorMessage, errorCode, unsetModalError} = useContext(PoiContext);
	useBodyOverflow()
	const location = useLocation()
	const {resetCase} = useContext(EventContext)

	const match = location?.pathname?.match(/\/events\/(\d+)/)
	const caseId = (match && match[1]) || false
	
	const handleYes = () => {
		if (caseId) {
			resetCase(caseId);
		}
		unsetModalError()
	}

	return (
		<div className="customModal errorModal">
			<div className="customModal__content">
				<Typography variant='h3'>Wiadomość:</Typography>
				<div className='customModal__content-errorMessageArea'>
					{errorCode !== 'rest_forbidden' && !match && <p
						style={{color: errorCode >= 200 && errorCode < 300 ? 'green' : 'red'}}>
						{errorCode}:
					</p>}
					<p dangerouslySetInnerHTML={{ __html: errorMessage }}>
					</p>
				</div>
				{errorCode !== 'rest_forbidden' && !match ?
				<Button onClick={unsetModalError} variant='contained'>Powrót</Button> : <div className={'customModal__content-buttonGroup'}>
						<Button onClick={unsetModalError} variant='outlined'>NIE</Button>
						<Button onClick={handleYes} variant='contained'>TAK</Button>
					</div>}
			</div>
		</div>
	)
}

export default ErrorModal;