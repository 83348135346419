import React, { useContext } from 'react'
import { Box, Button, Fade, Grid, Menu, Typography } from '@mui/material';
import { QuizContext } from '../../../context/quiz/quizContext';
import NuvButton from '../../shared/ui/buttons/NuvButton';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import infoIcon from '../../../assets/img/ui/infoIcon.svg';
import { validateString } from '../../../utils/validateString';
import { levelData } from './levelData'

const QuizInfo = () => {
  const {quizState, currentQuizIndex} = useContext(QuizContext);
  const {width} = useWindowDimensions()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const level = quizState?.caseStep?.model === 'withQuizExamQuestions' && quizState?.caseStep?.quiz.questions[currentQuizIndex].level;
  const isPesExamQuizz = quizState?.caseStep?.model === 'withQuizExamQuestions' || quizState?.caseStep?.model === 'withBeforeExamResults' || quizState?.caseStep?.model === 'withStartQuiz'

  return quizState &&
    <Grid item xs={12} lg={3} className={'quizInfo'}>
      <Grid className={'quizInfo__navigation'}>
        <NuvButton flag={'return'} link={'/quizzes/variant'} title={'POWRÓT DO QUIZOW'}/>
        <NuvButton flag={'refresh'} title={'zacznij od nowa'} quizId={quizState.id}/>
      </Grid>
      <Box className={'quizInfo__title'}>
        {width < 1200 && !isPesExamQuizz &&
          <>                    <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
              height: '60px',
              width: '60px',
              position: 'relative',
              left: '-10px',
              top: '25%',
              background: '#00C2DF',
              paddingRight: '10px',
              zIndex: '10'
            }}><img src={infoIcon} alt={'info icon'}/></Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              variant={'menu'}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
              {(quizState?.caseStep?.model !== 'withStartQuiz' || quizState?.caseStep?.model !== 'withQuizExamQuestions') &&
                <Box sx={{
                  display: 'flex',
                  height: 'auto',
                  width: '300px',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  lineHeight: '30px',
                  paddingLeft: '15px'
                }}>
                  <Typography variant={'subtitle1'}>Autorzy: </Typography>
                  {
                    quizState.details?.authors?.map((author, i) => <p
                      key={i}>{author}</p>)
                  }
                  <ul>
                    {
                      quizState.details?.institutions?.map((institution, i) => <li key={i}>
                        <Typography variant={'body2'}>{institution}</Typography></li>)
                    }
                  </ul>
                  {
                    quizState.details?.publishedBy?.map((publishing, i) => <div
                      key={i}
                      dangerouslySetInnerHTML={{__html: validateString(publishing.content)}}/>)
                  } </Box>
              }
            </Menu>
          </>

        }

        {(quizState?.caseStep?.model === 'withStartQuiz' || quizState?.caseStep?.model === 'withQuizExamQuestions'
          || quizState?.caseStep?.model === 'withBeforeExamResults') ?
          <h3 className={'quizInfo__title-additional'}>
            POWTÓRKA PRZED PES
            <br/>
            - {quizState.title} -
          </h3> :
          <h3>
            {quizState.title}
          </h3>
        }
      </Box>
      {quizState?.caseStep?.model === 'withQuizExamQuestions' &&
        <div className={'quizInfo__level'}>
          <img src={levelData[level].image} alt=""/>
          <h2>
            poziom: <span style={{color: levelData[level].color}}>{levelData[level].text}</span>
          </h2>
          <p>Autor: <strong
            dangerouslySetInnerHTML={{__html: validateString(quizState?.caseStep?.quiz.questions[currentQuizIndex].author)}}/>
          </p>
          <p>Stopień trudności pytania wynika z subiektywnej opinii autora.</p>
          <p>Pytanie opracowano w roku 2023.</p>
          <p
            className={'quizInfo__level-signature'}>{quizState?.caseStep?.quiz.questions[currentQuizIndex].signature}</p>
        </div>
      }


      {
        width > 1200 &&
        <>
          {!(quizState?.caseStep?.model === "withStartQuiz" || quizState?.caseStep?.model === "withQuizExamQuestions"
              || quizState?.caseStep?.model === "withBeforeExamResults") &&
            <>
              <p className={'quizInfo__authors'}>Autorzy: </p>
              {
                quizState.details?.authors?.map((author, i) => <p
                  className={'quizInfo__authors'} key={i}>{author}</p>)
              }
              <ul className={'quizInfo__institutions'}>
                {
                  quizState.details?.institutions?.map((institution, i) => <li key={i}>
                    <p>{institution}</p></li>)
                }
              </ul>
              {
                quizState.details?.publishedBy?.map((publishing, i) => <div
                  className={'quizInfo__publishedBy'}
                  key={i}
                  dangerouslySetInnerHTML={{__html: validateString(publishing.content)}}/>)
              }
            </>
          }
        </>
      }
    </Grid>
}

export default QuizInfo;