import {useReducer, useContext, useCallback} from 'react';
import {EventContext} from './eventContext'
import {eventReducer} from './eventReducer';
import {PoiContext} from '../poi/poiContext';
import axios from '../../axios/axios';
import {AuthContext} from '../auth/authContext';
import {ADD_EVENT_ACCESS_TOKEN, CHECK_EVENT_ANSWER, CLEAR_EVENT_STATE, GET_CURRENT_EVENT_INFO} from '../types';
import {useNavigate} from 'react-router-dom';

export const EventState = ({children}) => {
    const {setGlobalLoader, unsetGlobalLoader, setModalError} = useContext(PoiContext)
    const {token} = useContext(AuthContext)

    const initialState = {
        caseCompletedSteps: null,
        caseTotalSteps: null,
        accessToken: null,
        patientRecord: null,
        id: null,
        caseStep: null,
    }

    const [state, dispatch] = useReducer(eventReducer, initialState)

    const navigate = useNavigate();

    const getEventInfo = useCallback(async (contentId, accessToken) => {
        setGlobalLoader();
        clearEventState();
        if (accessToken) {
            dispatch({type: ADD_EVENT_ACCESS_TOKEN, payload: accessToken})
        }
        try {
            await axios.get(
                `wp-json/softwebo/v1/cases/${contentId}`,
                {
                    headers: {Authorization: accessToken ? `AccessToken ${accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                dispatch({type: GET_CURRENT_EVENT_INFO, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
        // eslint-disable-next-line
    }, [token])

    const confirmSlide = async (slideId, caseId) => {
        setGlobalLoader();
        try {
            await axios.post(
                `wp-json/softwebo/v1/lessons/${slideId}`,
                null,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then((res) => {
                if (state.accessToken) {
                    dispatch({type: GET_CURRENT_EVENT_INFO, payload: res.data})
                    unsetGlobalLoader();
                } else {
                    getEventInfo(caseId)
                }

            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const quizComplete = async (data, caseId) => {
        setGlobalLoader()
        try {
            await axios.post(
                `wp-json/softwebo/v1/lessons/${state.caseStep.id}/quiz/${state.caseStep.quiz.id}`,
                data,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                if (state.caseStep.model === 'withQuiz' && state.caseStep.quiz.questions[0].answers[0].isCorrect === undefined) {
                    dispatch({type: CHECK_EVENT_ANSWER, payload: res.data});
                    unsetGlobalLoader();
                } else if (state.accessToken) {
                    confirmSlide(state.caseStep.id, caseId)
                } else {
                    getEventInfo(caseId);
                }
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const resetCase = async (caseId) => {
        setGlobalLoader();
        try {
            await axios.put(
                `wp-json/softwebo/v1/cases/${caseId}/reset`,
                null,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                getEventInfo(caseId, state.accessToken);
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const sendQuestionnaire = async (data) => {
        setGlobalLoader()
        try {
            await axios.post(
                `wp-json/softwebo/v1/forms/complete`,
                data,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                },
            ).then(res => {
                let defRes = {
                    data: {
                        code: '200',
                        message: 'Dziękujemy za wysłanie ankiety',
                    },
                }
                setModalError(defRes);
                navigate(`/events`)
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const clearEventState = () => dispatch({type: CLEAR_EVENT_STATE, payload: initialState});

    return (
        <EventContext.Provider value={{
            eventState: state,
            quizComplete,
            getEventInfo,
            clearEventState,
            sendQuestionnaire,
            confirmSlide,
            resetCase,
        }}>
            {children}
        </EventContext.Provider>
    )
}
