import React, {useContext} from 'react';
import {Grid} from "@mui/material";
import NuvButton from "../../shared/ui/buttons/NuvButton";
import ProgressBar from "../../shared/ui/ProgressBar";
import {EventContext} from "../../../context/event/eventContext";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const EventNav = ({caseId}) => {
    const {eventState} = useContext(EventContext)
    const {width} = useWindowDimensions()

    return (
        <Grid container height={'100px'}>
            <Grid item xs={10} lg={4} pr={1}>
                <Grid container height={'100%'} alignItems={'center'} flexWrap={'nowrap'}>
                    <NuvButton flag={'return'} link={'/events'} title={'POWRÓT DO PRZYPADKÓW'}/>
                    <NuvButton flag={'refresh'} title={'zacznij od nowa'} caseId={caseId}/>
                </Grid>
            </Grid>
            <Grid item xs={2} lg={8}>

                {width > 1200 && <Grid container height={'100%'} alignItems={'center'}>
                    {
                        eventState.caseTotalSteps
                        && <ProgressBar
                            currentStep={+eventState.caseCompletedSteps + 1}
                            stepCount={+eventState.caseTotalSteps}
                        />
                    }
                </Grid>}
            </Grid>
        </Grid>
    )
}

export default EventNav;