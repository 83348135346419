import {ADD_PODCAST_ACCESS_TOKEN, CLEAR_PODCASTS_STATE, GET_CURRENT_PODCAST_INFO} from '../types';


const handlers = {
    [GET_CURRENT_PODCAST_INFO]: (state, {payload}) => ({...state, ...payload, isLoaded: true}),
    [ADD_PODCAST_ACCESS_TOKEN]: (state, {payload}) => ({...state, accessToken: payload}),
    [CLEAR_PODCASTS_STATE]: (state, {payload}) => ({...payload}),
    DEFAULT: state => state,
}

export const podcastsReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}