import React, {useContext} from 'react'
import {MainPageContext} from '../../../context/mainPage/mainPageContext';
import {Grid} from '@mui/material';
import QuizBox from '../../shared/ui/boxes/QuizBox';
import PageTitles from '../../shared/ui/PageTitles';
import NoContent from '../../shared/ui/NoContent';

const NewestQuizzes = () => {
    const {mainPageState: {quizzes}} = useContext(MainPageContext)
    return (
        <Grid container mb={'50px'}>
            <PageTitles
                titleOptions={'quizzes'}
                flag={true}
            />
            <Grid item xs={12}>
                <Grid
                    container
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={{xs: 'center', lg: 'flex-start'}}
                    m={{md: '0 auto'}}
                    maxWidth={{md: '1000px', lg: '1900px'}}
                >
                    {
                        quizzes && quizzes.length
                            ?
                            quizzes.map(quiz => <QuizBox quiz={quiz} key={quiz.id}/>)
                            :
                            <NoContent/>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewestQuizzes