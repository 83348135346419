import React, { useContext, useEffect, useState } from 'react';
import { MainPageContext } from '../../../context/mainPage/mainPageContext';
import { Container, Grid } from '@mui/material';
import PageTitles from '../../shared/ui/PageTitles';
import { MaterialUIPagination } from '../../shared/ui/MaterialUIPagination';
import QuizPesBox from '../../shared/ui/boxes/QuizPesBox';
import NoContent from '../../shared/ui/NoContent';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const QuizzesPes = ({flag, type}) => {

    const {
        mainPageState: {quizzes},
        getPageInfo,
        clearQuizzes,
    } = useContext(MainPageContext);
    const [drawArray, setDrawArray] = useState([]);
    const [pageDrawArray, setPageDrawArray] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1)
    const {width} = useWindowDimensions();
    const cardsPerPage = !type && width > 1200 ? 12 : !type && width < 1200 ? 12 : 4

    useEffect(() => {
        getPageInfo(flag)
        return () => {
            clearQuizzes();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (quizzes) {
            setCurrentPage(1);
            let tempDrawArray = [...quizzes];

            setDrawArray([...tempDrawArray]);

            setPageCount(Math.ceil(tempDrawArray.length / cardsPerPage))
        }
    }, [quizzes, type, cardsPerPage])


    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <>
            <Container maxWidth="xl">
                <Grid container>
                    <PageTitles
                        titleOptions="quizzes"
                        subTitleOption={flag}
                        type={type}
                        flag={false}
                    />
                    <Grid item xs={12}>
                        {
                            drawArray.length ?
                                <>
                                    <Grid container
                                          justifyContent={{xs: 'center', lg: 'flex-start'}}
                                          m={{md: '0 auto'}}
                                          maxWidth={{md: '1000px', lg: '1900px'}}
                                    >
                                        {
                                            pageDrawArray.length && pageDrawArray.map(quiz => <QuizPesBox quiz={quiz}
                                                                                                          key={quiz.id}/>)
                                        }
                                    </Grid>
                                    <Grid container justifyContent={'flex-end'} pt={2} pb={2}>
                                        <MaterialUIPagination
                                            count={pageCount}
                                            page={currentPage}
                                            onChange={(e, value) => setCurrentPage(value)}
                                            size={'large'}
                                        />
                                    </Grid>
                                </>
                                :
                                <NoContent/>
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default QuizzesPes;