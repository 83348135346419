import {
	CLEAR_EVENTS, CLEAR_MAIN_PAGE_INFO, CLEAR_PODCASTS,
	CLEAR_QUIZZES,
	GET_EVENTS,
	GET_MAIN_PAGE_INFO,
	GET_PODCASTS,
	GET_QUIZZES,
	GET_SPECIALIZATIONS,
	GET_FORMSET,
} from '../types';

const handlers = {
	[GET_MAIN_PAGE_INFO]: (state, {payload}) => ({...state, ...payload}),
	[CLEAR_MAIN_PAGE_INFO]: (state, {payload}) => ({...payload}),
	[GET_SPECIALIZATIONS]: (state, {payload}) => ({...state, specializations: payload}),
	[GET_EVENTS]: (state, {payload}) => ({...state, events: payload}),
	[CLEAR_EVENTS]: (state) => ({...state, events: null}),
	[GET_QUIZZES]: (state, {payload}) => ({...state, quizzes: payload}),
	[CLEAR_QUIZZES]: (state) => ({...state, quizzes: null}),
	[GET_PODCASTS]: (state, {payload}) => ({...state, podcasts: payload}),
	[CLEAR_PODCASTS]: (state) => ({...state, podcasts: null}),
	[GET_FORMSET]: (state, {payload}) => ({...state, formSet: [...payload]}),

	DEFAULT: state => state,
}

export const mainPageReducer = (state, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT
	return handle(state, action)
}