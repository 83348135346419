import React, {useContext, useEffect, useState} from 'react';
import {EventContext} from "../../../../context/event/eventContext";
import {Grid} from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import HtmlSelector from "../../../shared/pureHtml/HtmlSelector";

const VideoVsVideoSlide = ({showButtonHandler}) => {
    const {eventState: {caseStep}} = useContext(EventContext);
    const [videoToShow, setVideoToShow] = useState(null);
    const [slideStep, setSlideStep] = useState(1);

    //step description
    //step 1 - video
    //step 2 - buttons
    //step 3 - comment from button
    //step 4 - video from button
    //step 5 - comment for slide
    const [buttonIndex, setButtonIndex] = useState(null);
    
    useEffect(() => {
        showButtonHandler({type: null, show: false});
    }, [])
    
    useEffect(() => {
        if (slideStep === 5) {
            showButtonHandler({type: 'slide', show: true});
        }
        // eslint-disable-next-line
    }, [slideStep])

    useEffect(() => {
        setVideoToShow(caseStep.video)
    }, [caseStep])

    return (
        <Grid
            className={'videoVsVideo'}
            container
            height={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            {
                videoToShow &&
                <Vimeo
                    className={'videoVsVideo__videoWrapper'}
                    video={videoToShow}
                    autoplay
                    allowfullscreen
                    transparent={true}
                    onEnd={() => {
                        setSlideStep(prev => prev + 1)
                        setVideoToShow(null)
                    }}
                />
            }
            {
                slideStep === 2 &&
                <Grid
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    height={'auto'}
                >
                    <h3>Proszę wybrać pytanie, które w pierwszej kolejności warto zadać pacjentowi:</h3>
                    <Grid container className={'videoVsVideo__buttonWrapper'} display={"flex"}>
                        {
                            caseStep.videoButtons.map((el, index) => (
                                <Grid
                                    className={'videoVsVideo__buttonWrapper-textWrapper'}
                                    key={index}
                                    onClick={() => {
                                        setButtonIndex(index)
                                        setSlideStep(prev => prev + 1)
                                    }}
                                >
                                    <p>{el.text}</p>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}>
                        <div className={'refreshButton'}
                             onClick={() => {
                                 setSlideStep(1)
                                 setVideoToShow(caseStep.video)
                             }}
                        >
                            <span className={'refreshButton__img'}/>
                            <span className={'refreshButton__title'}
                                  style={{textAlign: 'center'}}>Odtwórz film ponownie</span>
                        </div>
                    </div>
                </Grid>
            }
            {
                slideStep === 3 &&
                <Grid
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    height={'auto'}
                >
                    {caseStep.videoButtons[buttonIndex].videoComment.map((el, i) => <HtmlSelector key={i}
                                                                                                  element={el}/>)}
                    <button
                        className={'videoVsVideo__nextButton'}
                        onClick={() => {
                            setVideoToShow(caseStep.videoButtons[buttonIndex].vimeoId)
                            setSlideStep(prev => prev + 1);
                        }}
                    >Przejdź dalej</button>
                </Grid>
            }
            {
                slideStep === 5 &&
                <Grid
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    height={'auto'}
                >
                    {caseStep.comment.map((el, i) => <HtmlSelector key={i} element={el}/>)}
                </Grid>
            }
        </Grid>
    )
}

export default VideoVsVideoSlide;
