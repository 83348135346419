import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/auth/authContext';
import { Grid, Stack } from '@mui/material';
import StatusVideoButton from '../buttons/StatusVideoButton';
import videoIco from '../../../../assets/img/ui/videoIco.png';
import { useNavigate } from 'react-router-dom';
import { timeConverter } from '../../../../utils/timeConverter';
import { validateString } from '../../../../utils/validateString';
import noImage from '../../../../assets/img/ui/video-box/no-image.jpg';

const VariaVideoBox = ({ element, type }) => {
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate()
    const [thumbnailLink, setThumbnailLink] = useState('')

    useEffect(() => {
        if (element.vimeoData) {
            (async () => {
                const response = await fetch(
                    element.vimeoData.url,
                    {
                        headers: {
                            Authorization: `Bearer ${element.vimeoData.token}`,
                        }
                    })
                const data = await response.json()
                setThumbnailLink(data.pictures.base_link)
            })()
        }

    }, [])

    return (<Grid
        item
        className={'variaVideoBox'}
        onClick={() => {
            type === 'varia' ? navigate(`/varia/video/${element.id}`) : navigate(`/shape/video/${element.id}`)
        }}
    >
        <Grid
            container
            height={'100%'}
            flexDirection={'column'}
            justifyContent={'space-between'}
        >
            <Grid
                item
                className={'variaVideoBox__photoArea'}
            >
                <img
                    className={'variaVideoBox__photoArea-photo'}
                    src={thumbnailLink !== '' ? thumbnailLink : noImage}
                    alt="video thumbnail"
                />
                <img
                    className={'variaVideoBox__photoArea-button'}
                    src={videoIco} alt="video button"
                />
            </Grid>
            <p className={'variaVideoBox__title'}>{element.title}</p>
            <Grid
                item
                className={'variaVideoBox__description'}
            >
                {!!element.description && element.description.map((el, i) => <p key={i}
                    dangerouslySetInnerHTML={{ __html: validateString(el.content) }} />)}
            </Grid>
            <Grid item width={'100%'}>
                <Grid
                    container
                    className={'variaVideoBox__footer'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexDirection={'row'}
                >
                    <Grid
                        item
                        flexGrow={1}
                        height={'100%'}
                        mr={2}
                        maxWidth={'calc(100% - 60px)'}
                    >
                        <Grid container flexDirection={'row'} flexWrap={{ xs: 'wrap', lg: 'nowrap' }}>
                            <p className={'variaVideoBox__footer-text variaVideoBox__footer-ls'}>autorzy:</p>
                            <Stack direction={'column'}>
                                {element.authors.length && element.authors.map((author, index) => <p
                                    className={'variaVideoBox__footer-text'} key={index}>{author.name}</p>)}
                            </Stack>
                        </Grid>
                        <Grid container flexDirection={'row'} flexWrap={'nowrap'}>
                            <p className={'variaVideoBox__footer-text variaVideoBox__footer-ls'}>czas trwania:</p>
                            <p className={'variaVideoBox__footer-text'}>{timeConverter(element.duration)}</p>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {isLoggedIn && <StatusVideoButton status={element.status} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}

export default VariaVideoBox;