import {useContext, useReducer} from 'react';
import {mainPageReducer} from './mainPageReducer';
import {MainPageContext} from './mainPageContext';
import {PoiContext} from '../poi/poiContext';
import {AuthContext} from '../auth/authContext';

import axios from '../../axios/axios';
import multipleAxios from 'axios';
import {
    CLEAR_EVENTS,
    CLEAR_MAIN_PAGE_INFO,
    CLEAR_PODCASTS,
    CLEAR_QUIZZES,
    GET_EVENTS,
    GET_FORMSET,
    GET_MAIN_PAGE_INFO,
    GET_PODCASTS,
    GET_PROFILE_PAGE_INFO,
    GET_QUIZZES,
    GET_SPECIALIZATIONS,
} from '../types';

export const MainPageState = ({children}) => {

    const {setGlobalLoader, unsetGlobalLoader, setModalError} = useContext(PoiContext);
    const {token, isLoggedIn} = useContext(AuthContext)

    const initialState = {
        specializations: null,
        events: null,
        quizzes: null,
        podcasts: null,
        formSet: null,
    }

    const [state, dispatch] = useReducer(mainPageReducer, initialState);

    const getFormSet = async () => {
        setGlobalLoader()
        try {
            axios.get(
                'wp-json/softwebo/v1/users/form-set',
            ).then(res => {
                dispatch({type: GET_FORMSET, payload: [...res.data]})
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const getSpecializationsSet = async (type = null) => {
        setGlobalLoader()
        try {
            axios.get(
                !type ? 'wp-json/softwebo/v1/medical-areas' : `wp-json/softwebo/v1/medical-areas?type=${type}`,
            ).then(res => {
                dispatch({type: GET_SPECIALIZATIONS, payload: res.data})
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const getMainPageInfo = async () => {
        setGlobalLoader()
        try {
            await multipleAxios.all([
                axios.get(
                    'wp-json/softwebo/v1/quizzes?limit=3',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ),
                axios.get(
                    'wp-json/softwebo/v1/cases?limit=4',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ),
                axios.get(
                    'wp-json/softwebo/v1/podcasts?limit=4',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ),
            ]).then(multipleAxios.spread((res1, res2, res3) => {
                dispatch({
                    type: GET_MAIN_PAGE_INFO,
                    payload: {quizzes: res1.data, events: res2.data, podcasts: res3.data},
                })
                unsetGlobalLoader();
            }))
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const getProfilePageInfo = async () => {
        setGlobalLoader()
        try {
            await multipleAxios.all([
                axios.get(
                    'wp-json/softwebo/v1/quizzes?limit=3',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ),
                axios.get(
                    'wp-json/softwebo/v1/cases?limit=4',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ),

            ]).then(multipleAxios.spread((res1, res2) => {
                dispatch({
                    type: GET_PROFILE_PAGE_INFO,
                    payload: {quizzes: res1.data, events: res2.data},
                })
                unsetGlobalLoader();
            }))
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const clearMainPageInfo = () => dispatch({type: CLEAR_MAIN_PAGE_INFO, payload: initialState})

    const getPageInfo = (flag) => {
        setGlobalLoader()
        try {

            if (flag === 'events') {
                axios.get(
                    'wp-json/softwebo/v1/cases',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ).then(res => dispatch({type: GET_EVENTS, payload: res.data}))
            } else if (flag === 'fast-fives' || flag === 'challenges' || flag === 'what-went-wrong' || flag === 'pictorial-curiosities') {
                axios.get(
                    `wp-json/softwebo/v1/quizzes/${flag}`,
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined
                ).then(res => dispatch({type: GET_QUIZZES, payload: res.data}))}
            else if ( flag === 'before-exam') {
                    axios.get(
                        `wp-json/softwebo/v1/quizzes/${flag}`,
                        isLoggedIn ? {
                            headers: {Authorization: `Bearer ${token}`},
                        } : undefined
                    ).then(res => {dispatch({type: GET_QUIZZES, payload: res.data});  unsetGlobalLoader()})
            } else if (flag === 'all') {
                axios.get(
                    'wp-json/softwebo/v1/quizzes/',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ).then(res => dispatch({type: GET_QUIZZES, payload: res.data}))
            } else if (flag === 'podcasts') {
                axios.get(
                    'wp-json/softwebo/v1/podcasts?limit=-1',
                    isLoggedIn ? {
                        headers: {Authorization: `Bearer ${token}`},
                    } : undefined,
                ).then(res => dispatch({type: GET_PODCASTS, payload: res.data}))
            }
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const clearEvents = () => dispatch({type: CLEAR_EVENTS})
    const clearQuizzes = () => dispatch({type: CLEAR_QUIZZES})
    const clearPodcasts = () => dispatch({type: CLEAR_PODCASTS})

    return (
        <MainPageContext.Provider value={{
            mainPageState: state,
            specializations: state.specializations,
            getMainPageInfo,
            getProfilePageInfo,
            clearMainPageInfo,
            getSpecializationsSet,
            getPageInfo,
            clearEvents,
            clearQuizzes,
            clearPodcasts,
            getFormSet,
        }}>
            {children}
        </MainPageContext.Provider>
    )
}