import React, {useEffect} from 'react'
import HtmlSelector from '../../../shared/pureHtml/HtmlSelector';

const TextSlide = ({content, showButtonHandler}) => {
	
	useEffect(() => {
		showButtonHandler({type: 'slide', show: true});
	}, [])
	
	return (
		<>
			{
				content.map((contentElement, index) => <HtmlSelector element={contentElement} key={index}/>)
			}
		</>
	)
}

export default TextSlide;