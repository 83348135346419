import React from 'react'
import {Stack} from '@mui/material';
import {NavLink} from 'react-router-dom';

const HeaderLink = ({link, img, title, alt, path}) => {
    return (
        <NavLink
            className={({isActive}) => `headerMenu__link  ${isActive && 'headerMenu__activeLink'}`}
            to={link}
        >
            < Stack
                direction={'row'}
                flexWrap={'nowrap'}
                alignItems={'center'}
                height={'100%'}>
                < img
                    src={img}
                    alt={alt}
                />
                <div>
                     {
                         title
                     }
                </div>

            </Stack>
        </NavLink>

    )
}

export default HeaderLink;