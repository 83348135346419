import React, { useContext, useEffect, useState } from 'react'
import { MainPageContext } from "../../../context/mainPage/mainPageContext";
import { VariaContext } from "../../../context/varia/variaContext";
import { Container, Grid } from "@mui/material";
import PageTitles from "../../shared/ui/PageTitles";
import Materials from "./Materials";

const Varia = () => {
    const {
        getSpecializationsSet,
        mainPageState: {specializations},
    } = useContext(MainPageContext)

    const {getVariaInfo} = useContext(VariaContext)
    const [isNotStartedSelected, setIsNotStartedSelected] = useState(true);
    const [isFinishedSelected, setIsFinishedSelected] = useState(true);
    const [specialization, setSpecialization] = useState('Obszar terapeutyczny');
    const [specializationsSelectSet, setSpecializationsSelectSet] = useState([]);
    const [finderValue, setFinderValue] = useState('')

    useEffect(() => {
        getVariaInfo()
        getSpecializationsSet('varia')
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (specializations) {
            setSpecializationsSelectSet([...specializations])
        }
    }, [specializations])

    return (
        <Container maxWidth='xl'>
            <Grid container maxWidth={'100vw'}>
                <PageTitles
                    titleOptions='varia'
                    flag={false} filtersMenu={true}
                    isNotStartedSelected={isNotStartedSelected}
                    setIsNotStartedSelected={setIsNotStartedSelected}
                    isFinishedSelected={isFinishedSelected}
                    setIsFinishedSelected={setIsFinishedSelected}
                    specialization={specialization}
                    setSpecialization={setSpecialization}
                    specializationsSelectSet={specializationsSelectSet}
                    finderValue={finderValue} setFinderValue={setFinderValue}
                />
            </Grid>
            <Materials
                materialOption={'video'}
                isNotStartedSelected={isNotStartedSelected}
                isFinishedSelected={isFinishedSelected}
                specialization={specialization}
                finderValue={finderValue}
            />
            <Materials
                materialOption={'image'}
                specialization={specialization}
                finderValue={finderValue}
            />
            <Materials
                materialOption={'text'}
                specialization={specialization}
                finderValue={finderValue}
            />
        </Container>
    )
}

export default Varia;