import React, {useContext} from 'react'
import {Grid} from '@mui/material';
import PodcastBox from '../../shared/ui/boxes/PodcastBox';
import PageTitles from '../../shared/ui/PageTitles';
import {MainPageContext} from '../../../context/mainPage/mainPageContext';
import NoContent from '../../shared/ui/NoContent';

const NewestPodcasts = () => {
    const {mainPageState: {podcasts}} = useContext(MainPageContext)
    return (
        <Grid container>
            <PageTitles
                titleOptions={'podcasts'}
                flag={true}
            />
            <Grid item xs={12}>
                <Grid
                    container
                    alignItems={'center'}
                    justifyContent={{xs: 'center', lg: 'flex-start'}}
                    m={{md: '0 auto'}}
                    maxWidth={{md: '1000px', lg: '1900px'}}
                >
                    {
                        podcasts
                            ?
                            podcasts.map(podcast => <PodcastBox isOneLine={true} podcast={podcast} key={podcast.id}/>)
                            :
                            <NoContent />
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewestPodcasts