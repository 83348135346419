import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {EventContext} from '../../../context/event/eventContext';
import {Container, Grid, Typography} from '@mui/material';
import EventNav from './EventNav';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PatientInfo from "./PatientInfo";
import PatientTabs from "./PatientTabs";
import EventContent from "./EventContent";
import EventNextButton from "../../shared/ui/buttons/EventNextButton";
import EventQuestionnaire from "./slides/EventQuestionnaire";
import {AuthContext} from "../../../context/auth/authContext";

const Event = () => {
    const {width} = useWindowDimensions()

    const {loginToken} = useContext(AuthContext);
    const {
        getEventInfo,
        clearEventState,
        eventState: {patientRecord, caseStep, questionnaire},
        confirmSlide,
        quizComplete, eventState,
    } = useContext(EventContext)

    const [isShowButton, setIsShowButton] = useState({
        type: null,
        show: false,
    });

    const [quizData, setQuizData] = useState({})

    const params = useParams()

    useEffect(() => {
        return () => {
            clearEventState()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getEventInfo(params.id, params.accessToken ?? loginToken ?? null)
    }, [getEventInfo, loginToken, params.accessToken, params.id])

    const nextSlideHandler = () => {
        if (isShowButton.type === 'slide') {
            confirmSlide(caseStep.id, params.id)
        } else if (isShowButton.type === 'quiz') {
            quizComplete({...quizData, endTimestamp: Date.now()}, params.id)
        }
    }

    const showButtonHandler = (flag) => setIsShowButton(flag)

    return (
        <Container maxWidth="xl" p={0}>
            {
                !questionnaire
                    ?
                    <>
                        <EventNav caseId={params.id}/>
                        <Grid container className={'event'} justifyContent={'space-between'}
                        >
                            {/*left side*/}
                            <Grid item
                                  className={'eventIn'}
                                  xs={12} lg={4}
                                  maxHeight={{xs: 'none', lg: `calc(100vh - 440px)`}}

                                  sx={{
                                      height: '100%',
                                      overflowY: 'auto',
                                      width: "100%"
                                  }}>
                                <Grid container
                                      flexDirection={'column'} gap={1} height={'100%'} flexWrap={'nowrap'} pr={{lg: 1}} >
                                    {
                                        patientRecord &&
                                        <>
                                            <PatientInfo/>
                                            {width > 1200 && <PatientTabs/>
                                            }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            {/*right side*/}
                            <Grid item xs={12} lg={8} className={'eventContentBorder eventContentCutBorder'} height={'100%'}>
                                <Grid item xs={12} display={{xs: 'flex', lg: 'none'}} justifyContent={'flex-start'}
                                      paddingY={1}>
                                    <Typography fontSize={'16px'} variant={'subtitle1'} fontWeight={700}
                                                color={'primary'}> {eventState.caseCompletedSteps + 1} / {+eventState.caseTotalSteps} </Typography>
                                </Grid>
                                {
                                    caseStep &&
                                    <EventContent showButtonHandler={showButtonHandler} setQuizData={setQuizData}/>
                                }
                                <div className={'eventBorderBottom'}
                                     style={{width: isShowButton.show ? 'calc(100% - 140px)' : '100%'}}/>
                                {
                                    isShowButton.show && <EventNextButton onClickHandler={nextSlideHandler}/>
                                }

                            </Grid>
                        </Grid>
                    </>
                    :
                    <EventQuestionnaire/>
            }
        </Container>

    )
}

export default Event;