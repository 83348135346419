import React, { useContext, useEffect, useState } from 'react';
import { EventContext } from '../../../context/event/eventContext';
import { Box, Button, Fade, Grid, Menu } from '@mui/material';
import PatientAvatar from '../../shared/ui/PatientAvatar';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import infoIcon from '../../../assets/img/ui/infoIcon.svg'
import HtmlSelector from "../../shared/pureHtml/HtmlSelector";
import { validateString } from "../../../utils/validateString";

const PatientInfo = () => {
  const {width} = useWindowDimensions()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {eventState: {patientRecord}} = useContext(EventContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {eventState: {patientRecord: {tabs}}} = useContext(EventContext)
  const [openTabIndex, setOpenTabIndex] = useState(0)

  useEffect(() => {
  }, [openTabIndex])

  return (
    <Grid
      container
      className={'patientInfo'}
      border={{xs: 'none', lg: '1px solid white'}}
      borderTop={{xs: '1px solid white',}}
      borderBottom={{xs: '1px solid white'}}
      maxWidth={'100vw'}
      borderRadius={{xs: 'none', lg: '5px'}}
      justifyContent={{xs: 'center', sm: 'space-between', lg: 'flex-start'}}
      alignItems={'center'}
      // overflow={'hidden'}
      flexWrap={"nowrap"}
    >
      {width < 1200 &&
        <Grid
          item xs={2} marginLeft={'-5px'} paddingRight={'5px'}>
          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
              height: '60px',
              width: '60px',
              // position: 'relative',
              // left: '0',
              // top: '25%',
              background: '#00C2DF',
              // paddingRight: '10px',
              zIndex: '10',
            }}><img src={infoIcon} alt={'info icon'}/></Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            variant={'menu'}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            <Box width={{xs: '100%', md: '350px'}} sx={{
              display: 'flex',
              height: 'auto',
              // width: '300px',
              alignItems: 'flex-start',
              flexDirection: 'column',
              lineHeight: '30px',
              paddingLeft: '15px',
              paddingRight: '15px'
            }}>
              <button className={'patientInfo__button'} onClick={handleClose}/>
              <Grid container className={'event__tabs-header'} justifyContent={'space-between'}
                    alignItems={'center'}>
                {
                  Object.entries(tabs).map(([key], index) => (
                    <Grid
                      item
                      flexGrow={1}
                      key={index}
                      style={{
                        borderBottom: openTabIndex === index ? '4px solid #00C2DF' : '4px solid rgb(242, 242, 242)',
                        color: openTabIndex === index ? '#00C2DF' : 'rgb(242, 242, 242)',
                      }}>
                      <p
                        onClick={() => setOpenTabIndex(index)}
                      >{key}</p>
                    </Grid>
                  ))
                }
              </Grid>
              <ul className={'patientInfo__list'}>
                <Grid container>
                  {
                    Object.entries(tabs)
                      .filter(([key, value], index) => index === +openTabIndex)[0][1]
                      .map((el, i) => <HtmlSelector element={el} key={i} tabs/>)
                  }
                </Grid>
              </ul>
            </Box>
          </Menu>
        </Grid>

      }
      <Grid item xs={6} md={6} lg={6} paddingLeft={2}>
        <Grid container height={'fit-content'} justifyContent={'center'} alignItems={'center'}>
          <PatientAvatar link={patientRecord.avatar} cn={'event__patientAvatar'}/>
        </Grid>
      </Grid>
      <Grid item xs={4} md={6} lg={6} paddingLeft={2}>
        {
          patientRecord.name && <p className={'event__patientInitials'}>{patientRecord.name} </p>
        }
        {
          patientRecord.description
          && patientRecord.description.map((infoLine, index) =>
            <p className={'event__patientDescription'} key={index}>{infoLine.title}
              {' '}<span dangerouslySetInnerHTML={{__html: validateString(infoLine.content)}}/></p>)
        }
      </Grid>

    </Grid>
  )
}

export default PatientInfo