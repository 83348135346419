import React, { useContext, useEffect, useState } from 'react';
import {Grid, Typography} from '@mui/material';
import {EventContext} from '../../../../context/event/eventContext';
import AnswerBox from './AnswerBox';
import HtmlSelector from '../../../shared/pureHtml/HtmlSelector';
import EventCommentButton from '../../../shared/ui/buttons/EventCommentButton';
import CommentModal from '../../../shared/modals/CommentModal';
import EventComment from '../../../shared/customElements/EventComment';
import {validateString} from "../../../../utils/validateString";

const QuizzesSlide = ({showButtonHandler, setQuizData}) => {

    const {eventState: {caseStep}} = useContext(EventContext);
    const [drawArray, setDrawArray] = useState([]);
    const [startTimestamp, setStartTimestamp] = useState(0);
    const [commentModalContent, setCommentModalContent] = useState(null)

    useEffect(() => {
        if (drawArray.filter(question => question.answers.filter(answer => answer.checked).length > 0,
        ).length === drawArray.length) {
            showButtonHandler({type: 'quiz', show: true});
        }
        setQuizData({
            startTimestamp,
            questions: drawArray,
        })
        // eslint-disable-next-line
    }, [drawArray])

    useEffect(() => {
        showButtonHandler({type: null, show: false})
        if (caseStep.quiz?.questions?.length) {
            const tempArray = []
            caseStep.quiz.questions.forEach(el => tempArray.push({
                ...el,
                answers: [...el.answers.map(answ => ({
                    ...answ,
                    checked: answ.checked !== undefined ? answ.checked : false,
                }))],
            }))
            setDrawArray(JSON.parse(JSON.stringify(tempArray)))
            setStartTimestamp(Date.now())
        }
        // eslint-disable-next-line
    }, [caseStep])

    const changeAnswerHandler = (questionIndex, answerIndex) => {
        if (!drawArray[questionIndex].answers[answerIndex].checked) {
            setDrawArray(prev => JSON.parse(JSON.stringify(
                prev.map((question, index) =>
                    index !== questionIndex
                        ?
                        question
                        :
                        {
                            ...question,
                            answers: question.answers.map((answer, i) => ({...answer, checked: i === answerIndex})),
                        },
                ),
            )))
        }
    }

    const closeCommentModal = () => setCommentModalContent(null)

    const openCommentModal = (modalContent) => setCommentModalContent({...modalContent})

    return (
        <Grid borderTop={{xs: '1px solid #fff', lg: 'none'}} >
            {
                caseStep.content.map((contentElement, index) => <HtmlSelector element={contentElement} key={index}/>)
            }
            <Grid p={{xs: '0 0 24px 0', lg: '0 24px 24px'}} container>
                {
                    drawArray.length > 0 && drawArray.map((question, questionIndex) => (
                        <React.Fragment key={questionIndex}>
                            {
                                question.desc &&
                                <Grid className={'eventQuestion__desc'} dangerouslySetInnerHTML={{__html: validateString(question.desc)}}/>
                            }
                            <Grid
                                container
                                sx={{borderBottom: '1px solid #fff'}}
                                padding={{xs: '16px 0px', lg: '16px 8px'}}
                                flexDirection={'column'}
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        flexDirection={{xs: 'column', lg: question.fullWidth ? 'column' : 'row'}}
                                        flexWrap={'nowrap'}
                                        alignItems={{ xs: 'flex-start', lg: 'center'}}
                                        gap={{xs: '10px' , lg: question.fullWidth ? 1 : 0}}
                                        justifyContent={'space-between'}
                                        key={questionIndex}
                                    >
                                        <Typography
                                            fontSize={{xs: '12px', lg: '16px'}}
                                            maxWidth={{xs: '100%', md: '100%'}}
                                            lineHeight={{xs: '18px', sm: '20px', lg: '22px'}}

                                            style={{
                                                color: '#fff',
                                                fontWeight: '400',
                                            }}
                                            dangerouslySetInnerHTML={{__html: validateString(question.question)}}>
                                        </Typography>
                                        <Grid item width={question.fullWidth ? '100%' : 'auto'}
                                              display={'flex'}
                                              margin={!question.fullWidth && '0 0 0 auto' }
                                          >
                                            <Grid
                                                container
                                                flexDirection={question.fullWidth ? 'column' : 'row'}
                                               
                                                flexWrap={'nowrap'}
                                                gap={2}
                                            >
                                                {
                                                    question.answers.map((answer, answerIndex) => (
                                                        <AnswerBox
                                                            key={answerIndex}
                                                            fullWidth={question.fullWidth}
                                                            answer={answer}
                                                            answerIndex={answerIndex}
                                                            questionIndex={questionIndex}
                                                            changeAnswerHandler={changeAnswerHandler}
                                                        />
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    question.correctMsg?.length > 0 && question.incorrectMsg?.length > 0
                                        ?
                                        <EventComment
                                            comment={question.answers.filter(el => el.isCorrect && el.checked).length ? question.correctMsg : question.incorrectMsg}/>
                                        :
                                        null
                                }
                                {
                                    question.modals?.length
                                        ?
                                        question.modals.map((button, index) => <EventCommentButton content={button}
                                                                                                   commentSetter={openCommentModal}
                                                                                                   key={index}/>)
                                        :
                                        null
                                }
                            </Grid>
                        </React.Fragment>
                    ))
                }
            </Grid>
            {
                caseStep.quiz?.afterQuestions &&
                <Grid className={'eventQuiz__afterQuiz'} dangerouslySetInnerHTML={{__html: validateString(caseStep.quiz?.afterQuestions)}}/>
            }
            {
                commentModalContent &&
                <CommentModal closeModalHandler={closeCommentModal} content={commentModalContent}/>
            }
        </Grid>
    )
}

export default QuizzesSlide;