import React, {useContext} from 'react';
import {PoiContext} from '../context/poi/poiContext';
import {AuthContext} from '../context/auth/authContext';
import { Route, Routes, useParams } from 'react-router-dom';
import {ROLE_ADMINISTRATOR, ROLE_GUEST, ROLE_USER} from '../consts/projectUsers';
import {Grid} from '@mui/material';
import ErrorModal from './shared/modals/ErrorModal';
import GlobalLoader from './shared/ui/GlobalLoader';
import Footer from './shared/Footer';
import Header from './shared/Header';
import ProtectedRoute from '../routes/ProtectedRoute';
import MainPage from './pages/mainPage/MainPage';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Registration from './pages/auth/Registration';
import ResetPassword from './pages/auth/ResetPassword';
import Profile from './pages/profile/Profile';
import QuizzesBoxes from './pages/quizzes/QuizzesBoxes';
import Quizzes from './pages/quizzes/Quizzes';
import QuizzesPes from './pages/quizzes/QuizzesPes';
import Quiz from './pages/quiz/Quiz';
import Events from './pages/events/Events';
import Event from './pages/event/Event';
import Varia from './pages/varia/Varia';
import VariaVideoPage from './pages/varia/VariaVideoPage';
import Podcasts from './pages/podcasts/Podcasts';
import Podcast from './pages/podcast/Podcast';
import Shape from './pages/shape/Shape'
import ShapeVideoPage from './pages/shape/ShapeVideoPage'
// import {AccessDenied} from './pages/accessDenied/AccessDenied';

const App = () => {
	const {isGlobalLoader, isGlobalError} = useContext(PoiContext);
	const {isUserLoad} = useContext(AuthContext);

	return (
		// <AccessDenied/>
		isUserLoad
			?
			<>
				<Grid container className="app" flexDirection={'column'}>
					<Header/>
					<Grid item flexGrow={1} maxWidth={'100vw'}>
						<Routes>
							<Route
								path={'/'}
								element={
									<ProtectedRoute>
										<MainPage/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/variant'}
								element={
									<ProtectedRoute>
										<QuizzesBoxes/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/fast-fives'}
								element={
									<ProtectedRoute>
										<Quizzes flag={'fast-fives'}/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/before-exam'}
								element={
									<ProtectedRoute>
										<QuizzesPes flag={'before-exam'}/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/challenges'}
								element={
									<ProtectedRoute>
										<Quizzes flag={'challenges'}/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/what-went-wrong'}
								element={
									<ProtectedRoute>
										<Quizzes flag={'what-went-wrong'}/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/pictorial-curiosities'}
								element={
									<ProtectedRoute>
										<Quizzes flag={'pictorial-curiosities'}/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/quiz/:id'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_ADMINISTRATOR, ROLE_USER]}
										navigateLink={'/login'}
									>
										<Quiz/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/quizzes/quiz/:id/:accessToken'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_GUEST]}
										navigateLink={'/quizzes/variant'}
									>
										<Quiz />
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/events'}
								element={
									<ProtectedRoute>
										<Events/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/events/:id'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_ADMINISTRATOR, ROLE_USER]}
										navigateLink={'/login'}
									>
										<Event/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/events/:id/:accessToken'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_GUEST]}
										navigateLink={'/events'}
									>
										<Event/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/varia'}
								element={
									<ProtectedRoute>
										<Varia/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/varia/video/:id'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_ADMINISTRATOR, ROLE_USER]}
										navigateLink={'/login'}
									>
										<VariaVideoPage/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/shape'}
								element={
									<ProtectedRoute>
										<Shape/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/shape/video/:id'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_ADMINISTRATOR, ROLE_USER]}
										navigateLink={'/login'}
									>
										<ShapeVideoPage/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/podcasts'}
								element={
									<ProtectedRoute>
										<Podcasts/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/podcasts/:id'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_ADMINISTRATOR, ROLE_USER]}
										navigateLink={'/login'}
									>
										<Podcast/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/podcasts/:id/:accessToken'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_GUEST]}
										navigateLink={'/podcasts'}
									>
										<Podcast/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/profile'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_ADMINISTRATOR, ROLE_USER]}
										navigateLink={'/login'}
									>
										<Profile/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/login'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_GUEST]}
										navigateLink={'/'}
									>
										<Login/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/login/:token'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_GUEST]}
										navigateLink={'/'}
									>
										<Login />
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/logout'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_ADMINISTRATOR, ROLE_USER]}
										navigateLink={'/'}
									>
										<Logout/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/registration'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_GUEST]}
										navigateLink={'/'}
									>
										<Registration/>
									</ProtectedRoute>
								}
							/>
							<Route
								path={'/reset-password'}
								element={
									<ProtectedRoute
										accessUserRoles={[ROLE_GUEST]}
										navigateLink={'/'}
									>
										<ResetPassword/>
									</ProtectedRoute>
								}
							/>
						</Routes>
					</Grid>
					<Footer/>
				</Grid>
				{isGlobalError && <ErrorModal/>}
				{isGlobalLoader && <GlobalLoader/>}
			</>
			:
			<GlobalLoader/>
	);
}

export default App;
