import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuizContext } from '../../../../context/quiz/quizContext';
import { Grid } from '@mui/material';
import QuizNextButton from '../../../shared/ui/buttons/QuizNextButton';
import SingleAnswerBox from '../../../shared/gamification/SingleAnswerBox';
import QuizCommentButton from '../../../shared/ui/buttons/QuizCommentButton';
import MultipleQuizModal from '../../../shared/modals/MultipleQuizModal';
import { validateString } from '../../../../utils/validateString';
import { LightboxModal } from "../../../shared/modals/LightboxModal";
import SinglePesAnswerBox from "../../../shared/gamification/SinglePesAnswerBox";
import FusionText from "../../../shared/pureHtml/FusionText";

const SingleQuestionQuiz = ({singleDescription}) => {

  const {
    quizState,
    checkShortQuiz,
    quizComplete,
    currentQuizIndex,
    setCurrentQuizIndex,
    drawArray,
    setDrawArray,
    startTimePes
  } = useContext(QuizContext);

  const [isValid, setIsValid] = useState(false);
  const [isGamification, setIsGamification] = useState(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [imageToShow, setImageToShow] = useState(null);
  const showImage = (link) => setImageToShow(link);
  const closeImage = () => setImageToShow(null);
  const ref = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!!imagesArray.length) {
      imagesArray.forEach((img) => {
        img.addEventListener('click', (e) => {
          e.preventDefault();
          showImage(img.src);
        });
      })
    }
  }, [imagesArray])

  useEffect(() => {
    window.localStorage.removeItem(`${quizState.id}-timeResult`)
  }, [])

  useEffect(() => {
    if (!isValid) {
      containerRef?.current?.scroll({top: 0, behavior: 'smooth'});
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [isValid])

  useEffect(() => {
    if (!!ref && !!ref.current) {
      setImagesArray([...ref.current.querySelectorAll('img')]);
    }
  }, [setImagesArray, ref, currentQuizIndex]);

  useEffect(() => {
    if (drawArray.length) {
      setIsValid(drawArray[currentQuizIndex].answers.filter(answer => answer.checked).length > 0)
    }
  }, [currentQuizIndex, drawArray])

  useEffect(() => {
    if (quizState.caseStep?.quiz?.questions?.length) {
      const {questions: quizQuestions} = quizState.caseStep.quiz
      let tempArray = []

      quizQuestions.forEach((question, index) => {
        if (index < currentQuizIndex) {
          tempArray.push({
            ...question,
            answers: [...question.answers.map((el, idx) => ({
              ...el,
              checked: el.checked !== undefined ? el.checked : question.answerBackId?.[0] !== '' || question.answerBackId.length > 0 ? Number(question.answerBackId[0]) === idx : false,
              isCorrect: question.answerBackId?.[0] === question.answerBackIsCorrect[0] ? Number(question.answerBackId[0]) === idx : undefined,
            }))],
          })
        } else {
          tempArray.push({
            ...question,
            answers: [...question.answers.map((el, idx) => ({
              ...el,
              checked: el.checked !== undefined ? el.checked : false,
            }))],
          })
        }
      })

      setDrawArray(JSON.parse(JSON.stringify(tempArray)))
      //setStartTimePes(Date.now())
    }

  }, [quizState])

  const changeAnswerHandler = (questionIndex, answerIndex) => {
    if (!drawArray[questionIndex].answers[answerIndex].checked) {
      setDrawArray(prev => JSON.parse(JSON.stringify(
        prev.map((question, index) =>
          index !== questionIndex
            ?
            question
            :
            {
              ...question,
              answers: question.answers.map((answer, i) => ({...answer, checked: i === answerIndex})),
            },
        ),
      )))
    }
  }

  const nextCaseHandler = () => {

    if (isValid && !isGamification) {
      setIsGamification(true)
      checkShortQuiz({answers: [...drawArray[currentQuizIndex].answers]}, currentQuizIndex);
    } else {
      if (drawArray.length - 1 > currentQuizIndex) {
        setIsValid(false)
        setIsGamification(false)
        setCurrentQuizIndex(prev => prev + 1)
      } else {
        quizComplete({startTimestamp: startTimePes, endTimestamp: Date.now(), questions: drawArray})
      }
    }
  }

  const openModalHandler = () => setIsCommentOpen(true)
  const closeModalHandler = () => setIsCommentOpen(false)
  console.log(Boolean(drawArray[currentQuizIndex]?.desc))
  return !singleDescription || !drawArray[currentQuizIndex]?.desc
    ?
    <>
      <Grid ref={ref} className={'singleQuestionQuiz'} flexDirection={{xs: 'column', md: 'row'}}>
        {
          drawArray.length &&
          <>
            <Grid className={'singleQuestionQuiz__containerGrid'} ref={containerRef}>
              <div>
                {quizState.caseStep?.model === 'withQuizExamQuestions' &&
                  <div className={'quizContent__questionTitle'}>
                    <h3>
                      {currentQuizIndex + 1} / {drawArray.length}
                    </h3>
                  </div>
                }
                {drawArray[currentQuizIndex]?.desc &&
                  <div className={'quizContent__questionDescription'}
                       dangerouslySetInnerHTML={{__html: validateString(drawArray[currentQuizIndex].desc)}}/>}
                {drawArray[currentQuizIndex]?.question && <div className={'quizContent__questionTitle'}
                                                               dangerouslySetInnerHTML={{__html: validateString(drawArray[currentQuizIndex].question)}}/>}
                <div>

                  {quizState?.caseStep?.model !== 'withQuizExamQuestions' ?
                    drawArray[currentQuizIndex].answers.map((answer, answerIndex) =>
                      <SingleAnswerBox
                        key={answerIndex}
                        answer={answer}
                        answerIndex={answerIndex}
                        questionIndex={currentQuizIndex}
                        changeAnswerHandler={changeAnswerHandler}
                      />
                    ) :
                    drawArray[currentQuizIndex].answers.map((answer, answerIndex) =>
                      <SinglePesAnswerBox
                        key={answerIndex}
                        answer={answer}
                        answerIndex={answerIndex}
                        questionIndex={currentQuizIndex}
                        changeAnswerHandler={changeAnswerHandler}
                      />
                    )
                  }
                </div>
              </div>
            </Grid>
            <div className={'singleQuestionQuiz__buttonsArea'}>

              {
                isGamification && drawArray[currentQuizIndex].correctMsg?.length > 0 && drawArray[currentQuizIndex].incorrectMsg?.length > 0
                  ?
                  <QuizCommentButton onClickHandler={openModalHandler}/>
                  :
                  null
              }
              {
                isValid && <QuizNextButton onClickHandler={nextCaseHandler}/>
              }
            </div>
          </>}

      </Grid>
      {
        isCommentOpen && <MultipleQuizModal
          content={drawArray[currentQuizIndex].answers.find(answer => answer.isCorrect && answer.checked) ? drawArray[currentQuizIndex].correctMsg : drawArray[currentQuizIndex].incorrectMsg}
          closeModalHandler={closeModalHandler}
        />
      }
      {
        imageToShow &&
        <LightboxModal closeImage={closeImage} link={imageToShow}/>
      }
    </>
    :
    <>
      {/*tutaj*/}
      <Grid container flexDirection={{xs: 'column', md: 'row'}}>
        <Grid ref={ref} className={'singleQuestionQuiz'} item xs={12} md={6} display={'flex'}
              flexDirection={'column'}>
          <Grid className={'singleQuestionQuiz__containerGrid'} display={'flex'} flexDirection={'column'}
                ref={containerRef}>
            {drawArray.length && drawArray[currentQuizIndex]?.desc &&
              <FusionText insideContent={validateString(drawArray[currentQuizIndex].desc)}/>
            }
          </Grid>
        </Grid>
        <Grid className={'singleQuestionQuiz'} item xs={12} md={6} display={'flex'} flexDirection={'column'}>
          {
            drawArray.length &&
            <>
              <Grid className={'singleQuestionQuiz__containerGrid'} display={'flex'}
                    flexDirection={'column'} ref={containerRef}>
                <div>
                  <div className={'quizContent__questionTitle'}
                       dangerouslySetInnerHTML={{__html: validateString(drawArray[currentQuizIndex].question)}}/>
                  <div>
                    {
                      drawArray[currentQuizIndex].answers.map((answer, answerIndex) => (
                        <SingleAnswerBox
                          key={answerIndex}
                          answer={answer}
                          answerIndex={answerIndex}
                          questionIndex={currentQuizIndex}
                          changeAnswerHandler={changeAnswerHandler}
                        />
                      ))
                    }
                  </div>
                </div>
              </Grid>
              <div className={'singleQuestionQuiz__buttonsArea'}>
                {
                  isGamification && drawArray[currentQuizIndex].correctMsg?.length > 0 && drawArray[currentQuizIndex].incorrectMsg?.length > 0
                    ?
                    <QuizCommentButton onClickHandler={openModalHandler}/>
                    :
                    null
                }
                {
                  isValid && <QuizNextButton onClickHandler={nextCaseHandler}/>
                }
              </div>

            </>
          }

        </Grid>
      </Grid>
      {
        isCommentOpen && <MultipleQuizModal
          content={drawArray[currentQuizIndex].answers.find(answer => answer.isCorrect && answer.checked) ? drawArray[currentQuizIndex].correctMsg : drawArray[currentQuizIndex].incorrectMsg}
          closeModalHandler={closeModalHandler}
        />
      }
      {
        imageToShow &&
        <LightboxModal closeImage={closeImage} link={imageToShow}/>
      }
    </>
}

export default SingleQuestionQuiz;