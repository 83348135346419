import React, { useState } from 'react';

const QuestionnaireBox = ({title, averageScore, userScore}) => {
    const [elementWidth, setElementWidth] = useState('0');
    const [elementColor, setElementColor] = useState('');
    const [percentage, setPercentage] = useState(0);

    const t = setTimeout(() => {
        setElementColor(() => {
            if (title === 'Średni wynik') {
                setElementColor('#C7C7C7')
                setElementWidth(`${averageScore}%`)
                setPercentage(averageScore)
            } else if (title === 'WYNIK') {
                setElementColor(userScore <= 40 ? '#FF007A' : '#2FD77C')
                setElementWidth(`${userScore}%`)
                setPercentage(userScore)
            } else {
                setElementColor(userScore < averageScore ? '#FF007A' : '#2FD77C')
                setElementWidth(`${userScore}%`)
                setPercentage(userScore)
            }
        })
        clearTimeout(t)
    }, 1000)

    return (
        <div className={'questionnaireBox'}>
			<span
                className={'questionnaireBox__gamification'}
                style={{
                    width: elementWidth,
                    backgroundColor: elementColor,
                    borderLeft: elementColor !== '' ? `5px solid ${elementColor}` : 'none',
                }}
            />
            <p className={'questionnaireBox__title'}>{title}</p>
            <span
                className={'questionnaireBox__percentage'}>{percentage + '%'}</span>
        </div>
    )
}

export default QuestionnaireBox;