import React, {useContext, useState} from 'react'
import {QuizContext} from '../../../../context/quiz/quizContext';
import {Grid} from '@mui/material';
import LiteratureButton from '../../../shared/ui/buttons/LiteratureButton';
import TheoryQuizModal from '../../../shared/modals/TheoryQuizModal';
import QuizNextButton from '../../../shared/ui/buttons/QuizNextButton';
import HtmlSelector from '../../../shared/pureHtml/HtmlSelector';

const TheoryQuiz = ({withText}) => {
	const {quizState, stepComplete} = useContext(QuizContext);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModalHandler = () => setIsModalOpen(true);
	const closeModalHandler = () => setIsModalOpen(false);

	return (
		<Grid className={'theoryQuiz'} maxWidth={'99vw'}>
			{
				!withText ?
					<h3>
						TEORIA
					</h3>
					:
					null
			}
			{
				quizState.caseStep?.content?.map((el, i) => <HtmlSelector element={el} key={i}/>)
			}
			{
				quizState.details?.literature && <LiteratureButton openModalHandler={openModalHandler}/>
			}
			{
				isModalOpen &&
				<TheoryQuizModal content={quizState.details.literature} closeModalHandler={closeModalHandler}/>
			}
			<QuizNextButton onClickHandler={stepComplete}/>
		</Grid>
	)
}

export default TheoryQuiz;