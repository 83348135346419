import React, {useContext} from 'react';
import {Grid, Stack} from '@mui/material';
import StatusVideoButton from '../buttons/StatusVideoButton';
import {AuthContext} from '../../../../context/auth/authContext';
import {useNavigate} from 'react-router-dom';

const QuizBox = ({quiz}) => {
	const {isLoggedIn} = useContext(AuthContext)
	const navigate = useNavigate();
	return (
		<Grid
			item
			p={1}
			lg={4} sm={6} xs={12}
		>
			<Grid
				container
				className="quizBox"
				onClick={() => {
					navigate(`/quizzes/quiz/${quiz.id}`);
				}}
			>
			<Grid
				container
				height={'100%'}
				flexDirection={'column'}
			>
				<Grid item className="quizBox__photoArea" flexGrow={1}>
					<img
						className="quizBox__photoArea-photo"
						src={quiz.details.thumbnail}
						alt="patient"
					/>
					<Grid
						className="quizBox__photoArea-banner"
						container
						alignItems={'flex-end'}
					>
						<Stack component={'div'} justifyContent={'center'}>
							<p className="quizBox__photoArea-patientName">{quiz.details.patientName}</p>
							<p className="quizBox__photoArea-disease">{quiz.title}</p>
						</Stack>
					</Grid>
				</Grid>
				<Grid item>
					<Grid
						container
						className="quizBox__footer"
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Grid width='80%' item>
							<p className="quizBox__quizName">{quiz.details.quizModel}</p>
							<Stack direction={'row'} mt={'4px'}>
								<p className="quizBox__authors">Autorzy: &nbsp;</p>
								<Stack direction={'column'}>
									{
										quiz.details.authors && quiz.details.authors.length
											?
											quiz.details.authors.map((author, index) =>
												<p className="quizBox__authors" key={index}>{author}</p>) : 'no data'
									}
								</Stack>
							</Stack>
						</Grid>
						<Grid item>
							{isLoggedIn && <StatusVideoButton status={quiz.details.status.text}/>}
						</Grid>
					</Grid>
				</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default QuizBox