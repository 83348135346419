import React, { useEffect, useRef, useState } from 'react';
import { validateString } from '../../../utils/validateString';
import { LightboxModal } from "../modals/LightboxModal";
import { transformHTMLCodeByTags } from '../../../utils/transformHTMLCodeByTags';

const FusionText = ({insideContent, tabs}) => {

  const [imagesArray, setImagesArray] = useState([]);
  const [imageToShow, setImageToShow] = useState(null);

  const showImage = (link) => setImageToShow(link);
  const closeImage = () => setImageToShow(null);
  const ref = useRef(null);

  useEffect(() => {
    if (!!imagesArray.length) {
      imagesArray.forEach((img) => {
        img.addEventListener('click', (e) => {
          e.preventDefault();
          showImage(img.src);
        });
      })
    }
  }, [imagesArray])

  useEffect(() => {
    if (!!ref) {
      setImagesArray([...ref.current.querySelectorAll('.tabs-fusionText img, .fusionText img')]);
    }
  }, [setImagesArray, ref]);

  return (
    <>
      {insideContent && <div ref={ref} className={tabs ? 'tabs-fusionText' : 'fusionText'}
                             dangerouslySetInnerHTML={{__html: transformHTMLCodeByTags(`<div>${validateString(insideContent)}</div>`)}}/>}
      {
        imageToShow &&
        <LightboxModal closeImage={closeImage} link={imageToShow}/>
      }
    </>
  )
}

export default FusionText