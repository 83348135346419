import React from 'react'
import { Container, Grid, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';

import bannerLogo from '../../../assets/img/ui/pixel.svg'
import variasIco from '../../../assets/img/ui/menuIcons/varias.svg'
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import rightIcon from "../../../assets/img/ui/menuIcons/rightIcon.svg";

const VariaBanner = () => {
    const {width} = useWindowDimensions();

    return (
        <Grid
            className="variaBanner"
            component={'div'}
            container
            maxWidth={'100vw'}
        >
            <Container maxWidth="xl">
                <Grid container height={'100%'}>
                    <Grid item xs={5} md={6}>
                        <Grid container height={'100%'} alignItems={'center'}
                              justifyContent={{xs: 'center', lg: 'flex-end'}}>
                            <img src={bannerLogo} alt="logo" width={'40%'}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} md={6}>
                        <Grid
                            container
                            height={'100%'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            flexWrap={'nowrap'}
                        >
                            <Grid item>
                                <Grid className="variaBanner__textArea" container flexDirection={'column'}
                                      alignItems={'flex-start'}>
                                    <Stack direction={'row'} component={'div'} alignItems={'center'}>
                                        <img src={variasIco} alt="varias ico"/>
                                        <p>VARIA</p>
                                    </Stack>
                                    <p>Odwiedź sekcję<br/>materiałów dodatkowych</p>
                                    {width > 900 && <>
                                        <p>W tej sekcji znajdziesz materiały<br/>wideo, zdjęcia i artykuły.</p>
                                    </>}
                                </Grid>
                            </Grid>
                            {width > 1200 && <NavLink
                                className="variaBanner__link"
                                to="/varia"
                            />}
                            {width < 1200 &&
                                <NavLink className={'variaBanner__mobileLink'}
                                         to="/varia"
                                >
                                    <div>
                                        <img src={rightIcon} alt={'link'}/>
                                    </div>
                                </NavLink>}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}

export default VariaBanner