import {useContext, useReducer} from 'react';
import {AuthContext} from '../auth/authContext';
import {podcastsReducer} from './podcastsReducer';
import {ADD_PODCAST_ACCESS_TOKEN, CLEAR_PODCASTS_STATE, GET_CURRENT_PODCAST_INFO} from '../types';
import {PodcastsContext} from './podcastsContext';
import axios from '../../axios/axios';
import {PoiContext} from '../poi/poiContext';

export const PodcastsState = ({children}) => {
	const {setGlobalLoader, unsetGlobalLoader, setModalError} = useContext(PoiContext)
	const {token} = useContext(AuthContext)
	
	const initialState = {
		accessToken: null,
		isLoaded: false,
	}
	
	const [state, dispatch] = useReducer(podcastsReducer, initialState);
	
	const getPodcastInfo = async (podcastId, accessToken, isLoader = true) => {
		if (isLoader) {
			setGlobalLoader();
		}

		if (accessToken) {
			dispatch({type: ADD_PODCAST_ACCESS_TOKEN, payload: accessToken})
		}
		
		try {
			axios.get(
				`wp-json/softwebo/v1/podcast/${podcastId}`,
				{
					headers: {Authorization: accessToken ? `AccessToken ${accessToken}` : `Bearer ${token}`},
				},
			).then(res => {
				dispatch({type: GET_CURRENT_PODCAST_INFO, payload: res.data})
				if (isLoader) {
					unsetGlobalLoader();
				}

			})
		} catch (e) {
			if (isLoader) {
				unsetGlobalLoader()
			}

			setModalError(e.response);
		}
	}
	
	const podcastProgress = async (url) => {
		try {
			await axios.put(
				`${url}`,
				null,
				{
					headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
				},
			)
		} catch (e) {
			setModalError(e.response);
		}
	}
	
	const podcastComplete = async (url) => {
		try {
			await axios.post(
				`${url}`,
				null,
				{
					headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
				},
			)
		} catch (e) {
			setModalError(e.response);
		}
	}
	
	const clearPodcastsState = () => dispatch({type: CLEAR_PODCASTS_STATE, payload: initialState})
	
	return (
		<PodcastsContext.Provider value={{
			podcastsState: state,
			getPodcastInfo,
			clearPodcastsState,
			podcastProgress,
			podcastComplete,
		}}>
			{children}
		</PodcastsContext.Provider>
	)
}