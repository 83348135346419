import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { VariaContext } from '../../../context/varia/variaContext';
import { Grid } from '@mui/material';
import videoMaterialIco from '../../../assets/img/ui/videoMaterialIco.svg';
import textMaterialIco from '../../../assets/img/ui/textMaterialIco.svg';
import imageMaterialIco from '../../../assets/img/ui/imageMaterialIco.svg';
import VariaVideoBox from '../../shared/ui/boxes/VariaVideoBox';
import VariaImageBox from '../../shared/ui/boxes/VariaImageBox';
import VariaTextBox from '../../shared/ui/boxes/VariaTextBox';
import { MaterialUIPagination } from '../../shared/ui/MaterialUIPagination';
import VariaImageModal from './VariaImageModal';
import NoContent from '../../shared/ui/NoContent';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const Materials = ({materialOption, isNotStartedSelected, isFinishedSelected, specialization, finderValue}) => {

    const {variaState} = useContext(VariaContext);
    const [drawArray, setDrawArray] = useState([]);
    const [pageDrawArray, setPageDrawArray] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [currentImageId, setCurrentImageId] = useState(null);
    // const cardsPerPage = materialOption === 'video' ? 3 : materialOption === 'image' ? 8 : 9;
    const {width} = useWindowDimensions();
    const cardsPerPage = width > 1496 && materialOption === 'video' ? 3 : width < 1200 && materialOption === 'image' ? 8 : 6
    const ref = useRef(null)
    const bodyOverflow = useMemo(() => document.querySelector('body'), []);
    const html = useMemo(() => document.querySelector('html'), []);

    // console.log(variaState)

    useEffect(() => {
        if (bodyOverflow && html) {
            if (materialOption === 'image' && currentImageId) {
                bodyOverflow.style.overflow = 'hidden';
                html.style.overflow = 'hidden';
                bodyOverflow.style.position = 'relative';
                html.style.position = 'relative';
            } else {
                bodyOverflow.style.overflow = 'visible';
                html.style.overflow = 'visible';
                bodyOverflow.style.position = 'static';
                html.style.position = 'static';
            }
        }
    }, [materialOption, currentImageId, bodyOverflow])

    useEffect(() => {
        if ((materialOption === 'video' && variaState.video) ||
            (materialOption === 'image' && variaState.image) ||
            (materialOption === 'text' && variaState.publication)) {
            setCurrentPage(1);
            let tempDrawArray = materialOption === 'video' ? [...variaState.video] : materialOption === 'image' ? [...variaState.image] : [...variaState.publication]

            if (materialOption === 'video' && !isNotStartedSelected) {
                tempDrawArray = [...tempDrawArray.filter(element => element.status !== 'notViewed')]
            }

            if (materialOption === 'video' && !isFinishedSelected) {
                tempDrawArray = [...tempDrawArray.filter(element => element.status !== 'viewed')]
            }

            if (specialization !== 'Obszar terapeutyczny') {
                tempDrawArray = [...tempDrawArray.filter(element => !!element.medicalAreas.find(el => el === specialization))]
            }

            if (finderValue.trim().length) {
                tempDrawArray = [...tempDrawArray.filter(element => element.title.toLowerCase().includes(finderValue.toLowerCase())
                    || (element.authors.length && element.authors.filter(author => author.name.toLowerCase().includes(finderValue.toLowerCase())).length)
                    || (element.description?.length && element.description.filter(content => content.content.toLowerCase().includes(finderValue.toLowerCase())).length)
                )]
            }

            setDrawArray([...tempDrawArray]);

            setPageCount(Math.ceil(tempDrawArray.length / cardsPerPage))

        }
    }, [materialOption, variaState, isNotStartedSelected, isFinishedSelected, specialization, finderValue, cardsPerPage])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])


    const modalToggle = (id) => setCurrentImageId(id)

    return (
        <Grid container flexDirection={'column'} mb={5} maxWidth={'100vw'}>
            <Grid container flexDirection={'row'} alignItems={'center'} flexWrap={'nowrap'} ref={ref}>
                <img
                    className={'varia__title-image'}
                    src={materialOption === 'video' ? videoMaterialIco : materialOption === 'image' ? imageMaterialIco : textMaterialIco}
                    alt="materials ico"
                />
                <p className={'varia__title-text'}>
                    {
                        materialOption === 'video' ? 'MATERIAŁY VIDEO' : materialOption === 'image' ? 'MATERIAŁY ILUSTRACYJNE' : 'PUBLIKACJE'
                    }
                </p>
            </Grid>
            <Grid item xs={12} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                {
                    !!drawArray.length ?
                        <>
                            <Grid
                                container
                                maxWidth={{xs: '100%', sm: '100%', md: '1000px', lg: '1900px'}}
                                // gap={materialOption === 'video' ? '10px' : materialOption === 'image' ? '13px' : '10px'}
                                gap={{xs: '18px', lg: '12px'}}
                                alignItems={'center'}
                                justifyContent={{
                                    xs: 'center',
                                    xl: 'flex-start',
                                }}
                                mt={3}

                            >
                                {
                                    !!pageDrawArray.length && pageDrawArray.map(element => materialOption === 'video'
                                        ? <VariaVideoBox element={element} key={element.id} type={'varia'}/>
                                        : materialOption === 'image'
                                            ? <VariaImageBox element={element} key={element.id} modalToggle={modalToggle}/>
                                            : <VariaTextBox element={element} key={element.id}/>,
                                    )
                                }
                            </Grid>
                            <Grid
                                container
                                justifyContent={'flex-end'}
                                pt={2}
                                pb={2}
                                mt={3}
                            >
                                <MaterialUIPagination
                                    count={pageCount}
                                    page={currentPage}
                                    onChange={(e, value) => {
                                        setCurrentPage(value);
                                        window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'})
                                    }}
                                    size={'large'}
                                />
                            </Grid>
                        </>
                        :
                        <NoContent/>
                }
            </Grid>
            {
                materialOption === 'image' && currentImageId &&
                <VariaImageModal modalToggle={modalToggle} imageId={currentImageId}/>
            }
        </Grid>
    )
}

export default Materials