import React, {useContext, useEffect, useState} from 'react';
import {PodcastsContext} from '../../../context/podcasts/podcastsContext';
import {Grid} from '@mui/material';
import {timeConverter} from "../../../utils/timeConverter";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {validateString} from '../../../utils/validateString';

const PodcastDescriptionBox = ({currentIndex}) => {
    const {podcastsState, podcastsState: {episodes}} = useContext(PodcastsContext);
    const [currentDescription, setCurrentDescription] = useState(null)
    const {width} = useWindowDimensions()

    useEffect(() => {
        if (podcastsState && episodes) {
            setCurrentDescription({
                title: episodes[currentIndex].episodeTitle,
                authors: episodes[currentIndex].episodeAuthors,
                medicalAreas: podcastsState.medicalAreas,
                durationTime: episodes[currentIndex].episodeDuration,
                description: episodes[currentIndex].episodeDesc,
                document: episodes[currentIndex].episodeDocument ? episodes[currentIndex].episodeDocument : null,
            })
        }
    }, [currentIndex, podcastsState, episodes])

    return (
        <Grid item xs={12} lg={4} className={'podcastParts__descriptionBox'}>

            {width > 1200 && <h3>SZCZEGÓŁY ODCINKA:</h3>
            } {
          currentDescription &&
            <Grid container flexDirection={'column'} height={{xs: '100%',
                // lg: 'calc(100% - 32px)'
            }}>
                <h5 className={'podcastParts__descriptionBox-title'}>{currentDescription.title}</h5>
                <Grid container flexDirection={'column'}
                      className={'podcastParts__descriptionBox-authors'}>
                    <Grid container flexDirection={'row'} flexWrap={'nowrap'}>
                        <p style={{minWidth: '90px'}}>prowadzący:</p>
                        <Grid container flexDirection={'column'} flexGrow={1}>
                            {currentDescription.authors.map((author, index) => <p
                                key={index}>{author.episodeAuthorName}</p>)}
                        </Grid>
                    </Grid>
                    <Grid container flexDirection={'row'} flexWrap={'nowrap'} pt={2}>
                        <p style={{minWidth: '90px'}}>obszar:</p>
                        <Grid container direction={'row'} flexGrow={1}>
                            {currentDescription.medicalAreas.map((medArea, index) => <span
                                key={index}>{medArea}{currentDescription.medicalAreas.length - 1 !== index && ','}&nbsp;</span>)}
                        </Grid>
                    </Grid>
                </Grid>
                <p className={'podcastParts__descriptionBox-durationTime'}>CZAS
                    TRWANIA:&nbsp;{timeConverter(currentDescription.durationTime)}</p>
                <Grid item flexGrow={1}>
                    <div className={'podcastParts__descriptionBox-description'}
                         dangerouslySetInnerHTML={{__html: validateString(currentDescription.description)}}/>
                </Grid>
                {
                    currentDescription.document &&
                    <Grid className="podcastParts__descriptionBox-link">
                        <a href={currentDescription.document} target="_blank" rel="noopener">MATERIAŁY DODATKOWE</a>
                    </Grid>
                }
            </Grid>
        }
        </Grid>
    )
}

export default PodcastDescriptionBox;