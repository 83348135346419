import React from 'react'

const NoContent = () => null
// 	(
// 	<div className={'noContent'}>
// 		<p>BRAK MATERIAŁÓW</p>
// 		<p>Zapraszamy w późniejszym terminie.</p>
// 	</div>
// )

export default NoContent;