import React from 'react'

const PatientAvatar = ({link, cn, isVideo}) => {
	let draw
	const avatarExtension = link.split('.')[link.split('.').length - 1]
	
	if (avatarExtension === 'jpg' || avatarExtension === 'png') {
		draw = <img
			className={isVideo ? `${cn}-photo` : cn}
			src={link}
			alt="patient avatar"
		/>
	} else if (avatarExtension === 'mp4') {
		draw = (
			<video
				playsInline={true}
				className={`${cn}`}
				src={link}
				autoPlay={true}
				controls={false}
				loop={true}
				muted={true}
			/>
		)
	} else {
		draw = (
			<p>brak informacji</p>
		)
	}
	
	return draw
}

export default PatientAvatar