import {useContext, useReducer} from 'react'
import { PoiContext } from "../poi/poiContext";
import {shapeReducer} from './shapeReducer';
import {ShapeContext} from "./shapeContext";
import { CLEAR_SHAPE_STATE, CLEAR_SHAPE_VIDEO_INFO, GET_SHAPE_INFO, GET_SHAPE_VIDEO_INFO } from "../types";
import axios from '../../axios/axios';
import { AuthContext } from "../auth/authContext";

export const ShapeState = ({children}) => {
    const {setGlobalLoader, unsetGlobalLoader, setModalError} = useContext(PoiContext);
    const {token, isLoggedIn} = useContext(AuthContext);
    const initialState = {
        video: null,
        shapeVideoInfo: null,
    }

    const [state, dispatch] = useReducer(shapeReducer, initialState)

    const getShapeInfo = async() => {
        setGlobalLoader();
        try {
            axios.get(
                `wp-json/softwebo/v1/shape`,
                isLoggedIn ? {
                    headers: {Authorization: `Bearer ${token}`},
                } : undefined,
            ).then(res => {
                dispatch({type: GET_SHAPE_INFO, payload: res.data})

                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const getShapeVideoInfo = (id) => {
        setGlobalLoader();
        if (state.video) {
            const payload = JSON.parse(JSON.stringify(state.video.find(el => +el.id === +id)));

            dispatch({type: GET_SHAPE_VIDEO_INFO, payload})
        }

        unsetGlobalLoader();
    }

    const clearShapeState = () => dispatch({type: CLEAR_SHAPE_STATE, payload: initialState})

    const clearShapeVideoInfo = () => dispatch({type: CLEAR_SHAPE_VIDEO_INFO})

    const setIsViewed = async (id) => {
        try {
            await axios.post(
                `wp-json/softwebo/v1/shape/${id}`, null,
                {
                    headers: {Authorization: state.accessToken ? `AccessToken ${state.accessToken}` : `Bearer ${token}`},
                }
            )
        } catch (e) {
            setModalError(e.response);
        }
    }

    return (
        <ShapeContext.Provider value={{
            shapeState: state,
            getShapeInfo,
            setIsViewed,
            getShapeVideoInfo,
            clearShapeState,
            clearShapeVideoInfo

        }}>{children}</ShapeContext.Provider>
    )
}