import React, {useCallback, useContext, useEffect, useReducer} from 'react';
import {PoiContext} from '../poi/poiContext';
import {AuthContext} from './authContext';
import {authReducer} from './authReducer';
import axios from '../../axios/axios';
import {
    ADD_LOGIN_TOKEN,
    GET_USER_SPECIALIZATIONS,
    IS_USER_LOAD,
    LOGIN_USER,
    LOGOUT_USER
} from '../types';
import {useNavigate} from 'react-router-dom';
import {dateConverter} from '../../utils/dateConverter';
import {ROLE_GUEST} from '../../consts/projectUsers';

let logOutTimer;

export const AuthState = ({children}) => {

    const {setModalError, setGlobalLoader, unsetGlobalLoader, errorMessage, errorCode} = useContext(PoiContext);

    const initialState = {
        isUserLoad: false,
        isLoggedIn: false,
        token: null,
        loginToken: null,
        role: ROLE_GUEST,
        email: null,
        telephone: null,
        specialization: null,
        tokenExpirationTime: null,
        userSpecializations: null,
        userId: null,
        navigationLink: null,
    }

    const [state, dispatch] = useReducer(authReducer, initialState);

    const navigate = useNavigate();


    const login = useCallback((token, role, email, telephone, specialization, tokenExpirationTimeServer, userId) => {
        const tokenExpirationTime = tokenExpirationTimeServer || new Date(new Date().getTime() + 1000 * 60 * 60 * 4); //4h
        dispatch({
            type: LOGIN_USER,
            payload: {
                token,
                role,
                email,
                telephone,
                specialization,
                tokenExpirationTime,
                userId,
            },
        })
        localStorage.setItem('userData', JSON.stringify({
            token,
            role,
            email,
            telephone,
            specialization,
            tokenExpirationTime: tokenExpirationTime.toISOString(),
            userId,
        }))
    }, [])

    const logout = useCallback(() => {
        localStorage.removeItem('userData');
        dispatch({type: LOGOUT_USER, payload: initialState});
        navigate('/login');
        // eslint-disable-next-line
    }, [navigate])

    useEffect(() => {
        if ((errorMessage === 'Invalid JWT Token' || errorMessage === 'Incorrect JWT Format.') && (errorCode === 401 || errorCode === '401')) {
            logout();
        }
    }, [errorMessage, errorCode, logout])

    useEffect(() => {
        if (state.token && state.tokenExpirationTime) {
            const remainingTime = state.tokenExpirationTime.getTime() - new Date().getTime()
            logOutTimer = setTimeout(logout, remainingTime)
        } else {
            clearTimeout(logOutTimer);
        }
    }, [state, logout])

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'))
        if (storedData
            && storedData.token
            && new Date(storedData.tokenExpirationTime) > new Date()) {
            login(storedData.token, storedData.role, storedData.email, storedData.telephone, storedData.specialization, new Date(storedData.tokenExpirationTime), storedData.userId)
        }
        dispatch({type: IS_USER_LOAD})
    }, [login])

    const authByToken = async (loginToken) => {
        setGlobalLoader();
        try {
            await axios.post(
                'wp-json/softwebo/v1/login-token-auth',
                {loginToken: loginToken},
            ).then((res) => {
                unsetGlobalLoader();
                localStorage.setItem('userData', JSON.stringify({
                    loginToken,
                    role: res.data.role
                }))
                dispatch({type: ADD_LOGIN_TOKEN, payload: {loginToken: loginToken, role: res.data.role}})
                navigate('/');
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const authUser = async (data) => {
        setGlobalLoader();
        try {
            await axios.post(
                'wp-json/softwebo/v1/login',
                data,
            ).then((res) => {
                unsetGlobalLoader();
                login(res.data.token, res.data.role, res.data.email, res.data.telephone, res.data.specialization, null, res.data.userId)
               // navigate(-1)
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const userRegistration = async (userInfo) => {
        setGlobalLoader()
        try {
            await axios.post(
                `wp-json/softwebo/v1/users`,
                {
                    pwzName: userInfo.name,
                    pwzSurname: userInfo.surname,
                    pwzBirthdate: dateConverter(userInfo.birthDate),
                    pwzNumber: userInfo.pwz,
                    email: userInfo.email,
                    phoneNumber: userInfo.tel,
                    specialization: userInfo.specialization,
                    password: userInfo.password,
                    passwordConfirm: userInfo.confirmPassword,
                    regulations: userInfo.privatePolicy,
                    marketingConsent: userInfo.contactPolicy
                }
            ).then(res => {
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Twoje konto zostało utworzone. Na podany w procesie rejestracji adres email został wysłany link aktywacyjny konta.',
                    },
                }
                setModalError(defRes);
                unsetGlobalLoader();
                navigate('/login');
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const getUserSpecializations = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `wp-json/softwebo/v1/users/form-set`,
            ).then(res => {
                dispatch({type: GET_USER_SPECIALIZATIONS, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const changePassword = async (password) => {
        setGlobalLoader();
        const data = {
            password,
        }
        try {
            await axios.post(
                `change_password`,
                data,
                {
                    headers: {Authorization: `Bearer ${state.token}`},
                },
            ).then(res => {
                logout();
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Twoje hasło zostało zmienione',
                    },
                }
                setModalError(defRes);
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const resetPassword = async (userData) => {
        const data = new FormData();
        data.append('username', userData);
        setGlobalLoader()
        try {
            await axios.post(
                'wp-json/softwebo/v1/login/resetPassword',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Na Twoją skrzynkę email zostały dostarczone dalsze instrukcje.',
                    },
                }
                setModalError(defRes);
                navigate('/')
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const changeProfileData = async ({email, password, passwordConfirm, phoneNumber, specialization}) => {
        const {userId} = state
        const data = {
            email, password, passwordConfirm, phoneNumber, specialization,
        }
        setGlobalLoader()
        try {
            await axios.put(
                `/wp-json/softwebo/v1/users/${userId}`,
                data,
                {
                    headers: {Authorization: `Bearer ${state.token}`},
                },
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Twoje dane zostały zmienione',
                    },
                }
                setModalError(defRes)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }
    return (
        <AuthContext.Provider value={{
            isLoggedIn: state.isLoggedIn,
            isUserLoad: state.isUserLoad,
            token: state.token,
            loginToken: state.loginToken,
            role: state.role,
            authState: state,
            logout,
            authByToken,
            authUser,
            changePassword,
            resetPassword,
            getUserSpecializations,
            userRegistration,
            changeProfileData,
        }}>
            {children}
        </AuthContext.Provider>
    )
}