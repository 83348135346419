import React, {useContext} from 'react'
import {AuthContext} from '../../../../context/auth/authContext';
import {Grid, Stack} from '@mui/material';
import StatusVideoButton from '../buttons/StatusVideoButton';
import {useNavigate} from "react-router-dom";
import PatientAvatar from '../PatientAvatar';

const EventBox = ({event, isOneLine}) => {
    const {isLoggedIn} = useContext(AuthContext)
    const navigate = useNavigate();

    return (
        <Grid
            item
            p={1}
            xl={3} lg={isOneLine? 3 : 4} sm={6} xs={12}
        >
            <Grid container
          
                  className="eventBox"
                  onClick={() => {
                      navigate(`/events/${event.id}`)
                  }}>
                <Grid container height={'100%'} flexDirection={'column'} flexWrap={'nowrap'}>
                    <Grid sx={{position: 'relative', width: '100%', maxHeight: '195px'}}>
                        <PatientAvatar link={event.details.avatar} cn={'eventBox__photo'} isVideo/>
                        <p className='eventBox__patientName'>
                            {event.details.patientName}
                        </p>
                    </Grid>
                    <Grid item flexGrow={1}>
                        <Grid container flexDirection={'column'} pl={2} pr={2} height={'100%'}>
                            <Grid height={'106px'}>
                                <p className="eventBox__disease">{event.title}</p>
                            </Grid>
                            <Grid flexGrow={1} minHeight={'150px'}>
                                <div className="eventBox__description">
                                    <p >{event.details.description}</p>
                                </div>
                            </Grid>
                            <Grid item m={'5px 0'}>
                                <Stack
                                    height={'100%'}
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'flex-end'}
                                >
                                    <Stack direction={'column'} flexGrow={'1'} justifyContent={'center'}
                                           height={'100%'}>
                                        <Stack direction={'row'}>
                                            <p className="eventBox__authors" style={{minWidth: '90px'}}>Autor:</p>
                                            <p className="eventBox__authors">{event.details.teacherName}</p>
                                        </Stack>
                                        <Stack direction={'row'}>
                                            <p className="eventBox__authors"
                                               style={{minWidth: '90px'}}>Komentarz:</p>
                                            <p className="eventBox__authors">{event.details.commenterName}</p>
                                        </Stack>
                                        <Stack direction={'row'}>
                                            <p className="eventBox__authors" style={{minWidth: '90px'}}>Szacunkowy
                                                czas:</p>
                                            <p className="eventBox__authors">{event.details.duration} min</p>
                                        </Stack>
                                    </Stack>
                                    {
                                        isLoggedIn &&
                                        <Stack
                                            direction={'row'}
                                            alignItems={'center'}
                                        >
                                            <p className="eventBox__authors"
                                               style={{marginRight: '5px'}}>{event.details.steps}</p>
                                            <StatusVideoButton status={event.details.status}/>
                                        </Stack>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EventBox;