import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import App from './components/App';
import {BrowserRouter} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material';
import {PoiState} from './context/poi/PoiState';
import {AuthState} from './context/auth/AuthState';
import {MainPageState} from './context/mainPage/MainPageState';
import {EventState} from './context/event/EventState';
import {VariaState} from './context/varia/VariaState';
import {PodcastsState} from './context/podcasts/PodcastsState';
import {QuizState} from './context/quiz/QuizState';
import {ShapeState} from './context/shape/ShapeState';
import * as smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1489,
        },
    },
    components: {
    }
})

const app = (
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <PoiState>
                <AuthState>
                    <MainPageState>
                        <EventState>
                            <VariaState>
                                <ShapeState>
                                <PodcastsState>
                                    <QuizState>
                                        <App/>
                                    </QuizState>
                                </PodcastsState>
                                </ShapeState>
                            </VariaState>
                        </EventState>
                    </MainPageState>
                </AuthState>
            </PoiState>
        </BrowserRouter>
    </ThemeProvider>
)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(app);

