import React, { useContext, useEffect } from 'react'
import { QuizContext } from '../../../../context/quiz/quizContext';
import { Grid } from '@mui/material';

const StartQuiz = () => {
    const { stepComplete, setStartTimePes, quizState} = useContext(QuizContext);

    const handleStart = () => {
        setStartTimePes(new Date().getTime(), quizState.id)
        stepComplete()
    }

    useEffect(()=> {
        window.localStorage.removeItem(`${quizState.id}-timeResult`);
        window.localStorage.removeItem(`${quizState.id}-startTime`);
    },[])

    return (
        <Grid className={'startQuiz'} maxWidth={'99vw'}>
            <h2>
                ROZWIĄŻ QUIZ
            </h2>
            <p>Quiz to zestaw 5 losowo przydzielonych pytań.</p>
            <button
                onClick={handleStart}
                className={'quizNextButton startQuiz__button'}
            >
                Start
            </button>
        </Grid>
    )
}

export default StartQuiz;