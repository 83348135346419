import {useContext, useState} from 'react'
import {PoiContext} from "../context/poi/poiContext";
import {AuthContext} from "../context/auth/authContext";
import validateInfo from "../utils/validateInfo";

const useForm = () => {
    const [userInfo, setUserInfo] = useState({
        name: '',
        surname: '',
        birthDate: null,
        pwz: '',
        email: '',
        tel: '',
        specialization: '',
        password: '',
        confirmPassword: '',
        contactPolicy: false,
        privatePolicy: false,
    })
    const [errors, setErrors] = useState({})
    const {setModalError} = useContext(PoiContext);
    const {userRegistration} = useContext(AuthContext);

    const handleChange = (name, value) => {
        setUserInfo({
            ...userInfo,
            [name]: value,
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        setErrors(null)
        if (userInfo.password === userInfo.confirmPassword) {
            userRegistration(userInfo);
        } else {
            let defRes = {
                data: {
                    code: 200,
                    message: 'Błędne hasło. Spróbuj ponownie.',
                },
            }
            setModalError(defRes);
        }
        setErrors(validateInfo(userInfo))
    }

    return {handleChange, handleSubmit, userInfo, errors}
}
export default useForm
