import {styled} from '@mui/material/styles';
import {Pagination} from '@mui/material';


export const MaterialUIPagination = styled(Pagination)(({theme}) => ({
	"& .MuiButtonBase-root": {
		color: '#fff',
	},
	"& .MuiPaginationItem-ellipsis": {
		color: '#fff',
	},
	"& .Mui-selected": {
		backgroundColor: 'rgba(255, 255, 255, .1) !important',
		color: '#00C2DF',
		fontWeight: '700',
	},
	"& .MuiPaginationItem-icon": {
		color: '#00C2DF',
	},
}))