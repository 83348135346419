import React, {useState} from 'react'
import {LightboxModal} from "../modals/LightboxModal";

const ImageFrame = ({insideContent}) => {

    const [imageToShow, setImageToShow] = useState(null);

    const showImage = (link) => setImageToShow(link);
    const closeImage = () => setImageToShow(null);

    return (
        <>
            <img className={'imageFrame'} src={insideContent} alt="content" onClick={() => showImage(insideContent)}/>
            {
                imageToShow &&
                <LightboxModal closeImage={closeImage} link={imageToShow}/>
            }
        </>
    )
}

export default ImageFrame;