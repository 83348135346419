import React, {useContext, useEffect, useState} from 'react';
import {EventContext} from '../../../../context/event/eventContext';
import {Grid} from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';

const VideoSlide = ({showButtonHandler}) => {
	const {eventState: {caseStep}} = useContext(EventContext);
	const [videoToShow, setVideoToShow] = useState(null);
	const [isVideoEnd, setIsVideoEnd] = useState(false);

	useEffect(() => {
		showButtonHandler({type: null, show: false});
		// eslint-disable-next-line
	}, [])
	
	useEffect(() => {
		setVideoToShow(caseStep.video)
	}, [caseStep])
	
	useEffect(() => {
		if (isVideoEnd) {
			showButtonHandler({type: 'slide', show: true});
		}
		// eslint-disable-next-line
	}, [isVideoEnd])
	
	return (
		<Grid
			container
			item
			className={'videoSlide'}
			height={'100%'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{
				videoToShow &&
				<Vimeo
					className={'videoSlide__videoWrapper'}
					video={videoToShow}
					autoplay
					allowfullscreen
					transparent={true}
					onEnd={() => setIsVideoEnd(true)}
				/>
			}
		</Grid>
	)
}

export default VideoSlide;