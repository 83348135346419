import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import Quizzes from '../quizzes/Quizzes';
import Events from '../events/Events';
import profilePhoto from '../../../assets/img/ui/profilr_icon.svg'
import {MainPageContext} from '../../../context/mainPage/mainPageContext';
import {AuthContext} from '../../../context/auth/authContext';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {grey} from '@mui/material/colors';
import {styled} from '@mui/material/styles';

const CustomDisableInput = styled(OutlinedInput)(() => ({
    '.MuiInputBase-input.Mui-disabled': {
        cursor: 'not-allowed',
    },
}));

const Profile = () => {


    const [values, setValues] = useState({
        email: '',
        password: '',
        passwordConfirm: '',
        phoneNumber: '',
        fieldOfStudy: '',
        showPassword: false,
    });
    const {getProfilePageInfo, getFormSet, mainPageState: {formSet}} = useContext(MainPageContext)
    const {authState} = useContext(AuthContext)
    const {changeProfileData} = useContext(AuthContext)
    const [specialize, setSpecialize] = useState({})


    useEffect(() => {
        getFormSet()
        getProfilePageInfo()
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (formSet) {
            setSpecialize([...formSet])
        }
    }, [formSet])

    useEffect(() => {
        if (authState) {
            setValues(prev => ({
                ...prev,
                email: authState.email,
                phoneNumber: authState.telephone,
                fieldOfStudy: authState.specialization,
            }))
        }
    }, [authState])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleFormSubmit = (e) => {
        e.preventDefault()
        changeProfileData(values)
    }

    const passwordIsValidated = values.password === values.passwordConfirm;

    return (
        <Box>
            <Quizzes type={'profile'} flag={'all'}/>
            <Events type={'profile'}/>
            <Container maxWidth={'xl'}>
                <Grid container spacing={4} justifyContent={{xs: 'space-between', lg: 'center'}} mt={10} flexDirection={'row'}>
                    <Grid container item xs={10} md={4} flexDirection={'row'} alignItems={'center'} mb={4} marginRight={5} justifyContent={{
                        xs: 'center',
                    }}>
                        <Grid item>
                            <img src={profilePhoto} alt={'Profile data'}/>
                        </Grid>
                        <Grid item xs={2} className={'newestTitle__text'} justifyContent={'center'}>
                            <p
                                className="newestTitle__text" >
                                <span >Twoje dane</span>
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} spacing={2} color={'white'} bgcolor={'#2A81A1'} py={2} mb={2}
                          mx={{xs: '0', md: '2'}} justifyContent={{xs: 'center', lg: 'flex-start'}}>
                        <form onSubmit={handleFormSubmit}>
                            <Grid item container flexDirection={'column'} spacing={2} justifyContent={'flex-end'}
                                  alignItems={'center'}>
                                <Grid item container xs={12} md={12} alignItems={'center'}
                                      justifyContent={'flex-start'}>
                                    <Grid item xs={6} className={'newestTitle__text'}>
                                        <span>Email</span>
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={5}>
                                        <FormControl fullWidth variant="standard">
                                            <CustomDisableInput
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                color="secondary"
                                                sx={{color: grey['A200']}}
                                                type={'text'}
                                                value={values.email}
                                                onChange={handleChange('email')}

                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid item container xs={12} md={12} alignItems={'center'}
                                      justifyContent={{xs: 'flex-start'}}>
                                    <Grid item xs={6} className={'newestTitle__text'}>
                                        <span>Hasło</span>
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={5}>
                                        <FormControl fullWidth variant="standard">
                                            <CustomDisableInput
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                color="secondary"
                                                sx={{color: grey['A200']}}
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleChange('password')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {values.showPassword ? <VisibilityOff/> :
                                                                <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid item container xs={12} md={12} alignItems={'center'}
                                      justifyContent={{xs: 'flex-start'}}
                                >
                                    <Grid item xs={6} className={'newestTitle__text'}>
                                        <span>Powtórz</span>
                                        <span>hasło</span>
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={5}>
                                        <FormControl fullWidth variant="standard">
                                            <CustomDisableInput
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                color="secondary"
                                                sx={{color: grey['A200']}}
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.passwordConfirm}
                                                onChange={handleChange('passwordConfirm')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={12} alignItems={'center'}
                                      justifyContent={{xs: 'flex-start', md: 'flex-start'}}
                                >
                                    <Grid item xs={6} className={'newestTitle__text'}>
                                        <span>Nr tel.:</span>
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={5}>
                                        <FormControl fullWidth variant="standard">
                                            <CustomDisableInput
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                color="secondary"
                                                sx={{color: grey['A200']}}
                                                type={'text'}
                                                value={values.phoneNumber}
                                                onChange={handleChange('phoneNumber')}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid item container sm={12} md={12} alignItems={'center'}
                                      justifyContent={{xs: 'flex-start', md: 'flex-start'}}
                                >
                                    <Grid item xs={6} className={'newestTitle__text'}>
                                        <span>Specjalizacja</span>
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={5}>
                                        <FormControl fullWidth variant="outlined">
                                            <Select
                                                color="secondary"
                                                sx={{color: grey['A200']}}
                                                type={'text'}
                                                value={values.fieldOfStudy || ''}
                                                onChange={handleChange('fieldOfStudy')}
                                            >
                                                {
                                                    specialize.length && specialize.map((el, i) => <MenuItem value={el}
                                                                                                             key={i}>{el}</MenuItem>)
                                                }
                                            < /Select>
                                        </FormControl>
                                    </Grid>


                                </Grid>
                                <Grid item xs={10} md={4} paddingLeft={[0, 10]} px={[2, 0]}>

                                    <Button variant={'contained'} sx={{backgroundColor: '#A3487F'}} type={'submit'}
                                            fullWidth disabled={!passwordIsValidated}>Zapisz</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Profile;

