import easyLevel from "../../../assets/img/ui/easyLevel.svg";
import mediumLevel from "../../../assets/img/ui/mediumLevel.svg";
import difficultLevel from "../../../assets/img/ui/difficultLevel.svg";

export const levelData = {
    easy: {
        image: easyLevel,
        text: 'łatwy',
        color: '#2FD77C'
    },
    medium: {
        image: mediumLevel,
        text: 'średni',
        color: '#F4EB08'
    },
    difficult: {
        image: difficultLevel,
        text: 'trudny',
        color: '#FF007A'
    }
}