import React from 'react';
import {Circles} from 'react-loader-spinner';

const LocalLoader = () => {
	
	return (
		<div className="loader">
			<Circles
				color={'#FF007A'}
				width={60}
				height={60}
			/>
		</div>
	)
}

export default LocalLoader;