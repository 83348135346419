import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/auth/authContext';
import UserLinks from '../../links/userLinks';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container, Grid } from '@mui/material';
import logo from '../../assets/img/ui/logoDrSim.svg'
import MobileNavMenu from "./MobileNavMenu";

const Header = () => {

  const [menuLinks, setMenuLinks] = useState(null);

  const {isLoggedIn} = useContext(AuthContext)
  const {width} = useWindowDimensions();

  useEffect(() => {
    setMenuLinks(UserLinks(isLoggedIn));
  }, [isLoggedIn])

  return (
    <Grid component={'header'} bgcolor={'rgba(27, 41, 54, 0.6)'} maxWidth={'100vw'}>
      <Container maxWidth="xl" p={0}>
        <Grid className="headerIn" container flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'}
              alignItems={'center'} height={210}
        >
          <Grid item>
            <a href={'/'} className={'headerMenu__logo'}>
              <img src={logo} alt=""/>
              <div>PLATFORMA EDUKACYJNA DLA LEKARZY</div>
            </a>
          </Grid>
          <Grid
            item
            flexGrow={1}
            display={'flex'}
            width={'100%'}
            height={{xs: 0, md: '100%'}}
            alignItems={'center'}
            justifyContent={{xs: 'flex-end', md: 'flex-end'}}
          >
            {width < 900 ?
              <MobileNavMenu/>
              : <Grid
                className={'menuLinks'}
                container
                height={'100%'}
                component={'nav'}
                direction={'row'}
                flexWrap={'nowrap'}
                justifyContent={{md: 'flex-end', xl: 'space-between'}}
                display={{xs: 'none', md: 'flex'}}
              >
                {menuLinks}
              </Grid>}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}

export default Header;