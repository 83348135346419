import {CLEAR_SHAPE_STATE, CLEAR_SHAPE_VIDEO_INFO, GET_SHAPE_INFO, GET_SHAPE_VIDEO_INFO} from '../types';


const handlers = {
    [GET_SHAPE_INFO]: (state, {payload}) => ({...state, video: payload}),
    [GET_SHAPE_VIDEO_INFO]: (state, {payload}) => ({...state, shapeVideoInfo: payload}),
    [CLEAR_SHAPE_STATE]: (state, {payload}) => ({...payload}),
    [CLEAR_SHAPE_VIDEO_INFO]: (state) => ({...state, shapeVideoInfo: null}),
    DEFAULT: state => state,
}

export const shapeReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}