import React from 'react';

const PodcastPlayButton = ({clickHandler}) => {
	
	const onClickHandler = (e) => {
		e.stopPropagation();
		clickHandler()
	}
	
	return <button
		className={'playButton'}
		onClick={(e) => onClickHandler(e)}
	/>
}

export default PodcastPlayButton;