import React, {useState} from 'react'
import arrowDown from '../../../../assets/img/ui/buttons/selectArrowDown.png';
import arrowUp from '../../../../assets/img/ui/buttons/selectArrowUp.png';

const CustomSelect = ({value, handler, selectSet}) => {
	const [isOpen, setIsOpen] = useState(false)
	const menuToggler = () => setIsOpen(prev => !prev)
	
	return (
		<div
			className="customSelect"
			onClick={menuToggler}
		>
			<span>{value}</span>
			<img src={isOpen ? arrowUp : arrowDown} alt="select button"/>
			{
				isOpen &&
				<div className="customSelect__backDrop" onMouseLeave={menuToggler}>
					<ul className="customSelect__set">
						<li onClick={() => handler('Obszar terapeutyczny')}>Obszar terapeutyczny</li>
						{selectSet.map((specialization, index) =>
							<li
								key={index}
								onClick={() => handler(specialization)}
							>
								{specialization}
							</li>)
						}
					</ul>
				</div>
			}
		</div>
	);
}

export default CustomSelect;