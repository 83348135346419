import React from 'react';
import { Container, Grid } from '@mui/material';
import HtmlSelector from '../../shared/pureHtml/HtmlSelector';
import { validateString } from '../../../utils/validateString';

const PodcastDescription = ({description, authors}) => {
  return (
    <Container maxWidth="xl">
      <Grid className={'podcastDescription'} container justifyContent={'space-between'} p={'20px 0'}
            flexDirection={{xs: 'column', lg: 'row'}}>
        <Grid item xs={12} lg={7}>
          {
            description.length > 0 && description.map((el, i) => <HtmlSelector element={el} key={i}/>)
          }
        </Grid>
        <Grid item xs={12} lg={4}>
          <h6>Autorzy:</h6>
          {
            authors.map((author, index) => <div className={'podcastDescription__authorDescription'}
                                                key={index}
                                                dangerouslySetInnerHTML={{__html: validateString(author.description)}}/>)
          }
        </Grid>
      </Grid>
    </Container>
  )
}


export default PodcastDescription;