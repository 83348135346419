import React from 'react'
import FusionText from './FusionText';
import ImageFrame from './ImageFrame';

const HtmlSelector = ({element, tabs}) => {
	switch (element.type) {
		case 'pureHTML':
			return <FusionText insideContent={element.content} tabs={tabs}/>
		case 'fusion_text':
			return <FusionText insideContent={element.content} tabs={tabs}/>
		case 'fusion_imageframe':
			return <ImageFrame insideContent={element.content} tabs={tabs}/>
		default:
			return <p> </p>
	}
}

export default HtmlSelector;