import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Grid} from "@mui/material";
import fluentArrow from '../../../../assets/img/ui/buttons/fluent_arrow.png';
import {AuthContext} from '../../../../context/auth/authContext';

const VariaImageBox = ({element, modalToggle}) => {
    
    const {isLoggedIn} = useContext(AuthContext);
    const navigate = useNavigate();
    
    return (
        <Grid
            className={'variaImageBox'}
            onClick={() => {
                if (isLoggedIn) {
                    modalToggle(element.id)
                } else {
                    navigate('/login')
                }
            }}
        >
            <img src={element.image} alt="minimized content"/>
            <button
            >
                <img src={fluentArrow} alt="button"/>
            </button>
            <Grid className={'variaImageBox__title'}>
                <p>{element.title}</p>
            </Grid>
        </Grid>
    )
}

export default VariaImageBox;