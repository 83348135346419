import React, {useEffect, useState} from 'react';
import {validateString} from "../../../utils/validateString";

const SingleAnswerBox = ({answer, answerIndex, questionIndex, changeAnswerHandler}) => {

	const [elementWidth, setElementWidth] = useState('0%');
	const [elementColor, setElementColor] = useState('')
	
	useEffect(()=>{
		if (answer.percentage !== undefined) {
			setElementWidth(`${answer.percentage}%`)
			setElementColor(() => answer.isCorrect ? '#2FD77C' : answer.checked ? '#FF007A' : '#C7C7C7')
		} else {
			setElementWidth('0%')
			setElementColor('transparent')
		}
	}, [answer])
	
	return (
		<div
			className={'singleAnswerBox'}
			onClick={() => {
				if (answer.percentage === undefined) {
					changeAnswerHandler(questionIndex, answerIndex)
				}
			}}
		>
			<span className={'singleAnswerBox__gamification'}
				  style={{
					  width: elementWidth,
					  backgroundColor: elementColor,
					  borderLeft: elementColor !== '' ? `5px solid ${elementColor}` : 'none',
				  }}/>
			<div className={`singleAnswerBox__radio ${answer.checked ? 'singleAnswerBox__userAnswer' : ''}`}/>
			<p className={'singleAnswerBox__title'} dangerouslySetInnerHTML={{__html: validateString(answer.answer)}}></p>
			<span
				className={'singleAnswerBox__percentage'}>{answer.percentage !== undefined ? answer.percentage + '%' : ''}</span>
		</div>
	)
}

export default SingleAnswerBox;