import React, { useContext, useEffect, useState } from "react";
import { ShapeContext } from "../../../context/shape/shapeContext";
import { Grid } from "@mui/material";
import VariaVideoBox from "../../shared/ui/boxes/VariaVideoBox";
import NoContent from "../../shared/ui/NoContent";
import { ReactComponent as LoadMore } from './../../../assets/img/ui/buttons/loadMore.svg'

const ShapeContent = ({isNotStartedSelected, isFinishedSelected, finderValue}) => {

    const {shapeState} = useContext(ShapeContext);
    const [drawArray, setDrawArray] = useState([]);
    const cardsPerPage = 9;
    const [next, setNext] = useState(cardsPerPage);

    useEffect(() => {
        if (shapeState.video) {

            let tempDrawArray = [...shapeState.video];

            if (!isNotStartedSelected) {
                tempDrawArray = [...tempDrawArray.filter(element => element.status !== 'notViewed')]
            }

            if (!isFinishedSelected) {
                tempDrawArray = [...tempDrawArray.filter(element => element.status !== 'viewed')]
            }

            if (finderValue.trim().length) {
                tempDrawArray = [...tempDrawArray.filter(element => element.title.toLowerCase().includes(finderValue.toLowerCase())
                    || (element.authors.length && element.authors.filter(author => author.name.toLowerCase().includes(finderValue.toLowerCase())).length)
                    || (element.description?.length && element.description.filter(content => content.content.toLowerCase().includes(finderValue.toLowerCase())).length)
                )]
            }

            setDrawArray([...tempDrawArray]);
            setNext(cardsPerPage)
        }
    }, [shapeState, isNotStartedSelected, isFinishedSelected, finderValue])

    const loadMoreImages = () => {
        setNext(next + cardsPerPage);
    }

    return (
        <Grid container flexDirection={'column'} mb={5} maxWidth={'100vw'} className={'shapeContent'}>
            <Grid item xs={12} mt={3} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                {
                    drawArray.length ?
                        <>
                            <Grid
                                container
                                maxWidth={{ sm: '90vw', md: '1000px', lg: '1900px'}}
                                // gap={materialOption === 'video' ? '10px' : materialOption === 'image' ? '13px' : '10px'}
                                gap={{xs: '18px', lg: '12px'}}
                                alignItems={'center'}
                                justifyContent={{
                                    xs: 'center',
                                    xl: 'flex-start',
                                }}
                            >
                                {
                                    drawArray.length && drawArray.slice(0, next).map(element =>
                                        <VariaVideoBox element={element} key={element.id} type={'shape'}/>
                                    )
                                }
                            </Grid>
                            {next <= drawArray.length && <Grid
                                container
                                justifyContent={'center'}
                                pt={2}
                                pb={2}
                                mt={3}
                            >
                                <div onClick={loadMoreImages} className={'shapeContent__button'}>
                                    ZOBACZ WIĘCEJ
                                    <LoadMore/>
                                </div>
                            </Grid>}
                        </>
                        :
                        <NoContent/>
                }
            </Grid>
        </Grid>
    )
}

export default ShapeContent