import { useEffect, useMemo } from 'react';

export const useBodyOverflow = () => {
  const bodyOverflow = useMemo(() => document.querySelector('body'), []);
  const html = useMemo(() => document.querySelector('html'), []);

  useEffect(() => {
    if (bodyOverflow && html) {
      bodyOverflow.style.overflow = 'hidden';
      html.style.overflow = 'hidden';
      bodyOverflow.style.position = 'relative';
      html.style.position = 'relative';
    }

    return () => {
      bodyOverflow.style.overflow = 'visible';
      html.style.overflow = 'visible';
      bodyOverflow.style.position = 'static';
      html.style.position = 'static';
    }
  })
}