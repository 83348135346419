import React, {useContext} from 'react'
import {MainPageContext} from '../../../context/mainPage/mainPageContext';
import {Grid} from '@mui/material';
import EventBox from '../../shared/ui/boxes/EventBox';
import PageTitles from '../../shared/ui/PageTitles';
import NoContent from '../../shared/ui/NoContent';

const NewestEvents = () => {
	const {mainPageState: {events}} = useContext(MainPageContext);
	return (
		<Grid container mt={'50px'} mb={'50px'}>
			<PageTitles
				titleOptions={'events'}
				flag={true}
			/>
			<Grid item xs={12}>
				<Grid
					container
					alignItems={'center'}
					justifyContent={{xs: 'center', lg: 'flex-start'}}
					m={{md: '0 auto'}}
					maxWidth={{md: '1000px', lg: '1900px'}}
				>
					{
						events
							?
							events.map(event => <EventBox  isOneLine={true}  event={event} key={event.id}/>)
							:
							<NoContent />
					}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default NewestEvents