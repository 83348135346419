import React, { useContext, useEffect, useState } from 'react';
import { MainPageContext } from '../../../context/mainPage/mainPageContext';
import { Container, Grid } from '@mui/material';
import PageTitles from '../../shared/ui/PageTitles';
import { MaterialUIPagination } from '../../shared/ui/MaterialUIPagination';
import EventBox from '../../shared/ui/boxes/EventBox';
import NoContent from '../../shared/ui/NoContent';
import useWindowDimensions from "../../../hooks/useWindowDimensions";


const Events = ({type}) => {
    const {
        getSpecializationsSet,
        mainPageState: {specializations, events},
        getPageInfo,
        clearEvents,
    } = useContext(MainPageContext);

    const [isNotStartedSelected, setIsNotStartedSelected] = useState(true);
    const [isInProgressSelected, setIsInProgressSelected] = useState(true);
    const [isFinishedSelected, setIsFinishedSelected] = useState(true);
    const [specialization, setSpecialization] = useState('Obszar terapeutyczny');
    const [specializationsSelectSet, setSpecializationsSelectSet] = useState([]);
    const [finderValue, setFinderValue] = useState('')
    const [drawArray, setDrawArray] = useState([]);
    const [pageDrawArray, setPageDrawArray] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1)
    const {width} = useWindowDimensions();
    const cardsPerPage = width > 1496 ? 8 : width < 1200 ? 4 : width < 900 ? 12 : 9

    useEffect(() => {
        getSpecializationsSet('sfwd-courses')
        getPageInfo('events')

        return () => {
            clearEvents();
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
            if (events) {
                setCurrentPage(1);
                let tempDrawArray = [...events];
                if (!isNotStartedSelected || type) {
                    tempDrawArray = [...tempDrawArray.filter(event => event.details.status !== 'notEnrolled')]
                }
                if (!isInProgressSelected) {
                    tempDrawArray = [...tempDrawArray.filter(event => event.details.status !== 'started')]
                }
                if (!isFinishedSelected) {
                    tempDrawArray = [...tempDrawArray.filter(event => event.details.status !== 'completed')]
                }
                if (specialization !== 'Obszar terapeutyczny') {
                    tempDrawArray = [...tempDrawArray.filter(event => !!event.details.medicalAreas.find(el => el === specialization))]
                }
                if (finderValue.trim().length) {
                    tempDrawArray = [...tempDrawArray.filter(event => event.title.toLowerCase().includes(finderValue.toLowerCase())
                        || (!!event.details.commenterName && event.details.commenterName.toLowerCase().includes(finderValue.toLowerCase()))
                        || (!!event.details.teacherName && event.details.teacherName.toLowerCase().includes(finderValue.toLowerCase()))
                        || (!!event.details.description && event.details.description.toLowerCase().includes(finderValue.toLowerCase()))
                    )]
                }
                setDrawArray([...tempDrawArray]);
                setPageCount(Math.ceil(tempDrawArray.length / cardsPerPage))
            }
        }, [events, isNotStartedSelected, isInProgressSelected, isFinishedSelected, specialization, finderValue, type, cardsPerPage]
    )

    useEffect(() => {
        if (specializations) {
            setSpecializationsSelectSet([...specializations])
        }
        // eslint-disable-next-line
    }, [specializations])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <Container maxWidth="xl">
            <Grid container>
                <PageTitles
                    titleOptions="events"
                    type={type}
                    flag={false} filtersMenu={true}
                    isNotStartedSelected={isNotStartedSelected}
                    setIsNotStartedSelected={setIsNotStartedSelected}
                    isInProgressSelected={isInProgressSelected}
                    setIsInProgressSelected={setIsInProgressSelected}
                    isFinishedSelected={isFinishedSelected}
                    setIsFinishedSelected={setIsFinishedSelected}
                    specialization={specialization}
                    setSpecialization={setSpecialization}
                    specializationsSelectSet={specializationsSelectSet}
                    finderValue={finderValue} setFinderValue={setFinderValue}
                />
                <Grid item xs={12}>
                    {
                        !!drawArray.length ?
                            <>
                                <Grid container
                                      maxWidth={{md: '1000px', lg: '1900px'}}
                                      m={{md: '0 auto'}}
                                      justifyContent={{xs: 'center', lg: 'flex-start'}}
                                >
                                    {
                                        !!pageDrawArray.length && pageDrawArray.map(event => <EventBox event={event}
                                                                                                     key={event.id}/>)
                                    }
                                </Grid>
                                <Grid container justifyContent={'flex-end'} pt={2} pb={2}>
                                    <MaterialUIPagination
                                        count={pageCount}
                                        page={currentPage}
                                        onChange={(e, value) => {
                                            setCurrentPage(value);
                                            window.scrollTo({top: 0, behavior: 'smooth'});
                                        }}
                                        size={'large'}
                                    />
                                </Grid>
                            </>
                            :
                            <NoContent/>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}

export default Events;
