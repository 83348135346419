import {
    ADD_LOGIN_TOKEN,
    GET_USER_SPECIALIZATIONS,
    IS_USER_LOAD,
    LOGIN_USER,
    LOGOUT_USER,
    SAVE_NAVIGATION_LINK
} from '../types';

const handlers = {
    [ADD_LOGIN_TOKEN]: (state, {payload}) => ({...state, loginToken: payload.loginToken, role: payload.role}),
    [LOGIN_USER]: (state, {payload}) => ({...state, isLoggedIn: true, ...payload}),
    [IS_USER_LOAD]: (state) => ({...state, isUserLoad: true}),
    [SAVE_NAVIGATION_LINK]: (state, {payload}) => ({...state, navigationLink: payload}),
    [GET_USER_SPECIALIZATIONS]: (state, {payload}) => ({...state, userSpecializations: payload}),
    [LOGOUT_USER]: (state, {payload}) => ({...payload, isUserLoad: true}),
    DEFAULT: state => state,
}

export const authReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}