import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { QuizContext } from '../../../../context/quiz/quizContext';
import QuestionnaireBox from '../../../shared/gamification/QuestionnaireBox';
import { useParams } from 'react-router-dom';
import { timeDifference } from '../../../../utils/timeDifference'

const PesResults = () => {
    const {quizState} = useContext(QuizContext);
    const [endTimeResult, setEndTime] = useState('')
    const params = useParams();
    const isResultVisible = !params.accessToken;

    useEffect(() => {
        if (!window.localStorage.getItem(`${params.id}-timeResult`)) {
            window.localStorage.setItem(`${params.id}-timeResult`, timeDifference(new Date().getTime(), window.localStorage.getItem(`${params.id}-startTime`)))
            setEndTime(timeDifference(new Date().getTime(), window.localStorage.getItem(`${params.id}-startTime`)))
        } else {
            setEndTime(window.localStorage.getItem(`${params.id}-timeResult`))
        }

    }, [])

    return quizState.caseStep?.model === 'withBeforeExamResults' &&
        <Grid className={'pesResults'}>
                <>
                    <h2 className={'pesResults__title'}>WYNIKI</h2>
                    <Grid container className={'pesResults__columns'}>
                        <Grid item xs={12} className={'pesResults__subtitle'}>
                            <h2>Gratulacje!</h2>
                            <p>Ukończyłeś: <strong>POWTÓRKA PRZED PES - <span style={{textTransform: 'uppercase'}}>{quizState.title}. </span></strong>
                                <br/>Aby rozwiązać kolejny zestaw 5 pytań, kliknij „Zacznij od nowa”.<br/><br/>
                                Pytania wracają do zbioru wszystkich dostępnych testów i mogą zostać wylosowane przez system ponownie. Pytania, na które udzielono błędnej odpowiedzi są powielone trzykrotnie, zwiększając tym samym szansę na ich ponowne wylosowanie. </p>
                        </Grid>
                        {isResultVisible &&
                          <Grid container>
                              <Grid item xs={12} sm={6} height={'100%'}>
                                  <Grid container alignItems={'center'} height={'100%'}>
                                      <p className={'pesResults__score'}>Odpowiedziano
                                          <span
                                            className={`${quizState.score.correct <= 2 ? 'pesResults__score-red' : 'pesResults__score-green'}`}> poprawnie
                                        na {quizState.score.correct} z {quizState.score.total}</span> pytań.</p>
                                      <p className={'pesResults__score'}>Twój czas:&nbsp;
                                          {endTimeResult}</p>
                                  </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                  <Grid container flexDirection={'column'}>
                                      <QuestionnaireBox
                                        title="WYNIK"
                                        averageScore={quizState.score.total}
                                        userScore={quizState.score.userScoreBeforeExam}
                                      />
                                  </Grid>
                              </Grid>
                          </Grid>
                        }
                    </Grid>
                </>
        </Grid>
}

export default PesResults;