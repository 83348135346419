import React from 'react'
import { Grid } from '@mui/material';

const QuestionnaireRangeItem = ({field, radioHandler}) => {
  return (
    <Grid container className={'questionnaireRangeItem__columns'} flexDirection={{xs: 'column', lg: 'row'}}>
      <Grid item xs={12} lg={6} height={'100%'} className={'questionnaireRangeItem__columns-ls'}>
        <Grid container>
          <p className={'questionnaireRangeItem__text'}>{field.title}</p>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6} className={'questionnaireRangeItem__columns-rs'}>
        <Grid className={'questionnaireRangeItem'}>
          {field.options.map((option, index) => (
            <div
              key={index}
              className={'questionnaireRangeItem__radio'}
              onClick={() => radioHandler(field.id, option.value)}
            >
							<span
                className={field.value === option.value ? 'questionnaireRangeItem__radio-active' : ''}
              />
              <span>{option.value}</span>
            </div>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuestionnaireRangeItem;