import {CLEAR_VARIA_STATE, CLEAR_VARIA_VIDEO_INFO, GET_VARIA_INFO, GET_VARIA_VIDEO_INFO} from '../types';


const handlers = {
    [GET_VARIA_INFO]: (state, {payload}) => ({...state, ...payload}),
    [GET_VARIA_VIDEO_INFO]: (state, {payload}) => ({...state, variaVideoInfo: payload}),
    [CLEAR_VARIA_STATE]: (state, {payload}) => ({...payload}),
    [CLEAR_VARIA_VIDEO_INFO]: (state) => ({...state, variaVideoInfo: null}),
    DEFAULT: state => state,
}

export const variaReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}