import React, { createRef, useContext, useEffect, useState } from 'react'
import { PodcastsContext } from '../../../context/podcasts/podcastsContext';
import { useParams } from 'react-router-dom';
import PodcastHeader from './PodcastHeader';
import PodcastDescription from './PodcastDescription';
import PodcastParts from './PodcastParts';
import { AuthContext } from "../../../context/auth/authContext";
import PodcastPlayer from "../../shared/player/PodcastPlayer";

const Podcast = () => {
  const {loginToken} = useContext(AuthContext);
  const {getPodcastInfo, clearPodcastsState, podcastsState} = useContext(PodcastsContext);
  const params = useParams();
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(0);
  const [episodes, setEpisodes] = useState(null);
  const [episode, setEpisode] = useState(null);
  const podcastPlayerRef = createRef();

  useEffect(() => {
    return () => {
      clearPodcastsState();
    }
  }, [])

  useEffect(() => {
    getPodcastInfo(params.id, params.accessToken ?? loginToken ?? null)
  }, [params.id])


  useEffect(() => {
    if (podcastsState.isSeries) {
      let tempArray = [];
      podcastsState.episodes.forEach((episode, index) => tempArray.push({
        preview: podcastsState.image,
        title: episode.episodeTitle,
        authors: episode.episodeAuthors,
        file: episode.episode_file,
        stoppedAt: episode.stoppedAt ? episode.stoppedAt : 0,
      }))
      setEpisodes([...tempArray])
    } else {
      setEpisodes([{
        preview: podcastsState.image,
        title: podcastsState.title,
        authors: podcastsState.authors,
        file: podcastsState.file,
        stoppedAt: podcastsState.stoppedAt ? podcastsState.stoppedAt : 0,
      }]);
    }
  }, [podcastsState.isSeries, podcastsState])

  const clickHandler = (index) => {
    setCurrentDescriptionIndex(index)
    setCurrentEpisode(index);
    if (episodes) {
      setEpisode({...episodes[index]})
    }
  }


  useEffect(() => {
    if (currentEpisode !== null) {
      getPodcastInfo(params.id, params.accessToken ?? loginToken ?? null, false)
        .then(
          podcastPlayerRef?.current?.playPauseAudio()
        )
    }
  }, [currentEpisode])

  return podcastsState.isLoaded &&
    <>
      <PodcastHeader
        isSeries={podcastsState.isSeries}
        image={podcastsState.image}
        title={podcastsState.title}
        medicalAreas={podcastsState.medicalAreas}
        authors={podcastsState.authors}
        durationTime={podcastsState.duration}
        status={podcastsState.status}
        stoppedAt={podcastsState.stoppedAt}
        file={podcastsState.file}
        setCurrentEpisode={setCurrentEpisode}
        clickHandler={clickHandler}
      />
      <PodcastDescription description={podcastsState.description} authors={podcastsState.authors}/>
      {
        podcastsState.isSeries &&
        <PodcastParts clickHandler={clickHandler} currentDescriptionIndex={currentDescriptionIndex}
                      setCurrentEpisode={setCurrentEpisode}
                      currentEpisode={currentEpisode} setCurrentDescriptionIndex={setCurrentDescriptionIndex}/>
      }
      <PodcastPlayer clickHandler={clickHandler} episode={episode} episodes={episodes} ref={podcastPlayerRef}
                     isSeries={podcastsState.isSeries}
                     episodeIndex={currentEpisode}
      />
    </>
}

export default Podcast