import React from 'react';
import {Button} from '@mui/material';
import HtmlSelector from '../pureHtml/HtmlSelector';

const TheoryQuizModal = ({content, closeModalHandler}) => {
	return (
		<div className='customModal theoryQuizModal'>
			<div className='customModal__content theoryQuizModal__content'>
				{
					content.map((el, i) => <HtmlSelector element={el} key={i} tabs/>)
				}
				<Button onClick={closeModalHandler} variant='contained' sx={{marginTop: '20px'}}>Powrót</Button>
			</div>
		</div>
	)
}

export default TheoryQuizModal;