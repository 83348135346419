import React, {useContext, useEffect, useState} from 'react'
import {AuthContext} from '../../../context/auth/authContext';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {NavLink, useParams} from 'react-router-dom';

const Login = () => {
    const {token} = useParams();
    const {authUser, authByToken} = useContext(AuthContext);
    const [isAuthByToken, setIsAuthByToken] = useState(false);
    const [login, setLogin] = useState('');
    // const [login, setLogin] = useState('1111111');
    const [password, setPassword] = useState('');
    // const [password, setPassword] = useState('Testowanie215%');
    const [isRemember, setIsRemember] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    const showPassHandler = () => setShowPassword(prev => !prev);
    const handleMouseDownPassword = (e) => e.preventDefault();

    useEffect(() => {
        if (!!token) {
            setIsAuthByToken(true);
        }
    }, [token, setIsAuthByToken]);

    useEffect(() => {
        if (isAuthByToken) {
            setIsAuthByToken(false);
            authByToken(token);
        }
    }, [isAuthByToken, authByToken, token])

    const submitHandler = (e) => {
        e.preventDefault()
        const data = new FormData();
        data.append('username', login);
        data.append('password', password);
        authUser(data);
    }

    return (
        <Grid
            container
            flex
            alignItems={'center'}
            justifyContent={'center'}
            pt={4}
            pb={4}
            className={'auth__container'}
        >
            <Grid
                width={{xs: '90%', md: '600px', lg: '900px'}}
                sx={{
                    padding: '20px',
                    borderRadius: '6px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, .5)',
                    backgroundColor: '#fff',
                }}
                container
            >
                <Grid item xs={12} lg={6} pr={{xs: 0, lg: 3}}>
                    <Stack direction={'column'} height={'100%'}>
                        <h3 className="auth__title">
                            Platforma edukacyjna dla lekarzy
                        </h3>
                        <p className="auth__ulTitle">
                            Znajdziesz tu:
                        </p>
                        <ul className="auth__ul">
                            <li className="auth__li">
                                interaktywne symulatory decyzji klinicznych
                            </li>
                            <li className="auth__li">
                                najnowsze dane naukowe ze świata medycyny
                            </li>
                            <li className="auth__li">
                                ciekawe przypadki opracowane przez specjalistów
                            </li>
                        </ul>
                        <p className="auth__text">
                            Z platformy edukacyjnej Dr SIM możesz korzystać, gdzie chcesz i kiedy chcesz, pod jednym
                            warunkiem: musisz posiadać uprawnienia lekarskie.
                        </p>
                        <p className="auth__help">
                           Pomoc techniczna: <a href="mailto:drsim@softwebo.pl">drsim@softwebo.pl</a>
                        </p>
                    </Stack>
                </Grid>
                <Grid component={'form'} onSubmit={(e) => submitHandler(e)} item xs={12} lg={6}
                      borderLeft={{xs: 'none', lg: '1px solid #CECECE'}}
                      pl={{xs: 'none', md: '18px'}} pt={5}>
                    <Stack direction={'column'}>
                        <h3 className="auth__title">
                            Logowanie
                        </h3>
                        <p className="auth__text" style={{marginBottom: '30px'}}>
                            Uzupełnij, żeby uzyskać dostęp do platformy.
                        </p>
                        <Stack direction={'column'} spacing={2}>
                            <FormControl required>
                                <InputLabel htmlFor="pwz">PWZ</InputLabel>
                                <OutlinedInput
                                    id="pwz"
                                    label="PWZ"
                                    placeholder="enter PWZ"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                />
                            </FormControl>
                            <FormControl required>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="enter password"
                                    value={password}
                                    autoComplete="on"
                                    onChange={(e) => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={showPassHandler}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Stack>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <FormControlLabel control={
                                <Checkbox
                                    name="isRemember"
                                    checked={isRemember}
                                    onChange={(e) => setIsRemember(e.target.checked)}
                                />} label="Zapamiętaj mnie"/>
                            <NavLink to={'/reset-password'} style={{color: 'black'}}>Nie pamiętasz hasła?</NavLink>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'flex-end'} mt={1} mb={2}>
                            <Button
                                type='submit'
                                variant='contained'
                            >
                                ZALOGUJ SIĘ
                            </Button>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <p className="auth__text">
                                Nie masz konta? <NavLink to={'/registration'}
                                                         style={{color: 'black', fontWeight: '700'}}>Zarejestruj
                                się</NavLink>
                            </p>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Login