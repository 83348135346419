import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { PodcastsContext } from '../../../context/podcasts/podcastsContext';
import playButtonImg from '../../../assets/img/ui/buttons/playButton32x36.svg';
import pauseButtonImg from '../../../assets/img/ui/buttons/pauseButton.svg';
import previousButtonImg from '../../../assets/img/ui/buttons/playerLeftArrow.svg';
import nextButtonImg from '../../../assets/img/ui/buttons/playerRightArrow.svg';

const PodcastPlayer = forwardRef((props, ref) => {
  const {isSeries, episodeIndex, episode, episodes, clickHandler} = props
  const {podcastsState, podcastProgress, podcastComplete} = useContext(PodcastsContext);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  // Refs
  const audioRef = useRef(new Audio());
  const intervalRef = useRef();
  const isReady = useRef(true);
  const {duration} = audioRef.current;
  const rangeInputRef = useRef(null);

  useImperativeHandle(ref, () => (
    {
      playPauseAudio,
    }
  ))

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        podcastComplete(`wp-json/softwebo/v1/podcast/${podcastsState.id}${isSeries ? `?episodeIndex=${episodeIndex}` : ''}`)
        if (isSeries) {
          toNextTrack();
        } else {
          setTrackProgress(0);
          setIsPlaying(false);
        }
      } else {
        setTrackProgress(audioRef.current?.currentTime);
        if (isPlaying) {
          podcastProgress(`wp-json/softwebo/v1/podcast/${podcastsState.id}?stoppedAt=${audioRef.current.currentTime}${isSeries ? `&episodeIndex=${episodeIndex}` : ''}`)
        }
      }
    }, 1000);
  };

  const playPauseAudio = useCallback(() => {
    if (episode) {
      audioRef.current.pause();
      audioRef.current = new Audio(episode.file);
      audioRef.current.currentTime = episode.stoppedAt ? +episode.stoppedAt : 0;
      setTrackProgress(episode.stoppedAt ? +episode.stoppedAt : 0);
      if (isReady.current) {
        audioRef.current.play().then();
        setIsPlaying(true);
        startTimer();
      } else {
        // Set the isReady ref as true for the next pass
        isReady.current = true;
      }
    }

  }, [episode, startTimer])

  const onScrub = (value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
    onScrubEnd()
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const toPrevTrack = () => {
    if (episodeIndex - 1 < 0) {
      setTrackProgress(0);
      setIsPlaying(false);
    } else {
      clickHandler(episodeIndex - 1);
    }
  };

  const toNextTrack = () => {
    if (episodeIndex < episodes.length - 1) {
      clickHandler(episodeIndex + 1);
    } else {
      setTrackProgress(0);
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play().then();
      startTimer();
    } else {
      audioRef.current.pause();
      startTimer();
    }
  }, [isPlaying]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  return episode &&
    <Grid
      container
      className={'player'}
      flexDirection={{xs: 'column', lg: 'row'}}
      alignItems={{xs: 'center'}}
      flexWrap={'nowrap'}
    >
      <Grid item xs={12} lg={4} className={'player__description'}>
        <img className={'player__description-preview'} src={episode.preview} alt="episode preview"/>
        <Grid className={'player__description-text'} container flexDirection={'column'}>
          <p>{episode.title}</p>
          <p>Autor: {episode.authors?.map((author, index) => <span
            key={index}>{isSeries ? author.episodeAuthorName : author.name}{index !== episode.authors.length - 1 && ','} </span>)}</p>
        </Grid>
      </Grid>
      {/*<audio ref={audioRef} src={audioRef.current ?? ''}/>*/}
      <Grid item xs={12} lg={4} className={'player__control'} container>
        <Grid container className={'player__control-buttonArea'} pb={[3, 2, 1, 1]}>
          <button
            type="button"
            className="player__prevButton"
            aria-label="Previous"
            disabled={!isSeries || episodeIndex === 0}
            style={{
              backgroundColor: !isSeries || episodeIndex === 0 ? 'gray' : '',
              transform: !isSeries || episodeIndex === 0 ? 'none' : '',
            }}
            onClick={toPrevTrack}
          >
            <img src={previousButtonImg} alt="previous button"/>
          </button>
          {
            isPlaying ?
              <button
                type="button"
                className="player__pauseButton"
                onClick={() => setIsPlaying(false)}
                aria-label="Pause"
              >
                <img src={pauseButtonImg} alt="play button"/>
              </button>
              :
              <button
                type="button"
                className="player__playButton"
                onClick={() => setIsPlaying(true)}
                aria-label="Play"
              >
                <img src={playButtonImg} alt="play button"/>
              </button>
          }
          <button
            type="button"
            className="player__nextButton"
            aria-label="Next"
            onClick={toNextTrack}
            disabled={!isSeries}
            style={{
              backgroundColor: !isSeries || episodeIndex === episodes.length - 1 ? 'gray' : '',
              transform: !isSeries || episodeIndex === episodes.length - 1 ? 'none' : '',
            }}
          >
            <img src={nextButtonImg} alt="next button"/>
          </button>
        </Grid>
        <Grid container className={'player__control-progressBar'}>
          <input
            ref={rangeInputRef}
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : undefined}
            className={`progress${duration ? '' : ' noVisible'}`}
            onChange={(e) => onScrub(+e.currentTarget.value)}
          />
        </Grid>
      </Grid>
    </Grid>
})

export default PodcastPlayer;
