import React, { useContext } from 'react'
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import pesBanner from './../../../assets/img/ui/pesBanner.png'
import rightIcon from '../../../assets/img/ui/menuIcons/rightIcon.svg';
import { MainPageContext } from "../../../context/mainPage/mainPageContext";

const PesBanner = () => {
    const navigate = useNavigate();
    const {clearQuizzes,} = useContext(MainPageContext);

    return (
        <Grid container mb={'50px'} mt={'43px'}>
            <Grid item xs={12} style={{height: '100%', maxHeight: '380px'}}>
                <Grid container onClick={() => {
                    navigate('quizzes/before-exam');
                    clearQuizzes()
                }}>
                    <Grid
                        container
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={{xs: 'center', lg: 'flex-start'}}
                        m={{md: '0 auto'}}
                        maxWidth={{md: '1000px', lg: '1900px'}}
                        className={'pesBanner'}
                    >

                        <img src={pesBanner} alt="baner pes"/>
                        <div>
                            <div>
                                <h1>Sprawdź swoją wiedzę i umiejętności</h1>
                                <h2>Rozwiąż quiz bazujący na pytaniach egzaminacyjnych z PES</h2>
                            </div>
                            <div className={'pesBanner__imageArea'}>
                                <img src={rightIcon} alt=""/>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PesBanner