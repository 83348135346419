import React, {useContext, useEffect, useState} from 'react'
import {Grid} from '@mui/material';
import {QuizContext} from '../../../../context/quiz/quizContext';
import QuestionnaireBox from '../../../shared/gamification/QuestionnaireBox';
import QuestionnaireRangeItem from '../../../shared/customElements/QuestionnaireRangeItem';
import QuizNextButton from '../../../shared/ui/buttons/QuizNextButton';
import {useParams} from 'react-router-dom';

const QuizQuestionnaire = () => {
	const {quizState, sendQuestionnaire} = useContext(QuizContext);
	
	const [specialization, setSpecialization] = useState('');
	const [specializationSet, setSpecializationSet] = useState(['']);
	const [isValid, setIsValid] = useState(false);
	const [fieldsState, setFieldsState] = useState([]);
	
	const params = useParams();
	
	const isResultVisible = !params.accessToken;
	
	useEffect(() => {
		fieldsState.filter((el) => el.value !== null).length === fieldsState.length && specialization !== '' ?
			setIsValid(true) : setIsValid(false)
	}, [fieldsState, specialization])
	
	useEffect(() => {
		if (quizState.questionnaire?.fields[1]?.options?.values) {
			const tempArray = [];
			Object.entries(quizState.questionnaire.fields[1].options.values).forEach(([key, value]) => tempArray.push(value))
			setSpecializationSet(tempArray)
		}
		
		setFieldsState([])
		
		quizState.questionnaire?.fields.forEach(field => {
			if (field.type === 'scale') {
				const tempArray = []
				Object.entries(field.options.values).forEach(([key, value]) => tempArray.push({label: key, value}))
				setFieldsState(prev => [...prev, {
					title: field.label,
					id: +field.id,
					value: null,
					options: [...tempArray],
				}])
			}
		})
		
	}, [quizState])
	
	const radioHandler = (id, value) => {
		const tempObj = JSON.parse(JSON.stringify(fieldsState.filter(el => el.id === id)[0]))
		tempObj.value = value
		const tempArray = fieldsState.map(el => el.id === id ? tempObj : el)
		setFieldsState(JSON.parse(JSON.stringify(tempArray)));
	}
	
	const QuoteHandler = () => {
		const data = {
			postId: quizState.id,
			formId: quizState.questionnaire.formId,
			formKey: quizState.questionnaire.formKey,
			nonce: quizState.questionnaire.nonce,
			frmSubmitEntry: quizState.questionnaire.frmSubmitEntry,
			fields: quizState.questionnaire.fields.map((el, index) => {
				switch (el.type) {
					case 'user_id':
						return {
							id: el.id,
							value: el.options.defaultValue,
						}
					case 'select':
						return {
							id: el.id,
							value: specialization,
						}
					case 'scale':
						return {
							id: el.id,
							value: fieldsState.filter(f => +el.id === +f.id)[0].value,
						}
					case 'hidden':
						return {
							id: el.id,
							value: el.options.defaultValue,
						}
					default:
						return {}
				}
			}),
		}
		sendQuestionnaire(data)
	}
	
	return quizState.caseStep?.model === 'withQuestionnaire' &&
		<Grid className={'quizQuestionnaire'}>
			
			{
				isResultVisible &&
				<>
					<h2 className={'quizQuestionnaire__title'}>WYNIKI</h2>
					<Grid container className={'quizQuestionnaire__columns'}>
						<Grid item xs={6} height={'100%'} className={'quizQuestionnaire__columns-ls'}>
							<Grid container alignItems={'center'} height={'100%'}>
								<p className={'quizQuestionnaire__score'}>Odpowiedziano poprawnie
									na {quizState.score.correct} z {quizState.score.total} pytań.</p>
							</Grid>
						</Grid>
						<Grid item xs={6} lg={12} className={'quizQuestionnaire__columns-rs'}>
							<Grid container flexDirection={'column'}>
								<QuestionnaireBox
									title="Średni wynik"
									averageScore={quizState.score.averageScore}
								/>
								<QuestionnaireBox
									title="Twój wynik"
									averageScore={quizState.score.averageScore}
									userScore={quizState.score.userScore}
								/>
							</Grid>
						</Grid>
					</Grid>
				</>
			}
			
			<Grid container className={'quizQuestionnaire__columns'}>
				<Grid item xs={12} lg={6} height={'100%'} className={'quizQuestionnaire__columns-ls'}>
					<Grid container>
						<p className={'quizQuestionnaire__text'}>SPECJALIZACJA:</p>
					</Grid>
				</Grid>
				<Grid item xs={12} lg={6} className={'quizQuestionnaire__columns-rs'}>
					<Grid container>
						<select name="specialization" className={'quizQuestionnaire__select'} value={specialization}
								onChange={(e) => setSpecialization(e.target.value)}>
							{
								specializationSet.length > 0 && specializationSet.map((spec, index) => <option key={index}
																										   value={spec}>{spec}</option>)
							}
						</select>
					</Grid>
				</Grid>
			</Grid>
			
			{
				fieldsState.length > 0 && fieldsState.map((field) => (
					<QuestionnaireRangeItem
						key={field.id}
						field={field}
						radioHandler={radioHandler}
					/>
				))
			}
			{
				isValid && <QuizNextButton onClickHandler={QuoteHandler}/>
			}
		</Grid>
}

export default QuizQuestionnaire;