import {
	ADD_QUIZ_ACCESS_TOKEN,
	CHECK_ANSWER,
	CLEAR_QUIZ_STATE, GET_QUIZ_STATE, SHORT_CASE_GAMIFICATION,
} from '../types';


const handlers = {
	[GET_QUIZ_STATE]: (state, {payload}) => ({...state, ...payload}),
	[CLEAR_QUIZ_STATE]: (state, {payload}) => ({...payload}),
	[CHECK_ANSWER]: (state, {payload}) => ({...state, caseStep: {...state.caseStep, quiz: payload}}),
	[ADD_QUIZ_ACCESS_TOKEN]: (state, {payload}) => ({...state, accessToken: payload}),
	[SHORT_CASE_GAMIFICATION]: (state, {payload}) => ({...state, caseStep: payload}),
	DEFAULT: state => state,
}

export const quizReducer = (state, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT
	return handle(state, action)
}