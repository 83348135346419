import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { QuizContext } from '../../../../context/quiz/quizContext';
import QuizNextButton from '../../../shared/ui/buttons/QuizNextButton';
import SingleAnswerBox from '../../../shared/gamification/SingleAnswerBox';
import { validateString } from '../../../../utils/validateString';
import QuizCommentButton from "../../../shared/ui/buttons/QuizCommentButton";
import MultipleQuizModal from "../../../shared/modals/MultipleQuizModal";

const MultipleQuiz = () => {
    const {quizState, quizComplete, stepComplete} = useContext(QuizContext)
    const [drawArray, setDrawArray] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [startTimestamp, setStartTimeStamp] = useState(null);
    const [commentOpenIndex, setCommentOpenIndex] = useState(-1);

    useEffect(() => {
        setIsValid(drawArray.filter(question => question.answers.filter(answer => answer.checked).length > 0,
        ).length === drawArray.length)
    }, [drawArray])

    useEffect(() => {
        if (quizState.caseStep?.quiz?.questions?.length) {
            let tempArray = []
            quizState.caseStep.quiz.questions.forEach((question, index) => tempArray.push({
                ...question,
                answers: [...question.answers.map(el => ({
                    ...el,
                    checked: el.checked !== undefined ? el.checked : false,
                }))],
            }))
            setDrawArray(JSON.parse(JSON.stringify(tempArray)))
            setStartTimeStamp(Date.now())
        }
    }, [quizState])

    const changeAnswerHandler = (questionIndex, answerIndex) => {
        if (!drawArray[questionIndex].answers[answerIndex].checked) {
            setDrawArray(prev => JSON.parse(JSON.stringify(
                prev.map((question, index) =>
                    index !== questionIndex
                        ?
                        question
                        :
                        {
                            ...question,
                            answers: question.answers.map((answer, i) => ({...answer, checked: i === answerIndex})),
                        },
                ),
            )))
        }
    }

    const nextCaseHandler = () => {
        const isQuizNotFinish = quizState.caseStep.quiz.questions[0].answers[0].isCorrect === undefined

        isQuizNotFinish
            ?
            quizComplete({startTimestamp, endTimestamp: Date.now(), questions: drawArray})
            :
            stepComplete();

    };

    const openModalHandler = (index) => setCommentOpenIndex(index)
    const closeModalHandler = () => setCommentOpenIndex(-1)

    return (
        <>
            <Grid className={'multipleQuiz'}>
                {
                    drawArray.length > 0 && drawArray.map((question, questionIndex) => (
                        <React.Fragment key={questionIndex}>
                            <Grid className={'multipleQuiz__containerGrid'}>
                                <Grid className={'multipleQuiz__question'}>
                                    {question.desc && <div className={'quizContent__questionDescription'}
                                                           dangerouslySetInnerHTML={{__html: validateString(question.desc)}}/>}
                                    <div className={'quizContent__questionTitle'}
                                         dangerouslySetInnerHTML={{__html: validateString(question.question)}}/>
                                    {
                                        question.answers.map((answer, answerIndex) => (
                                            <SingleAnswerBox
                                                key={answerIndex}
                                                answer={answer}
                                                answerIndex={answerIndex}
                                                questionIndex={questionIndex}
                                                changeAnswerHandler={changeAnswerHandler}
                                            />
                                        ))
                                    }
                                    {
                                        question.correctMsg?.length > 0 && question.incorrectMsg?.length > 0
                                            ?
                                            <QuizCommentButton onClickHandler={() => openModalHandler(questionIndex)}/>
                                            :
                                            null
                                    }
                                </Grid>
                            </Grid>

                            {
                                questionIndex === commentOpenIndex && <MultipleQuizModal
                                    content={question.answers.find(answer => answer.isCorrect && answer.checked) ? question.correctMsg : question.incorrectMsg}
                                    closeModalHandler={closeModalHandler}
                                />
                            }

                        </React.Fragment>
                    ))
                }

                {
                    isValid && <QuizNextButton onClickHandler={nextCaseHandler}/>
                }
            </Grid>
        </>
    )
}

export default MultipleQuiz;