import React from 'react';
import {Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const QuizzesBox = ({color, text, flag}) => {
	let navigate = useNavigate();
	return (
		<Grid
			item
			className="quizzes__box"
			borderColor={`${color}`}
			onClick={() => {
				navigate(`/quizzes/${flag}`)
			}}
		>
			<Grid container height={'100%'} alignItems={'center'} justifyContent={'center'}>
				<p>{text}</p>
			</Grid>
		</Grid>
	)
}

export default QuizzesBox