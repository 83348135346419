import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {QuizContext} from '../../../context/quiz/quizContext';
import {Container, Grid} from '@mui/material';
import QuizInfo from './QuizInfo';
import QuizContent from './QuizContent';
import {AuthContext} from "../../../context/auth/authContext";

const Quiz = () => {
    const {loginToken} = useContext(AuthContext);
    const {getQuizInfo} = useContext(QuizContext);

    const params = useParams();

    useEffect(() => {
        getQuizInfo(params.id, params.accessToken ?? loginToken ?? null)
    }, [getQuizInfo, params.id, params.accessToken, loginToken])

    return (
        <Container maxWidth={'xl'}>
            <Grid
                container
                className={'quiz'}
                flexDirection={'row'}
                height={'100%'}
                maxWidth={'100vw'}
            >
                <QuizInfo/>
                <QuizContent/>
            </Grid>
        </Container>
    )
}

export default Quiz;