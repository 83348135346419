import React, { useContext } from 'react'
import { Container, Grid } from '@mui/material';
import logoSandoz from '../../assets/img/ui/sandozLogo.svg';
import { QuizContext } from '../../context/quiz/quizContext';
import { EventContext } from '../../context/event/eventContext';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const {quizState} = useContext(QuizContext);
  const {eventState} = useContext(EventContext);
  const location = useLocation()
  const isQuizzesPage = (/\/quizzes\/quiz\/[0-9]+/i).test(location.pathname)
  const isEventsPage = (/\/events\/[0-9]+/i).test(location.pathname)
  const isQuizzSignature = isQuizzesPage && quizState.details?.signature
  const isEventSignature = isEventsPage && eventState.signature
  return (
    <Grid component={'footer'} bgcolor={'#1B2936'} mt={2} maxWidth={'100vw'}>
      <Container maxWidth={'xl'}>
        <Grid
          container
          className="footerIn"
          height={100}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'nowrap'}
        >
          <Grid className={'footerColumnLeft'} item container flexGrow={1} display={'flex'} xs={4}
                alignItems={'center'}>
            <a href="https://www.sandoz-med.pl/" target={'_blank'} rel="noreferrer">
              <img
                src={logoSandoz}
                alt="logo Sandoz"
              />
            </a>
          </Grid>
          <Grid item className="footerText" display={{xs: 'none', md: 'block'}} ml={'20px'} xs={4}>
            <Grid container direction={'row'}
                  justifyContent={isQuizzSignature || isEventSignature ? 'flex-start' : 'flex-end'}
                  alignItems={'center'}>
              <a className="footerLink footerText"
                 href="https://drsim.pl/api/wp-content/uploads/2023/10/RegulaminDrSimOd10.2023.pdf"
                 target={'_blank'} rel="noreferrer">Regulamin</a>
              |
              <a className="footerLink footerText"
                 href="https://drsim.pl/api/wp-content/uploads/2023/11/Website-Privacy-Policy_2023_PL_final.pdf"
                 target={'_blank'} rel="noreferrer">Polityka prywatności</a>
            </Grid>
          </Grid>
          {
            isQuizzSignature &&
            <p className="footerSignature" style={{marginLeft: '20px'}}>{quizState.details.signature}</p>
          }
          {
            isEventSignature &&
            <p className="footerSignature" style={{marginLeft: '20px'}}>{eventState.signature}</p>
          }
        </Grid>
      </Container>
    </Grid>
  )
}

export default Footer;