import React from 'react'
import { Container, Grid } from '@mui/material';
import podcastImage from '../../../assets/img/ui/podcastsTitleIco.svg'
import PodcastPlayButton from './PodcastPlayButton';
import { timeConverter } from "../../../utils/timeConverter";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const PodcastHeader = ({
                         isSeries,
                         image,
                         title,
                         authors,
                         medicalAreas,
                         durationTime,
                         clickHandler
                       }) => {
  const {width} = useWindowDimensions()

  return (
    <Grid className={'podcastHeader'}>
      {width > 900 && <Container maxWidth="xl" height={'100%'}>
        <Grid container className={'podcastHeader__container'}>
          <Grid item xs={3}>
            <img className={'podcastHeader__image'} src={image} alt="avatar"/>
          </Grid>
          <Grid item xs={1}/>
          <Grid item xs={5}>
            <Grid container flexDirection={'column'} className={'podcastHeader__infoWrapper'}>
              {
                !isSeries &&
                <Grid className={'podcastHeader__infoWrapper-pageName'}>

                  <img src={podcastImage} alt="podcast ico"/>
                  <p>PODCAST</p>
                </Grid>
              }

              <Grid className={'podcastHeader__infoWrapper-title'}>
                <p>
                  {title}
                </p>
              </Grid>
              <Grid container flexDirection={'row'}
                    className={'podcastHeader__infoWrapper-authorsWrapper'}>
                <Grid container flexDirection={'row'} flexWrap={'nowrap'}>
                  <p style={{minWidth: '80px'}}>prowadzący:</p>
                  <Grid container flexDirection={'column'} flexGrow={1}>
                    {authors.map((author, index) => <p key={index}>{author.name}</p>)}
                  </Grid>
                </Grid>
                <Grid container flexDirection={'row'} flexWrap={'nowrap'}>
                  <p style={{minWidth: '80px'}}>obszar:</p>
                  <Grid container direction={'row'} flexGrow={1}>
                    {medicalAreas.map((medArea, index) => <span
                      key={index}>{medArea}{medicalAreas.length - 1 !== index && ','}&nbsp;</span>)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container flexDirection={'column'} className={'podcastHeader__infoWrapper'}>
              <Grid height={'45px'}/>
              <Grid height={'252px'} width={'100%'}
                    className={'podcastHeader__infoWrapper-buttonWrapper'}>
                {
                  !isSeries &&
                  <PodcastPlayButton
                    clickHandler={() => clickHandler(0)}
                  />
                }
              </Grid>
              <Grid height={'60px'} width={'100%'}
                    className={'podcastHeader__infoWrapper-durationWrapper'}>
                {
                  durationTime
                    ?
                    <p>CZAS TRWANIA: {timeConverter(durationTime)}</p>
                    :
                    <p>Już wkrótce</p>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>}
      {
        width < 900 &&
        <>
          <Grid container>
            <Container maxWidth={'md'}>
              <Grid className={'podcastHeader__infoWrapper-pageName'}>
                {
                  !isSeries &&
                  <>
                    <img src={podcastImage} alt="podcast ico"/>
                    <p>PODCAST</p>
                  </>
                }
              </Grid>
              <Grid className={'podcastHeader__infoWrapper-title'}>
                <p>
                  {title}
                </p>
              </Grid>
              <Grid item container alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <img className={'podcastHeader__image'} src={image} alt="avatar" width={'100%'}/>
                </Grid>
                <Grid item pt={{xs: 1, sm: 6}} xs={12} sm={6}>
                  <Grid height={'auto'} width={'100%'}
                        className={'podcastHeader__infoWrapper-buttonWrapper'}>
                    {
                      !isSeries &&
                      <PodcastPlayButton
                        clickHandler={() => clickHandler(0)}
                      />
                    }
                  </Grid>
                  <Grid height={'60px'} width={'100%'}
                        className={'podcastHeader__infoWrapper-durationWrapper'}>
                    {
                      durationTime
                        ?
                        <p>CZAS TRWANIA: {timeConverter(durationTime)}</p>
                        :
                        <p>Już wkrótce</p>
                    }
                  </Grid>
                </Grid>

              </Grid>
              <Grid flexDirection={{xs: 'column', md: 'row'}} pl={{xs: 0, sm: 10}} mb={2} mt={2}
                    className={'podcastHeader__infoWrapper-authorsWrapper'}>
                <Grid container flexDirection={'row'} flexWrap={'nowrap'}>
                  <p style={{minWidth: '80px'}}>prowadzący:</p>
                  <Grid container flexDirection={'column'} flexGrow={1} pl={{xs: 1, sm: 10}}>
                    {authors.map((author, index) => <p key={index}>{author.name}</p>)}
                  </Grid>
                </Grid>
                <Grid container flexDirection={'row'} flexWrap={'nowrap'}>
                  <p style={{minWidth: '80px'}}>obszar:</p>
                  <Grid container direction={'row'} flexGrow={1} pl={{xs: 1, sm: 10}}>
                    {medicalAreas.map((medArea, index) => <span
                      key={index}>{medArea}{medicalAreas.length - 1 !== index && ','}&nbsp;</span>)}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </>}
    </Grid>
  )
}

export default PodcastHeader;