import React from 'react';
import {useNavigate} from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';

export const LockedContentButton = () => {
    const navigate = useNavigate();

    const onClickHandler = (e) => {
        e.stopPropagation();
        navigate('/login');
    }

    return <button
        className={'lockedButton'}
        onClick={(e) => onClickHandler(e)}>
        <LockIcon/>
    </button>
}