import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ShapeContext } from '../../../context/shape/shapeContext';
import NuvButton from '../../shared/ui/buttons/NuvButton';
import { Container, Grid, Stack } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import { validateString } from '../../../utils/validateString';

const ShapeVideoPage = () => {
    const {
        shapeState: {shapeVideoInfo, video},
        getShapeVideoInfo,
        clearShapeVideoInfo,
        getShapeInfo,
        setIsViewed
    } = useContext(ShapeContext)

    const params = useParams()

    useEffect(() => {
        getShapeInfo()
        return () => {
            clearShapeVideoInfo()
        }

    }, [])

    useEffect(() => {
        if (params.id) {
            getShapeVideoInfo(params.id)
        }
        // eslint-disable-next-line
    }, [params.id, video])


    return (
        <Container maxWidth={'xl'}>
            <Grid container className={'variaVideoPage__header'}>
                <NuvButton flag={'return'} link={'/shape'} title={'POWRÓT'}/>
            </Grid>
            {
                shapeVideoInfo &&
                <>
                    <Grid container className={'variaVideoPage__content'}>
                        <Grid item xs={12} lg={6} p={{xs: 0, sm: 2}} container alignItems={'center'}>
                            <p className={'variaVideoPage__content-title'}>{shapeVideoInfo.title}</p>
                        </Grid>
                        <Grid item xs={12} lg={6} p={{xs: 0, sm: 2}}>
                            {
                                shapeVideoInfo.description && shapeVideoInfo.description.map((el, i) => <div key={i}
                                                                                                             className={'variaVideoPage__content-description'}
                                                                                                             dangerouslySetInnerHTML={{__html: validateString(el.content)}}/>)
                            }
                            <Stack direction={'row'}>
                                <p className={'variaVideoPage__content-authors'}>autor:</p>
                                <div className={'variaVideoPage__content-authorsContainer'}>
                                    {
                                        shapeVideoInfo.authors && shapeVideoInfo.authors.map((author, index) => <p
                                            className={'variaVideoPage__content-authors'}
                                            key={index}>{author.name}{index !== shapeVideoInfo.authors.length - 1 ? ', ' : ''}</p>)
                                    }
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Vimeo
                        className={'variaVideoPage__content-video'}
                        video={shapeVideoInfo.vimeoId}
                        autoplay
                        allowfullscreen
                        onEnd={() => setIsViewed(shapeVideoInfo.id)}
                    />
                    {
                        shapeVideoInfo.signature ?
                            <p className={'variaVideoPage__content-signature'}>{shapeVideoInfo.signature}</p> : null
                    }
                </>
            }
        </Container>
    )
}

export default ShapeVideoPage;