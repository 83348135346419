export default function validateInfo(values) {
    let errors = {};

    if (values.name.length > 26) {
        errors.name = 'Nieprawidłowa ilość znaków'
    }
    if (values.name.trim().length === 0) {
        errors.name = 'Podaj imię'
    }
    if (values.surname <2) {
        errors.surname = 'Nieprawidłowa ilość znaków'
    }
    if (!values.email) {
        errors.email = 'Email required';
    } else if (!emailRule.test(values.email)) {
        errors.email = 'Nieprawidłowy format adresu email';
    }
    if (!/^\d{7}$/.test(values.pwz)) {
        errors.pwz = 'Nieprawidłowy format PWZ'
    }
    if (!/([^\d]*\d){8}/.test(values.tel)) {
        errors.tel = 'Nieprawidłowy format numeru telefonu'
    }
    if (!passwordRule.test(values.password)) {
        errors.password = 'Hasło musi zawierać 8 znaków, w tym wielką literę oraz znak specjalny.'
    }
    if (!values.password) {
        errors.password = 'Hasło jest wymagane';
    } else if (values.password.length < 6) {
        errors.password = 'Hasło jest zbyt krótkie';
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = 'Password is required';
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Hasła nie są zgodne';
    }
    return errors;
}

export const passwordRule = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
export const emailRule = new RegExp('\\S+@\\S+\\.\\S+')
