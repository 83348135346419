import React from 'react';
import { Grid } from '@mui/material';
import { data } from './quizPesData'
import { useNavigate } from 'react-router-dom';


const QuizPesBox = ({quiz}) => {
    const navigate = useNavigate();
    return (
        <Grid
            item
            p={1}
            lg={4} sm={6} xs={12}
        >
            <Grid
                container
                className="quizPesBox"
                onClick={() => {
                    navigate(`/quizzes/quiz/${quiz.id}`);
                }}
                style={{background: data[quiz.id]?.background || '#303A60'}}
            >
                <Grid
                    container
                    height={'100%'}
                    flexDirection={'column'}
                >
                    <Grid item height={'100%'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          flexDirection={"column"}
                    >
                        {data[quiz.id]?.image && <img src={data[quiz.id].image} alt="icon"/>}
                        <p className="quizPesBox__title">- {quiz.title} -</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default QuizPesBox