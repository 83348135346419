import React from 'react';
import {Grid} from '@mui/material';
import { useBodyOverflow } from '../../../hooks/useBodyOverflow';
import CloseIcon from "@mui/icons-material/Close";

export const LightboxModal = ({closeImage, link}) => {
  useBodyOverflow()
    return (
        <div className={'customModal variaImageModal'}>
            <div className={'customModal__content variaImageModal__content'}>
              <CloseIcon className={'variaImageModal__content-button'} onClick={closeImage}/>
                {
                    link &&
                    <Grid width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                        <img
                            className={'variaImageModal__content-image'}
                            src={link}
                            alt="content"
                        />
                    </Grid>
                }
            </div>
        </div>
    )
}