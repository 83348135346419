import React from 'react';
import {Button} from '@mui/material';
import {validateString} from '../../../utils/validateString';
import { useBodyOverflow } from "../../../hooks/useBodyOverflow";

const MultipleQuizModal = ({content, closeModalHandler}) => {
	useBodyOverflow()
	return (
		<div className="customModal multipleQuizModal">
			<div className="customModal__content multipleQuizModal__content">
				<div className={'customModal__content-text'} dangerouslySetInnerHTML={{__html: validateString(content)}}/>
				<Button onClick={closeModalHandler} variant="contained" sx={{marginTop: '20px'}}>Powrót</Button>
			</div>
		</div>
	)
}

export default MultipleQuizModal;