import React, {useContext, useEffect, useState} from 'react'
import {Grid} from '@mui/material';
import {EventContext} from '../../../context/event/eventContext';
import HtmlSelector from '../../shared/pureHtml/HtmlSelector';

const PatientTabs = () => {
	
	const {eventState: {patientRecord: {tabs}}} = useContext(EventContext)
	const [openTabIndex, setOpenTabIndex] = useState(0)

	useEffect(() => {
	}, [openTabIndex])
	
	return tabs &&
		<Grid
			container
			className={'eventContentBorder event__tabs'}
			flexGrow={1}
			p={1}
			flexDirection={'column'}
			height={'fit-content'}
		>
			<Grid container className={'event__tabs-header'} justifyContent={'space-between'} alignItems={'center'}>
				{
					Object.entries(tabs).map(([key], index) => (
						<Grid
							item
							flexGrow={1}
							key={index}
							style={{
								borderBottom: openTabIndex === index ? '4px solid #00C2DF' : '4px solid #595959',
								color: openTabIndex === index ? '#00C2DF' : '',
							}}>
							<p
								onClick={() => setOpenTabIndex(index)}
							>{key}</p>
						</Grid>
					))
				}
			</Grid>
			<Grid container>
				{
					Object.entries(tabs)
					.filter(([key, value], index) => index === +openTabIndex)[0][1]
					.map((el, i) => <HtmlSelector element={el} key={i} tabs/>)
				}
			</Grid>
		</Grid>
}

export default PatientTabs;