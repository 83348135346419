export const  transformHTMLCodeByTags = (innerHTML) => {
  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = innerHTML;

  const topLevelElements = Array.from(tempContainer.children);

  const newElements = topLevelElements.map(element => {
    const elementType = element.tagName.toLowerCase();
    switch (elementType){
      case 'table': {

        // Dla każdej znalezionej tabeli, dodaj rodzicowi <div> z klasą 'quiz-table'
        const div = document.createElement('div');
        div.className = 'quiz-table';

        // Tworzenie rodzica dla tabeli i przeniesienie tabeli do tego rodzica
        const tableParent = element.parentElement;
        tableParent.replaceWith(div);
        div.appendChild(element);
        return div.outerHTML;
      }

      //Możliwość rozszerzenia kodu przetwarzania o inne nazwy tagów. Jeżeli nie to zwróc domyślnie element bez modyfikacji.
      default : {
        return element.outerHTML;
      }
    }
  });

  return newElements.join('');
}