import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../context/auth/authContext';
import { NavLink } from 'react-router-dom';
import { Box, Button, Grid, Stack } from '@mui/material';
import quizzesTitleIco from '../../../assets/img/ui/quizzesTitleIco.svg';
import eventsTitleIco from '../../../assets/img/ui/eventsTitleIco.svg';
import podcastsTitleIco from '../../../assets/img/ui/podcastsTitleIco.svg';
import variaTitlesIco from '../../../assets/img/ui/variaTitleIco.svg';
import finderIco from '../../../assets/img/ui/finderIco.svg'
import CustomStarSwitch from './buttons/CustomStarSwitch';
import CustomSandClockSwitch from './buttons/CustomSundClockSwitch';
import CustomCheckSwitch from './buttons/CustomCheckSwitch';
import CustomSelect from './selects/CustomSelect';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import rightIcon from '../../../assets/img/ui/menuIcons/rightIcon.svg';
import shapeImage from './../../../assets/img/ui/shapeImage.svg'

const PageTitles = ({
                        titleOptions, subTitleOption, flag, filtersMenu,
                        isNotStartedSelected, setIsNotStartedSelected,
                        isInProgressSelected, setIsInProgressSelected,
                        isFinishedSelected, setIsFinishedSelected,
                        specialization, specializationsSelectSet, setSpecialization,
                        finderValue, setFinderValue, type,
                    }) => {

    const {isLoggedIn} = useContext(AuthContext)
    const {width} = useWindowDimensions();
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Box width={'100%'} display={'flex'} alignItems={'center'} flexDirection={'column'} maxWidth={'100vw'}
             className={'pageTitle'}>
            {titleOptions === 'shape' && <div><img className={'pageTitle__shapeImage'} src={shapeImage} alt=""/></div>}
            <Grid
                item
                container
                mb={{xs: !filtersMenu ? '20px' : 0, lg: '50px'}}
                borderBottom={'1px solid white'}
                alignItems={'center'}
                justifyContent={'space-between'}
                p={'10px 0'}
                flexWrap={'nowrap'}
            >
                <Stack direction={'row'} alignItems={'center'} mr={'10px'}>
                    <img
                        src={titleOptions === 'quizzes'
                            ? quizzesTitleIco : titleOptions === 'events' || titleOptions === 'shape'
                                ? eventsTitleIco : titleOptions === 'varia'
                                    ? variaTitlesIco : podcastsTitleIco}
                        alt={`${titleOptions === 'quizzes' ? 'quiz' : titleOptions === 'events' || titleOptions === 'shape' ? 'events' : titleOptions === 'varia' ? 'varia' : 'podcasts'} title ico`}
                    />
                    <p
                        className="newestTitle__text">
                        {type ? 'Moje ' : !flag ? '' : (!filtersMenu && 'Najnowsze ')}
                        <span>{titleOptions === 'quizzes' ? 'QUIZY' : titleOptions === 'events' ? 'PRZYPADKI' : titleOptions === 'varia' ? 'VARIA' : titleOptions === 'shape' ? 'SHAPE' : 'PODCASTY'} {subTitleOption && subTitleOption === 'fast-fives' ? '/ SZYBKA PIĄTKA' : subTitleOption === 'challenges' ? '/ WYZWANIA' : subTitleOption === 'what-went-wrong' ? '/ Co poszło nie tak?' : subTitleOption === 'pictorial-curiosities' ? '/ Ciekawostki obrazowe' : subTitleOption === 'before-exam' ? '/ Powtórka przed PES' : null}</span>
                    </p>
                </Stack>
                {
                    filtersMenu &&
                    <Grid
                        // item
                        flexGrow={1}
                        container
                        flexDirection={'row'}
                        flexWrap={'nowrap'}
                        position={'relative'}

                    >
                        <Grid
                            className={'pageTitle__box'}
                            container
                            flexWrap={'nowrap'}
                        >
                            <Grid
                                className={`pageTitle__leftSide`}
                                container
                                flexWrap={'nowrap'}
                                justifyContent={'flex-end'}
                                display={{
                                    xs: 'none',
                                    lg: 'flex',
                                }}
                            >
                                {
                                    !!specialization &&
                                    <Grid item display={{xs: 'none', lg: 'flex'}}>
                                        <CustomSelect
                                            value={specialization}
                                            handler={setSpecialization}
                                            selectSet={specializationsSelectSet}
                                        />
                                    </Grid>
                                }
                                {
                                    isLoggedIn &&
                                    <>
                                        {/*nierozpoczete*/}
                                        {!type &&

                                            <Grid item>
                                                <CustomStarSwitch
                                                    value={isNotStartedSelected}
                                                    handler={setIsNotStartedSelected}
                                                />
                                            </Grid>}
                                        {/*ukonczone*/}
                                        <Grid item>
                                            <CustomCheckSwitch
                                                value={isFinishedSelected}
                                                handler={setIsFinishedSelected}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>

                            <Grid
                                className={`pageTitle__rightSide`}
                                sx={{width: '100%'}}
                                container
                                justifyContent={'flex-end'}
                            >
                                <input
                                    type="text"
                                    className={'pageTitle__input'}
                                    value={finderValue}
                                    onChange={(e) => setFinderValue(e.target.value)}
                                    placeholder={'Wyszukaj...'}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            className={'pageTitle__icon'}
                            container
                            alignItems={'center'}
                            sx={{width: '18px', height: '18px'}}
                        >
                            <img
                                src={finderIco}
                                alt="finder"
                                style={{cursor: 'pointer'}}
                            />
                        </Grid>
                    </Grid>
                }
                {
                    flag &&
                    <NavLink
                        className="newestTitle__link"
                        to={`${titleOptions === 'quizzes' ? '/quizzes/variant' : titleOptions === 'events' ? '/events' : '/podcasts'}`}

                    >
                        <div>
                            <img src={rightIcon} alt={'link'}/>
                        </div>
                    </NavLink>
                }
            </Grid>
            {
                width < 1200 && filtersMenu &&
                <>
                  {
                    isLoggedIn ?
                      <Grid container mb={{xs: '20px', sm: '50px', md: '50px', lg: '0'}} alignItems={'center'}
                            flexDirection={'column'}
                            maxWidth={{md: '80%'}} mt={2}
                      >
                        <Button
                          variant={'contained'}
                          aria-expanded={isOpen ? 'true' : undefined}
                          onClick={() => {
                            setIsOpen(!isOpen)
                          }}>{isOpen ? 'Schowaj filtry' : 'Pokaż filtry'}</Button>
                        {isOpen &&
                          <Grid sx={{
                            background: '#1B2936',
                            borderRadius: '2%'
                          }} mt={2} pt={1} paddingLeft={ '10px'} paddingRight={{xs: '10px'}} width={'100%'} display={'flex'} flexDirection={'column'}
                                alignItems={'flex-start'} justifyContent={'space-around'}>
                            {!!specialization && (<Grid item display={{md: 'flex', lg: 'none'}} width={'100%'}>
                              <CustomSelect
                                value={specialization}
                                handler={setSpecialization}
                                selectSet={specializationsSelectSet}
                              />
                            </Grid>)}
                            {/*nierozpoczete*/}
                            {!type &&

                              <Grid item display={{md: 'flex', lg: 'none'}}>
                                <CustomStarSwitch
                                  value={isNotStartedSelected}
                                  handler={setIsNotStartedSelected}
                                />
                              </Grid>}
                            {/*w trakcie*/}
                            {
                              titleOptions !== 'varia' &&
                              <Grid item display={{md: 'flex', lg: 'none'}}>
                                <CustomSandClockSwitch
                                  value={isInProgressSelected}
                                  handler={setIsInProgressSelected}
                                />
                              </Grid>
                            }
                            {/*ukonczone*/}
                            <Grid item display={{md: 'flex', lg: 'none'}}>
                              <CustomCheckSwitch
                                value={isFinishedSelected}
                                handler={setIsFinishedSelected}
                              />
                            </Grid>
                          </Grid>
                        }
                      </Grid>: <>
                      {!!specialization && (<Grid className={'pageTitle__selectFull'} item width={'100%'} mt={2} mb={2} display={{md: 'flex', lg: 'none'}}>
                        <CustomSelect
                          value={specialization}
                          handler={setSpecialization}
                          selectSet={specializationsSelectSet}
                        />
                        </Grid>)}
                      </>
                  }
                </>
            }
        </Box>

    )
}

export default PageTitles