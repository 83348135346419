import React, {useContext} from 'react';
import {Grid} from "@mui/material";
import pdfFileIco from '../../../../assets/img/ui/pdfFileIco.svg';
import txtFileIco from '../../../../assets/img/ui/textMaterialIco.svg';
import downloadButton from '../../../../assets/img/ui/buttons/carbon_download.svg';
import {AuthContext} from '../../../../context/auth/authContext';
import {NavLink} from 'react-router-dom';

const VariaTextBox = ({element}) => {
    const {isLoggedIn} = useContext(AuthContext);

    return (
        <Grid className={'variaTextBox'}>
            <Grid className={'variaTextBox__fileIco'} >
                <img
                    src={element.type === 'publication' ? pdfFileIco : txtFileIco}
                    alt="file icon"
                />
            </Grid>
         <Grid container display={'flex'} className={'variaTextBox__grid'}>
            <Grid className={'variaTextBox__title'} >
                <p>{element.title}</p>
            </Grid>
            <Grid className={'variaTextBox__author'} >
                {
                    element.authors.map((author, i) => <p key={i}>{author.name}</p>)
                }
            </Grid>
         </Grid>
        
            <Grid className={'variaTextBox__downloadButton'}>
                {
                    isLoggedIn
                    ?
                    <a href={element.src} target={'_blank'} rel="noreferrer">
                        <img
                            src={downloadButton}
                            alt="file icon"
                        />
                    </a>
                    :
                    <NavLink to={'/login'}>
                        <img
                            src={downloadButton}
                            alt="file icon"
                        />
                    </NavLink>
                }
            </Grid>
        </Grid>
    )
}

export default VariaTextBox;