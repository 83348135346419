import {ADD_EVENT_ACCESS_TOKEN, CHECK_EVENT_ANSWER, CLEAR_EVENT_STATE, GET_CURRENT_EVENT_INFO} from '../types';

const handlers = {
    [GET_CURRENT_EVENT_INFO]: (state, {payload}) => ({...state, ...payload}),
    [ADD_EVENT_ACCESS_TOKEN]: (state, {payload}) => ({...state, accessToken: payload}),
    [CHECK_EVENT_ANSWER]: (state, {payload}) => ({...state, caseStep: {...state.caseStep, quiz: payload}}),
    [CLEAR_EVENT_STATE]: (state, {payload}) => ({...payload}),
    DEFAULT: state => state,
}

export const eventReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}