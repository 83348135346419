import React from 'react';
import HeaderLink from '../components/shared/HeaderLink';
import logoutButton from '../assets/img/ui/menuIcons/logoutButton.svg'
import quizzesIco from '../assets/img/ui/menuIcons/quizes.svg'
import podcastsIco from '../assets/img/ui/menuIcons/podcasts.svg'
import variasIco from '../assets/img/ui/menuIcons/varias.svg'
import profileIco from '../assets/img/ui/menuIcons/profile.svg'
import home from '../assets/img/ui/menuIcons/home.svg'
import shape from '../assets/img/ui/menuIcons/shape.svg'

const UserLinks = (isLoggedIn) => {
	return (
		<>
			<HeaderLink link="/" title="home" img={home} alt='home menu button'/>
			<HeaderLink link="/quizzes/variant" title="QUIZY" img={quizzesIco} alt='quiz menu button'/>
			<HeaderLink link="/events" title="PRZYPADKI" img={shape} alt='events menu button'/>
			<HeaderLink link="/podcasts" title="PODCASTY" img={podcastsIco} alt='podcasts menu button'/>
			<HeaderLink link="/shape" title="SHAPE" img={shape} alt='shape menu button'/>
			<HeaderLink link="/varia" title="VARIA" img={variasIco} alt='varia menu button'/>
			{
				isLoggedIn
					?
					<HeaderLink link="/profile" title="PROFIL" img={profileIco} alt='profile menu button'/>
					:
					<HeaderLink link="/login" title="LOGIN" img={profileIco} alt='login menu button'/>
			}
			{
				isLoggedIn && <HeaderLink link="/logout" title={'WYLOGUJ'} img={logoutButton} alt='logout menu button'/>
			}
		</>
	)
}

export default UserLinks;