import React from 'react';
import {Button} from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';

const VideoModal = ({vimeoId, closeModalHandler}) => {
	return (
		<div className={'customModal videoModal'}>
			<div className={'customModal__content videoModal__content'}>
				<Vimeo
					className={'videoModal__content-videoWrapper'}
					video={vimeoId}
					allowfullscreen
					transparent={true}
				/>
				<Button onClick={closeModalHandler} variant='contained' sx={{marginTop: '20px'}}>Powrót</Button>
			</div>
		</div>
	)
}

export default VideoModal;