import React, { useContext, useEffect, useState } from 'react';
import { MainPageContext } from '../../../context/mainPage/mainPageContext';
import { Container, Grid } from '@mui/material';
import PageTitles from '../../shared/ui/PageTitles';
import { MaterialUIPagination } from '../../shared/ui/MaterialUIPagination';
import QuizBox from '../../shared/ui/boxes/QuizBox';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Quizzes = ({flag, type}) => {

    const {
        getSpecializationsSet,
        mainPageState: {specializations, quizzes},
        getPageInfo,
        clearQuizzes,
    } = useContext(MainPageContext);
    const [isNotStartedSelected, setIsNotStartedSelected] = useState(true);
    const [isInProgressSelected, setIsInProgressSelected] = useState(true);
    const [isFinishedSelected, setIsFinishedSelected] = useState(true);
    const [specialization, setSpecialization] = useState('Obszar terapeutyczny');
    const [specializationsSelectSet, setSpecializationsSelectSet] = useState([]);
    const [finderValue, setFinderValue] = useState('')
    const [drawArray, setDrawArray] = useState([]);
    const [pageDrawArray, setPageDrawArray] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1)
    const {width} = useWindowDimensions();
    const cardsPerPage =
        type && width < 1200 ? 2 : type && width > 1489 ? 3 : !type && width > 1200 ? 12 : !type && width < 1200 ? 12 : 4

    useEffect(() => {
        getSpecializationsSet('sfwd-courses')
        getPageInfo(flag)
        return () => {
            clearQuizzes();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (quizzes) {
            setCurrentPage(1);
            let tempDrawArray = [...quizzes];
            if (!isNotStartedSelected || type) {
                tempDrawArray = [...tempDrawArray.filter(event => event.details.status.text !== 'notEnrolled')]
            }
            if (!isInProgressSelected) {
                tempDrawArray = [...tempDrawArray.filter(event => event.details.status.text !== 'started')]
            }
            if (!isFinishedSelected) {
                tempDrawArray = [...tempDrawArray.filter(event => event.details.status.text !== 'completed')]
            }
            if (specialization !== 'Obszar terapeutyczny') {
                tempDrawArray = [...tempDrawArray.filter(event => !!event.details.medicalAreas.find(el => el === specialization))]
            }
            if (finderValue.trim().length) {
                tempDrawArray = [...tempDrawArray.filter(
                    event => event.title.toLowerCase().includes(finderValue.toLowerCase())
                        || (event.details.authors.length && event.details.authors.filter(author => author.toLowerCase().includes(finderValue.toLowerCase())).length)
                )]
            }
            setDrawArray([...tempDrawArray]);

            setPageCount(Math.ceil(tempDrawArray.length / cardsPerPage))
        }
    }, [quizzes, isNotStartedSelected, isInProgressSelected, isFinishedSelected, specialization, finderValue, type, cardsPerPage])

    useEffect(() => {
        if (specializations) {
            setSpecializationsSelectSet([...specializations])
        }
    }, [specializations])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <>
            <Container maxWidth="xl">
                <Grid container>
                    <PageTitles
                        titleOptions="quizzes"
                        subTitleOption={flag}
                        type={type}
                        flag={false} filtersMenu={true}
                        isNotStartedSelected={isNotStartedSelected}
                        setIsNotStartedSelected={setIsNotStartedSelected}
                        isInProgressSelected={isInProgressSelected}
                        setIsInProgressSelected={setIsInProgressSelected}
                        isFinishedSelected={isFinishedSelected}
                        setIsFinishedSelected={setIsFinishedSelected}
                        specialization={specialization}
                        setSpecialization={setSpecialization}
                        specializationsSelectSet={specializationsSelectSet}
                        finderValue={finderValue} setFinderValue={setFinderValue}
                    />
                    <Grid item xs={12}>
                        {
                            !!drawArray.length &&
                                <>
                                    <Grid container
                                          justifyContent={{xs: 'center', lg: 'flex-start'}}
                                          m={{md: '0 auto'}}
                                          maxWidth={{md: '1000px', lg: '1900px'}}
                                    >
                                        {
                                            !!pageDrawArray.length && pageDrawArray.map(quiz => <QuizBox quiz={quiz}
                                                                                                       key={quiz.id}/>)
                                        }
                                    </Grid>
                                    <Grid container justifyContent={'flex-end'} pt={2} pb={2}>
                                        <MaterialUIPagination
                                            count={pageCount}
                                            page={currentPage}
                                            onChange={(e, value) => {
                                                setCurrentPage(value);
                                                window.scrollTo({top: 0, behavior: 'smooth'});
                                            }}
                                            size={'large'}
                                        />
                                    </Grid>
                                </>
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Quizzes;