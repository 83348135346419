import React from 'react'

const EventNextButton = ({onClickHandler}) => {
	return (
		<button
			onClick={onClickHandler}
			className={'eventNextButton'}
		>
			DALEJ
		</button>
	)
}

export default EventNextButton;

