import React, {useContext} from 'react';
import {EventContext} from '../../../context/event/eventContext';
import {Grid} from '@mui/material';
import VideoVsButtonsSlide from './slides/VideoVsButtonsSlide';
import TextSlide from './slides/TextSlide';
import QuizzesSlide from './slides/QuizzesSlide';
import SummaryVideo from './slides/SummaryVideo';
import NoContent from '../../shared/ui/NoContent';
import VideoWithCheck from './slides/VideoWithCheckSlide';
import VideoSlide from './slides/VideoSlide';
import VideoVsVideoSlide from "./slides/VideoVsVideoSlide";

const EventContent = ({showButtonHandler, setQuizData}) => {
	const {eventState: {caseStep}} = useContext(EventContext);

	let draw;

	switch (caseStep.model) {
		case 'withText':
			draw = <TextSlide content={caseStep.content} showButtonHandler={showButtonHandler}/>
			break
		case 'interviewVideo':
			if (caseStep.interviewModel === 'buttons') {
				draw = <VideoVsButtonsSlide showButtonHandler={showButtonHandler}/>
				break;
			} else if (caseStep.interviewModel === 'checklist') {
				draw = <VideoWithCheck showButtonHandler={showButtonHandler}/>
				break;
			} else if (caseStep.interviewModel === 'movie') {
				draw = <VideoSlide showButtonHandler={showButtonHandler}/>
				break;
			} else if (caseStep.interviewModel === 'buttons_with_comments') {
				draw = <VideoVsVideoSlide showButtonHandler={showButtonHandler}/>
			}
			break;
		case 'withQuiz':
			draw = <QuizzesSlide showButtonHandler={showButtonHandler} setQuizData={setQuizData}/>
			break
		case 'summaryVideo':
			draw = <SummaryVideo showButtonHandler={showButtonHandler}/>
			break
		default:
			return draw = <NoContent />
	}
	
	return (
		<Grid className={'scroll'} sx={{height: '100%', overflowY: 'auto'}} maxWidth={'100vw'} maxHeight={{xs: 'none', lg: `calc(100vh - 498px)`}}>
			{draw}
		</Grid>
	)
}

export default EventContent