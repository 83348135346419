import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { EventContext } from '../../../../context/event/eventContext';
import { QuizContext } from '../../../../context/quiz/quizContext';

const NuvButton = ({flag, link, title, caseId, quizId, pdfLink, closeModalHandler}) => {
    const {resetCase} = useContext(EventContext)
    const {resetQuiz, setCurrentQuizIndex} = useContext(QuizContext)

    switch (flag) {
        case 'return':
            return (
                <NavLink className={'returnButton'} to={link}>
                    <span className={'returnButton__img'}/>
                    <span className={'returnButton__title'}>{title}</span>
                </NavLink>
            )
        case 'refresh':
            return (
                <div className={'refreshButton'} onClick={() => {
                    if (caseId) {
                        resetCase(caseId)
                    }
                    if (quizId) {
                        resetQuiz()
                        setCurrentQuizIndex(0)
                    }
                }}>
                    <span className={'refreshButton__img'}/>
                    <span className={'refreshButton__title'}>{title}</span>
                </div>
            )
        case 'pdf':
            return (
                <a className={'pdfButton'} target={'_blank'} href={pdfLink} rel="noreferrer">
                    <span className={'pdfButton__img'}/>
                    <span className={'pdfButton__title'}>{title}</span>
                </a>
            )
        case 'lastSLide':
            return (
                <div className={'lastSlideButton'} onClick={closeModalHandler}>
                    <span className={'lastSlideButton__img'}/>
                    <span className={'lastSlideButton__title'}>{title}</span>
                </div>
            )
        default:
            return <span className={'refreshButton__title'}>error {flag}</span>
    }
}

export default NuvButton;