import React, {useContext, useEffect} from 'react';
import {EventContext} from '../../../../context/event/eventContext';
import {Grid} from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import {useParams} from 'react-router-dom';

const SummaryVideo = ({showButtonHandler}) => {
	const {eventState: {caseStep}, confirmSlide} = useContext(EventContext);
	const params = useParams()
	useEffect(()=>{
		showButtonHandler({type: null, show: false});
	}, [])
	return (
		<Grid
			container
			className={'summaryVideo'}
			height={'100%'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{
				caseStep.video &&
				<Vimeo
					className={'summaryVideo__videoWrapper'}
					video={caseStep.video}
					autoplay
					allowfullscreen
					transparent={true}
					onEnd={() => {
						confirmSlide(caseStep.id, params.id)
					}}
				/>
			}
		</Grid>
	)
}

export default SummaryVideo;