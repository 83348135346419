import React from 'react';
import {Grid} from '@mui/material';
import StatusVideoButton from "../../shared/ui/buttons/StatusVideoButton";
import PodcastPlayButton from './PodcastPlayButton';
import {timeConverter} from '../../../utils/timeConverter';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {LockedContentButton} from "../../shared/ui/buttons/LockedContentButton";

const PodcastPart = ({part, index, currentDescriptionIndex, clickHandler}) => {
    const {width} = useWindowDimensions();
    
    return (
        <Grid
            container
            className={`podcastPart ${index === currentDescriptionIndex && 'podcastPart__active'}`}
            flexWrap={'nowrap'}
            gap={'3px'}
        >
            <StatusVideoButton status={part.status}/>
            <p className={'podcastPart__number'}>{index + 1}.</p>
            <Grid item  flexGrow={1} height={'100%'}>
                <Grid container flexDirection={'column'} justifyContent={'space-between'} height={'100%'} minHeight={{xs: 'unset', sm: '110px'}}>
                    <p className={'podcastPart__title'}>{part.episodeTitle}</p>
                    {width > 600 &&
                      <div className={'podcastPart__authors'}><p>PROWADZĄCY:</p><div>{part.episodeAuthors.map((author, index) =>
                        <p
                            key={index}>{author.episodeAuthorName}{part.episodeAuthors.length - 1 !== index && ','}</p>)}</div></div>}
                </Grid>
            </Grid>
            <Grid item height={'100%'} flexShrink={0} className={'podcastPart__playContainer'} >
                <Grid
                    container
                    height={'100%'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    alignSelf={'center'}
                    minHeight={{xs: 'unset', sm: '110px'}}
                >
                    {
                        part.isLocked
                            ? <LockedContentButton/>
                            : <PodcastPlayButton clickHandler={clickHandler}
                            />
                    }
                    <p className={'podcastPart__durationTime'}>CZAS TRWANIA: {timeConverter(part.episodeDuration)}</p>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PodcastPart;