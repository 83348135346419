import React, {useContext, useState} from 'react';
import {
	Button,
	// Checkbox,
	FormControl,
	// FormControlLabel,
	Grid,
	// IconButton,
	// InputAdornment,
	InputLabel,
	OutlinedInput,
	Stack,
} from '@mui/material';
import {AuthContext} from '../../../context/auth/authContext';
// import {Visibility, VisibilityOff} from '@mui/icons-material';
// import {NavLink} from 'react-router-dom';

const ResetPassword = () => {
	
	const {resetPassword} = useContext(AuthContext);
	
	const [userData, setUserdata] = useState('')
	
	const submitHandler = (e) => {
		e.preventDefault()
		resetPassword(userData)
	}
	
	return (
		<Grid
			container
			flex
			alignItems={'center'}
			justifyContent={'center'}
			pt={4}
			pb={4}
		>
			<Grid
				width={{xs: '90%', md: '600px', lg: '900px'}}
				sx={{
					padding: '20px',
					borderRadius: '6px',
					boxShadow: '0 0 10px rgba(0, 0, 0, .5)',
					backgroundColor: '#fff',
				}}
				container
			>
				<Grid component={'form'} onSubmit={(e) => submitHandler(e)} item xs={12}>
					<Stack direction={'column'} alignItems={'center'}>
						<h3 className="auth__title">
							Zmiana hasła
						</h3>
						<p className="auth__text" style={{marginBottom: '30px', textAlign: 'center'}}>
							Nie pamiętasz hasła? Wprowadź poniżej swój adres e-mail, a my dostarczymy na niego dalsze instrukcje ustawienia nowego hasła.
						</p>
						<Stack direction={'column'} width={'100%'} mb={3}>
							<FormControl required fullWidth>
								<InputLabel htmlFor="email">Numer PWZ lub Email</InputLabel>
								<OutlinedInput
									id="email"
									label="Numer PWZ lub Email"
									placeholder="enter PWZ lub Email"
									value={userData}
									onChange={(e) => setUserdata(e.target.value)}
								/>
							</FormControl>
						</Stack>
						<Stack direction={'row'} justifyContent={'flex-end'} mt={1} mb={2}>
							<Button
								type='submit'
								variant='contained'
								style={{textTransform: 'capitalize'}}
							>
								Zmień hasło
							</Button>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ResetPassword