import React from 'react'
import {Button} from '@mui/material';
import HtmlSelector from '../pureHtml/HtmlSelector';
import { useBodyOverflow } from '../../../hooks/useBodyOverflow';

const CommentModal = ({content, closeModalHandler}) => {
	useBodyOverflow()
	return (
		<div className={'customModal commentModal'}>
			<div className={'customModal__content commentModal__content'}>
				<h3>{content.title}</h3>
				{
					content.content.map((el, index)=> <HtmlSelector element={el} key={index} tabs/>)
				}
				<Button onClick={closeModalHandler} variant='contained' sx={{marginTop: '20px'}}>Powrót</Button>
			</div>
		</div>
	)
}

export default CommentModal;