import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { QuizContext } from '../../../context/quiz/quizContext';
import MultipleQuiz from './slides/MultipleQuiz';
import VideoQuiz from './slides/VideoQuiz';
import QuizQuestionnaire from './slides/QuizQuestionnaire';
import TheoryQuiz from './slides/TheoryQuiz';
import StartQuiz from './slides/StartQuiz';
import PesResults from './slides/PesResults';
import SingleQuestionQuiz from './slides/SingleQuestionQuiz';
import NoContent from '../../shared/ui/NoContent';

const QuizContent = () => {

    const {quizState} = useContext(QuizContext);
    const [draw, setDraw] = useState(null);


    useEffect(() => {
        if (quizState.caseStep?.model) {
            switch (quizState.caseStep?.model) {
                case 'withQuizWithoutAnswers':
                    setDraw(<MultipleQuiz/>)
                    break;
                case 'withQuiz':
                    setDraw(<MultipleQuiz/>)
                    break;
                case 'withVideo':
                    setDraw(<VideoQuiz/>)
                    break;
                case 'withQuestionnaire':
                    setDraw(<QuizQuestionnaire/>)
                    break;
                case 'withTheory':
                    setDraw(<TheoryQuiz/>)
                    break;
                case 'withBeforeExamResults':
                    setDraw(<PesResults/>)
                    break;
                case 'withStartQuiz':
                    setDraw(<StartQuiz/>)
                    break;
                case 'withText':
                    setDraw(<TheoryQuiz withText/>)
                    break;
                case 'withQuizSingleQuestions':
                    setDraw(<SingleQuestionQuiz/>)
                    break;
                case 'withQuizExamQuestions':
                    setDraw(<SingleQuestionQuiz/>)
                    break;
                case 'withQuizSingleDescribedQuestions':
                    setDraw(<SingleQuestionQuiz singleDescription/>)
                    break;
                default:
                    setDraw(<NoContent/>)
            }
        } else {
            setDraw(<NoContent/>)
        }

    }, [quizState])

    return (
        <Grid item xs={12} lg={9} className={'quizContent'} pt={{xs: '12px', md: '0'}}>
            {draw}
        </Grid>
    )
}

export default QuizContent;