import React, {useContext, useState} from 'react'
import {QuizContext} from '../../../../context/quiz/quizContext';
import {Grid} from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import QuizNextButton from '../../../shared/ui/buttons/QuizNextButton';

const VideoQuiz = () => {
	const {quizState, stepComplete} = useContext(QuizContext);

	const [isVideoViewed, setIsVideoViewed] = useState(false)

	return (
		<Grid className={'videoQuiz'}>

			{
				quizState.caseStep?.video &&
				<>
				<Grid className={'videoQuiz__containerGrid'}>
				<Vimeo
					className={'videoQuiz__vimeoVideo'}
					video={quizState.caseStep.video}
					autoplay
					allowfullscreen
					onEnd={() => {
						setIsVideoViewed(true)
					}}
				/>
				</Grid>
			{
				isVideoViewed && <QuizNextButton onClickHandler={stepComplete}/>
			}
				</>
			}


		</Grid>
	)
}

export default VideoQuiz;