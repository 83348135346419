//POI TYPES
export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
export const UNSET_GLOBAL_LOADER = 'UNSET_GLOBAL_LOADER';
export const SET_MODAL_ERROR = 'SET_MODAL_ERROR';
export const UNSET_MODAL_ERROR = 'UNSET_MODAL_ERROR';

//AUTH TYPES
export const ADD_LOGIN_TOKEN = 'ADD_LOGIN_TOKEN';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USER_SPECIALIZATIONS = 'GET_USER_SPECIALIZATIONS';
export const GET_FORMSET = 'GET_FORMSET';
export const IS_USER_LOAD = 'IS_USER_LOAD';
export const SAVE_NAVIGATION_LINK = 'SAVE_NAVIGATION_LINK';

//MAIN PAGE TYPES
export const GET_SPECIALIZATIONS = 'GET_SPECIALIZATIONS';
export const GET_MAIN_PAGE_INFO = 'GET_MAIN_PAGE_INFO';
export const GET_PROFILE_PAGE_INFO = 'GET_PROFILE_PAGE_INFO';
export const CLEAR_MAIN_PAGE_INFO = 'CLEAR_MAIN_PAGE_INFO';

//EVENTS PAGE TYPES
export const GET_EVENTS = 'GET_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';

//EVENT PAGE TYPES
export const GET_CURRENT_EVENT_INFO = 'GET_CURRENT_EVENT_INFO';
export const CLEAR_EVENT_STATE = 'CLEAR_EVENT_STATE';
export const CHECK_EVENT_ANSWER = 'CHECK_EVENT_ANSWER';
export const ADD_EVENT_ACCESS_TOKEN = 'ADD_EVENT_ACCESS_TOKEN';

//QUIZZES PAGE TYPES
export const GET_QUIZZES = 'GET_QUIZZES';
export const CLEAR_QUIZZES = 'CLEAR_QUIZZES';

//PODCASTS PAGE TYPES
export const GET_PODCASTS = 'GET_PODCASTS';
export const CLEAR_PODCASTS = 'CLEAR_PODCASTS';

//VARIA PAGE TYPES
export const GET_VARIA_INFO = 'GET_VARIA_INFO';
export const GET_VARIA_VIDEO_INFO = 'GET_VARIA_VIDEO_INFO';
export const CLEAR_VARIA_STATE = 'CLEAR_VARIA_STATE';
export const CLEAR_VARIA_VIDEO_INFO = 'CLEAR_VARIA_VIDEO_INFO';

//SHAPE PAGE TYPES
export const GET_SHAPE_INFO = 'GET_SHAPE_INFO';
export const GET_SHAPE_VIDEO_INFO = 'GET_SHAPE_VIDEO_INFO';
export const CLEAR_SHAPE_STATE = 'CLEAR_SHAPE_STATE';
export const CLEAR_SHAPE_VIDEO_INFO = 'CLEAR_SHAPE_VIDEO_INFO';

//PODCASTS PAGE TYPES
export const GET_CURRENT_PODCAST_INFO = 'GET_CURRENT_PODCAST_INFO';
export const CLEAR_PODCASTS_STATE = 'CLEAR_PODCASTS_STATE';
export const ADD_PODCAST_ACCESS_TOKEN = 'ADD_PODCAST_ACCESS_TOKEN';

//QUIZ PAGE TYPES
export const GET_QUIZ_STATE ='GET_QUIZ_STATE';
export const CLEAR_QUIZ_STATE ='CLEAR_QUIZ_STATE';
export const CHECK_ANSWER = 'CHECK_ANSWER';
export const SHORT_CASE_GAMIFICATION = 'SHORT_CASE_GAMIFICATION';
export const ADD_QUIZ_ACCESS_TOKEN = 'ADD_QUIZ_ACCESS_TOKEN';