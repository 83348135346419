import React, {useContext, useEffect, useState} from 'react'
import {Grid} from '@mui/material';
import {EventContext} from '../../../../context/event/eventContext';
import Vimeo from '@u-wave/react-vimeo';
import EventVideoBox from '../../../shared/ui/boxes/EventVideoBox';
import HtmlSelector from '../../../shared/pureHtml/HtmlSelector';

const VideoWithCheck = ({showButtonHandler}) => {
	const {eventState: {caseStep}} = useContext(EventContext);
	const [videoToShow, setVideoToShow] = useState(null);
	const [commentToShow, setCommentToShow] = useState(null);
	const [videoMenuToShow, setVideoMenuToShow] = useState(null);
	const [isFirstVideoEnd, setIsFirstVideoEnd] = useState(false);
	const [videoListToShow, setVideoListToShow] = useState([]);
	const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
	const [protoVideoList, setProtoVideoList] = useState([]);
	
	
	useEffect(() => {
		if (videoListToShow.length && currentVideoIndex === videoListToShow.length - 1 && !videoToShow) {
			showButtonHandler({type: 'slide', show: true});
		}
	}, [currentVideoIndex, videoListToShow, commentToShow])
	
	useEffect(() => {
		showButtonHandler({type: null, show: false});
		// eslint-disable-next-line
	}, [])
	
	useEffect(() => {
		setVideoToShow(caseStep.video)
	}, [caseStep])
	
	
	const protoVideoToggler = (buttonInfo) => {
		setProtoVideoList(prev => !!prev.find(prevEl => +buttonInfo.vimeoId === +prevEl.vimeoId) ? ([...prev.filter(element => +element.vimeoId !== +buttonInfo.vimeoId)]) : ([...prev, buttonInfo]))
	}
	return (
		<Grid
			className={'videoWithCheck'}
			container
			height={'100%'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{
				videoToShow &&
				<Vimeo
					className={'videoWithCheck__videoWrapper'}
					video={videoToShow}
					autoplay
					allowfullscreen
					transparent={true}
					onEnd={() => {
						if (!isFirstVideoEnd) {
							setIsFirstVideoEnd(true)
							setVideoMenuToShow(true)
							setVideoToShow(null)
						} else {
							setVideoToShow(null)
							setCommentToShow([...videoListToShow[currentVideoIndex].videoComment])
						}
					}}
				/>
			}
			{
				commentToShow &&
				<>
					{
						commentToShow.map((el, index) => <HtmlSelector element={el} key={index}/>)
					}
					{
						currentVideoIndex !== videoListToShow.length - 1 &&
						<button
							className={'videoWithCheck__button'}
							// disabled={+currentVideoIndex === videoListToShow.length - 1}
							onClick={() => {
								// if (currentVideoIndex < videoListToShow.length - 1) {
								setCommentToShow(null);
								setVideoToShow(videoListToShow[currentVideoIndex + 1].vimeoId)
								setCurrentVideoIndex(prev => prev + 1)
								// }
							}}
						>
							Kontynuj rozmowę
						</button>
					}
				</>
			}
			{
				videoMenuToShow &&
				<>
					<h3 className={'videoWithCheck__title'}>Jakie pytania są niezbędne dla postawienia właściwego
						rozpoznania i wdrożenia leczenia u tego pacjenta?<br/>Można wybrać dowolną liczbę pytań:
					</h3>
					{
						caseStep.videoButtons.map((videoButton, index) => (
							<EventVideoBox
								isChecked={!!protoVideoList.find(el => +el.vimeoId === +videoButton.vimeoId)}
								key={index}
								onClick={protoVideoToggler}
								buttonInfo={videoButton}
							/>
						))
					}
					{
						!!protoVideoList.length &&
						<Grid container justifyContent={'center'} alignItems={'center'}>
							<button
								className={'videoWithCheck__button'}
								onClick={() => {
									setVideoListToShow([...protoVideoList])
									setCurrentVideoIndex(0)
									setVideoMenuToShow(null)
									setVideoToShow(protoVideoList[0].vimeoId)
								}}
							>
								Kontynuj rozmowę
							</button>
						</Grid>
					}
				</>
			}
		</Grid>
	)
}

export default VideoWithCheck;