import React, { useContext, useEffect } from 'react'
import { MainPageContext } from '../../../context/mainPage/mainPageContext';
import NewestQuizzes from './NewestQuizzes';
import NewestPodcasts from './NewestPodcasts';
import NewestEvents from './NewestEvents';
import VariaBanner from './VariaBanner';
import { Container } from '@mui/material';
import PesBanner from "./PesBanner";

const MainPage = () => {
    const {getMainPageInfo, clearMainPageInfo} = useContext(MainPageContext)

    useEffect(() => {
        getMainPageInfo()
        return () => {
            clearMainPageInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Container maxWidth="xl">
                <PesBanner/>
                <NewestQuizzes/>
                <NewestPodcasts/>
                <NewestEvents/>
            </Container>
            <VariaBanner/>
        </>
    )
}

export default MainPage;